export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date format: YYYY-MM-DD */
  Date: any;
  /**
   * Time format according RFC3339 (https://tools.ietf.org/html/rfc3339)
   * Valid formats:
   * - 2002-10-02T10:00:00-05:00
   * - 2002-10-02T15:00:00Z
   * - 2002-10-02T15:00:00.05Z
   */
  Time: any;
  Upload: any;
};

export type GQLAccount = {
  __typename?: 'Account';
  email: Scalars['String'];
  first_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  logon_id: Scalars['ID'];
  organization: GQLOrganization;
  roles?: Maybe<Array<GQLAccountRole>>;
  status?: Maybe<GQLAccountStatus>;
};

export type GQLAccountFederation = {
  __typename?: 'AccountFederation';
  account_id: Scalars['ID'];
  external_logon_id: Scalars['String'];
  external_provider: Scalars['String'];
};

export type GQLAccountProfile = {
  __typename?: 'AccountProfile';
  id: Scalars['ID'];
  organization: GQLOrganization;
  properties: Array<GQLMapValue>;
  settings: Array<GQLMapValue>;
};

export type GQLAccountRole = {
  __typename?: 'AccountRole';
  organization: GQLOrganization;
  roles: Array<GQLRole>;
};

export enum GQLAccountStatus {
  Blocked = 'BLOCKED',
  Unblocked = 'UNBLOCKED'
}

export type GQLAccountV2 = {
  __typename?: 'AccountV2';
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type GQLActorContext = {
  __typename?: 'ActorContext';
  /** optional - The account that is available in the x-auth-jwt. In case of impersonation it's the impersonating account */
  account?: Maybe<GQLAccount>;
  /** optional - The account ID that is available in the x-auth-jwt. In case of impersonation it's the impersonating account */
  account_id?: Maybe<Scalars['String']>;
  /** optional - The name of the channel that triggered the event, e.g. 'www.kramp.com' */
  channel_name?: Maybe<Scalars['String']>;
  /** optional - The type of channel that triggered the event, e.g. 'webshop' */
  channel_type?: Maybe<Scalars['String']>;
  /** optional - The account of the actor that triggered the event. In case of impersonation it's the impersonated account, absent in case of org only impersonation */
  effective_account?: Maybe<GQLAccount>;
  /** optional - The ID of the actor that triggered the event. In case of impersonation it's the impersonated account, absent in case of org only impersonation */
  effective_account_id?: Maybe<Scalars['String']>;
  /** optional - The ID of the organization that triggered the event. In case of impersonation it's the impersonated organization */
  effective_organization_id?: Maybe<Scalars['String']>;
  /** optional - The organization ID that is available in the x-auth-jwt. In case of impersonation it's the impersonating organization */
  organization_id?: Maybe<Scalars['String']>;
  /** optional - The system that the event was triggered on, e.g. 'dibbler-submitter' */
  system?: Maybe<Scalars['String']>;
};

export type GQLAddAddressTypesInput = {
  address_id: Scalars['ID'];
  address_types: Array<GQLAddressType>;
};

export type GQLAddCampaignItemsInput = {
  campaign_id: Scalars['ID'];
  insert_position: Scalars['Int'];
  item_ids: Array<Scalars['ID']>;
  source: Scalars['String'];
};

export type GQLAddCampaignTagsInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type GQLAddCampaignTargetGroupsInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
  target_groups: Array<Scalars['String']>;
};

export type GQLAddItemToQuotationInput = {
  /**
   * If the item in this quotation line represent a configurable or pre configured set of items, this bill of
   *     materials is filled with the contents of the set.
   */
  bill_of_materials?: InputMaybe<GQLBillOfMaterialsInput>;
  /** Comment, only relevant for the customer */
  comment?: InputMaybe<Scalars['String']>;
  /** Indicator if quotation lines with the same item_id will be combined, see consolidate_with_line */
  consolidate: Scalars['Boolean'];
  /**
   * reference to quotation line to consolidate with, if consolidate is true and consolidate_with_line:
   *     - is empty -> consolidate if there is a single possible line, return a consolidation error if there are multiple,
   *     - refers to a correct line (same item id) -> consolidate,
   *     - refers to an incorrect line -> error
   */
  consolidate_with_line?: InputMaybe<Scalars['String']>;
  /** Free text describing instructions for fulfillment. */
  fulfillment_instructions?: InputMaybe<Scalars['String']>;
  /** Identifier of the item */
  item_id: Scalars['ID'];
  /** name(alias) for the quotation line */
  name?: InputMaybe<Scalars['String']>;
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** Map of free text properties */
  properties?: InputMaybe<Array<GQLMapValueInput>>;
  /** Quantity to be added. If the item already exists in the shopping cart and consolidate set to true, then the quantity will be added to the existing quantity. */
  quantity: Scalars['Float'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /**
   * Map of free text references from another system. Might be used in automated channels to relate
   *     the quotation line to something in the system it originated from.
   */
  references?: InputMaybe<Array<GQLMapValueInput>>;
  /** Requested shipping date to be used for the item */
  requested_shipping_date?: InputMaybe<Scalars['Time']>;
  /** If supplied will be checked against existing unit of measure for validation, no match creates validation error. */
  unit_of_measure?: InputMaybe<Scalars['String']>;
};

export type GQLAddItemsToQuotationInput = {
  /** The items to add */
  items: Array<GQLAddItemsToQuotationLinesInput>;
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLAddItemsToQuotationLinesInput = {
  /**
   * If the item in this quotation line represent a configurable or pre configured set of items, this bill of
   *     materials is filled with the contents of the set.
   */
  bill_of_materials?: InputMaybe<GQLBillOfMaterialsInput>;
  /** Comment, only relevant for the customer */
  comment?: InputMaybe<Scalars['String']>;
  /** Indicator if quotation lines with the same item_id will be combined, see consolidate_with_line */
  consolidate: Scalars['Boolean'];
  /**
   * reference to quotation line to consolidate with, if consolidate is true and consolidate_with_line:
   *     - is empty -> consolidate if there is a single possible line, return a consolidation error if there are multiple,
   *     - refers to a correct line (same item id) -> consolidate,
   *     - refers to an incorrect line -> error
   */
  consolidate_with_line?: InputMaybe<Scalars['String']>;
  /** Optional : Currency to be used for the fixed price */
  currency?: InputMaybe<Scalars['String']>;
  /** Free text describing instructions for fulfillment. */
  fulfillment_instructions?: InputMaybe<Scalars['String']>;
  /** Identifier of the item */
  item_id: Scalars['ID'];
  /** Optional : Margin percentage of the quotation line */
  margin_percentage?: InputMaybe<Scalars['Float']>;
  /** name(alias) for the quotation line */
  name?: InputMaybe<Scalars['String']>;
  /** Map of free text properties */
  properties?: InputMaybe<Array<GQLMapValueInput>>;
  /** Quantity to be added. If the item already exists in the shopping cart and consolidate set to true, then the quantity will be added to the existing quantity. */
  quantity: Scalars['Float'];
  /**
   * Map of free text references from another system. Might be used in automated channels to relate
   *     the quotation line to something in the system it originated from.
   */
  references?: InputMaybe<Array<GQLMapValueInput>>;
  /** Requested shipping date to be used for the item */
  requested_shipping_date?: InputMaybe<Scalars['Time']>;
  /** Optional : Gross price of the quotation line */
  total_gross_price?: InputMaybe<Scalars['Float']>;
  /** Optional : Effective price (excl discounts) of the quotation line */
  total_net_price?: InputMaybe<Scalars['Float']>;
  /** Optional : Unit gross price of the quotation line */
  unit_gross_price?: InputMaybe<Scalars['Float']>;
  /** If supplied will be checked against existing unit of measure for validation, no match creates validation error. */
  unit_of_measure?: InputMaybe<Scalars['String']>;
};

export type GQLAddItemsToWishlistInput = {
  items: Array<GQLAddItemsToWishlistLineInput>;
  wishlist_id: Scalars['ID'];
};

export type GQLAddItemsToWishlistLineInput = {
  comment?: InputMaybe<Scalars['String']>;
  item_id: Scalars['String'];
  quantity: Scalars['Float'];
};

export type GQLAddOrganizationInput = {
  country_code: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  properties: Array<GQLMapValueInput>;
  settings: Array<GQLMapValueInput>;
};

export type GQLAddOrganizationLinkInput = {
  child_id: Scalars['String'];
  parent_id: Scalars['String'];
  weight: Scalars['Int'];
};

export type GQLAddOrganizationsToStaticTargetGroupInput = {
  organization_ids: Array<Scalars['ID']>;
  static_target_group_id: Scalars['ID'];
};

export type GQLAddress = {
  __typename?: 'Address';
  address_text: Scalars['String'];
  address_type: Array<GQLAddressType>;
  city: Scalars['String'];
  /** country code (ISO format, 2 positions) */
  country_code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  geo_location?: Maybe<GQLGeoLocation>;
  /** unique address identifier */
  id: Scalars['ID'];
  label: Scalars['String'];
  modifiable: Scalars['Boolean'];
  /** @deprecated Use recipient_name instead. */
  name: Scalars['String'];
  /** postal code */
  post_code: Scalars['String'];
  recipient_name: Scalars['String'];
  settings: Array<GQLMapValue>;
};

export type GQLAddressDeliveryOptionsFreightChargeRanges = {
  __typename?: 'AddressDeliveryOptionsFreightChargeRanges';
  delivery_address: GQLDeliveryAddress;
  /** @deprecated Use delivery_address object instead */
  delivery_address_id?: Maybe<Scalars['String']>;
  delivery_options_freight_charge_ranges: Array<GQLDeliveryOptionFreightChargeRanges>;
};

export enum GQLAddressType {
  Billing = 'BILLING',
  Contact = 'CONTACT',
  Postal = 'POSTAL',
  Primary = 'PRIMARY',
  Service = 'SERVICE',
  Shipping = 'SHIPPING',
  Unknown = 'UNKNOWN'
}

/** AddressValue does not have an required ID, therefor making it an value object */
export type GQLAddressValue = {
  __typename?: 'AddressValue';
  address?: Maybe<GQLAddress>;
  /** textual field with the address information */
  address_text?: Maybe<Scalars['String']>;
  /** city this address is in */
  city?: Maybe<Scalars['String']>;
  /** country code (ISO format, 2 positions) */
  country_code?: Maybe<Scalars['String']>;
  /** fields added by the DC3 Experience Layer */
  extended: GQLExtendedAddressValue;
  /** postal code for this address */
  post_code?: Maybe<Scalars['String']>;
  /** recipient name, if specified */
  recipient_name?: Maybe<Scalars['String']>;
  /** catlyn address identifier, won't be there in case of an address unknown to catlyn */
  reference_address_id?: Maybe<Scalars['ID']>;
};

export type GQLAdminRole = {
  __typename?: 'AdminRole';
  can_assign_roles: Array<GQLAdminRole>;
  created_at?: Maybe<Scalars['Time']>;
  id: Scalars['Int'];
  is_assignable_by_roles: Array<GQLAdminRole>;
  /** translated description of the role, based on locale */
  localized_role_description: Scalars['String'];
  /** translated name of the role, based on locale */
  localized_role_name: Scalars['String'];
  permissions_details: Array<GQLPermissionDetails>;
  /** non-localized description of the role, as stored in role entity, locale independent */
  role_description: Scalars['String'];
  /** non-localized name of the role, as stored in role entity, locale independent */
  role_name: Scalars['String'];
  translations: Array<GQLRoleTranslation>;
};

export type GQLAlsoSearchForSynonym = {
  __typename?: 'AlsoSearchForSynonym';
  /** What account_id has initially created this synonym */
  created_by_account_id?: Maybe<GQLAccount>;
  /** When was this synonym exactly created */
  creation_timestamp: Scalars['Time'];
  /** Identifier for this synonym */
  id: Scalars['String'];
  /** Language code that this synonym is applicable to */
  language: Scalars['String'];
  /** When was this update of suppressed items for this search term */
  last_update_timestamp: Scalars['Time'];
  /** When account_id has last modified this synonym */
  last_updated_by_account_id?: Maybe<GQLAccount>;
  /** Input terms for this synonym, when a user searches for any of these terms they will also search for the terms in the search_terms field */
  query_terms?: Maybe<Array<Scalars['String']>>;
  /** What problem is this synonym supposed to resolve */
  resolve_type?: Maybe<GQLSynonymResolveType>;
  /** Search terms for this synonym, when a user searches for any of the terms in the query_terms field they will also search for the terms in this field */
  search_terms?: Maybe<Array<Scalars['String']>>;
};

export type GQLAnswer = {
  __typename?: 'Answer';
  attributes?: Maybe<Array<GQLKv>>;
  id: Scalars['String'];
  value: Scalars['String'];
};

/** DC3 Applications available */
export enum GQLApplication {
  Alpha = 'alpha',
  Campaign = 'campaign',
  Catalog = 'catalog',
  Delivery = 'delivery',
  Experimentation = 'experimentation',
  Hyperloop = 'hyperloop',
  Organization = 'organization',
  Quotation = 'quotation',
  Role = 'role',
  Search = 'search'
}

/** This is the new Asset coming from Soteria */
export type GQLAsset = {
  __typename?: 'Asset';
  /** Localized description for the asset */
  description?: Maybe<GQLAssetDescription>;
  id: Scalars['ID'];
  locales?: Maybe<Array<Scalars['String']>>;
  mime_type: Scalars['String'];
  /** Properties can be used to describe the characteristics of an asset */
  properties?: Maybe<Array<GQLMapValue>>;
  sort_order: Scalars['Int'];
  url: Scalars['String'];
  /** Usage of the media asset */
  usage_type: Scalars['String'];
};

export type GQLAssetDescription = {
  __typename?: 'AssetDescription';
  content: Scalars['String'];
  locale: Scalars['String'];
  summary: Scalars['String'];
};

export type GQLAssetToUploadForUsageDefinitionInput = {
  asset_content_id?: InputMaybe<Scalars['String']>;
  file_name: Scalars['String'];
  mime_type: Scalars['String'];
  sort_order: Scalars['Int'];
  usage_show_for_locales: Array<Scalars['String']>;
};

export type GQLAssignAccountRoleInput = {
  account_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  role_id: Scalars['Int'];
};

export type GQLAssignQuotationInput = {
  /** identifier of the account the quotation will be assigned to */
  account_id: Scalars['ID'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLAssignableRole = {
  __typename?: 'AssignableRole';
  organization: GQLOrganization;
  roles: Array<GQLRole>;
};

export type GQLAttribute = {
  __typename?: 'Attribute';
  /** Type of the data in the values for this attribute */
  data_type: GQLAttributeDataType;
  /** Localized description */
  description: GQLLocalizedValue;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Number for determining ordering of this Attribute compared to other Attributes */
  sort_order: Scalars['Int'];
  unit_of_measurement?: Maybe<GQLUnitOfMeasurement>;
};

export enum GQLAttributeDataType {
  /** Value: "true","false" */
  Boolean = 'BOOLEAN',
  /** Value: "2016-04-30 19:00:00.000" */
  Date = 'DATE',
  /** Value: "1","0.5" */
  Decimal = 'DECIMAL',
  /** Value: "My free text" */
  Text = 'TEXT',
  Unknown = 'UNKNOWN'
}

export type GQLAttributeValue = {
  __typename?: 'AttributeValue';
  attribute: GQLAttribute;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Number for determining ordering of this AttributeValue compared to other AttributeValues */
  sort_order: Scalars['Int'];
  /** Attribute values, including locale if available */
  values: Array<GQLLocalizableValue>;
};

export enum GQLAvailability {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Unknown = 'UNKNOWN',
  Virtual = 'VIRTUAL'
}

export type GQLBillOfMaterials = {
  __typename?: 'BillOfMaterials';
  /** configuration id: this id comes from Tacton or ISAH and is later used to reference this particular bill of materials */
  configuration_id: Scalars['ID'];
  /** Lines in this bill of materials. */
  lines: Array<GQLBillOfMaterialsLine>;
};

export type GQLBillOfMaterialsInput = {
  /** configuration id: this id comes from tacton and is later used to reference this particular bill of materials */
  configuration_id: Scalars['ID'];
  /** Lines in this bill of materials. */
  lines: Array<GQLBillOfMaterialsLineInput>;
};

export type GQLBillOfMaterialsLine = {
  __typename?: 'BillOfMaterialsLine';
  /** Identifier of the item. */
  item_id: Scalars['ID'];
  /** Quantity for this line in the bill of materials. */
  quantity: Scalars['Float'];
};

export type GQLBillOfMaterialsLineInput = {
  /** Identifier of the item. */
  item_id: Scalars['ID'];
  /** Quantity for this line in the bill of materials. */
  quantity: Scalars['Float'];
};

export type GQLBooleanValue = {
  __typename?: 'BooleanValue';
  value: Scalars['Boolean'];
};

export type GQLBooleanValueInput = {
  value: Scalars['Boolean'];
};

export type GQLBooleanValueSpec = {
  __typename?: 'BooleanValueSpec';
  default_value: Scalars['Boolean'];
};

export type GQLBooleanValueSpecInput = {
  default_value: Scalars['Boolean'];
};

export type GQLBrand = {
  __typename?: 'Brand';
  /** Media assets coming from Soteria */
  assets?: Maybe<Array<GQLAsset>>;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Name of this brand */
  name: Scalars['String'];
};

export type GQLBulkUpdateDiscountsForCampaignsInput = {
  /** Campaign ID */
  campaign_id: Scalars['ID'];
  /** Discount type */
  discount_type: GQLDiscountType;
  /** Discount value */
  discount_value: Scalars['Float'];
};

export type GQLCampaign = {
  __typename?: 'Campaign';
  end_datetime?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  items: Array<GQLItem>;
  name: Scalars['String'];
  properties: Array<GQLMapValue>;
  source: Scalars['String'];
  start_datetime?: Maybe<Scalars['Time']>;
  status: GQLCampaignStatus;
  tags: Array<Scalars['String']>;
  target_groups: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type GQLCampaignAttributeDescription = {
  __typename?: 'CampaignAttributeDescription';
  description: Scalars['String'];
  id: Scalars['ID'];
  unitOfMeasurement?: Maybe<GQLCampaignUnitOfMeasure>;
};

export type GQLCampaignAttributeValue = {
  __typename?: 'CampaignAttributeValue';
  attribute: GQLCampaignAttributeDescription;
  id: Scalars['ID'];
  values: Array<Scalars['String']>;
};

export type GQLCampaignExportedDataItem = {
  __typename?: 'CampaignExportedDataItem';
  endDateTime: Scalars['String'];
  id: Scalars['ID'];
  items: Array<GQLCampaignItem>;
  name: Scalars['String'];
  properties: Array<GQLCampaignMapValue>;
  source: Scalars['String'];
  startDateTime: Scalars['String'];
  status: GQLCampaignStatus;
  tags: Array<Scalars['String']>;
  targetGroups: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type GQLCampaignItem = {
  __typename?: 'CampaignItem';
  attributes: Array<GQLCampaignAttributeValue>;
  brand: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  quantityUnit: GQLCampaignUnitOfMeasure;
  roundingQuantity: Scalars['Float'];
  variant: GQLCampaignVariant;
};

export type GQLCampaignMapValue = {
  __typename?: 'CampaignMapValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GQLCampaignOverviewItem = {
  __typename?: 'CampaignOverviewItem';
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  tags: Scalars['String'];
  type: Scalars['String'];
};

/** The basic status of a campaign */
export enum GQLCampaignStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Inactive = 'INACTIVE',
  Planned = 'PLANNED',
  Undefined = 'UNDEFINED'
}

export type GQLCampaignUnitOfMeasure = {
  __typename?: 'CampaignUnitOfMeasure';
  content: Scalars['String'];
  id: Scalars['ID'];
  symbol: Scalars['String'];
};

export type GQLCampaignV2 = {
  __typename?: 'CampaignV2';
  /** Campaigns */
  campaigns?: Maybe<Array<GQLCampaign>>;
  /** Pagination */
  statistics: GQLStatistics;
};

export type GQLCampaignVariant = {
  __typename?: 'CampaignVariant';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLCampaignsQueryInput = {
  campaign_type?: InputMaybe<Scalars['String']>;
  end_datetime?: InputMaybe<Scalars['Time']>;
  start_datetime?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<GQLCampaignStatus>;
};

export type GQLCampaignsQueryV2Input = {
  /** Campaign end datetime */
  end_datetime?: InputMaybe<Scalars['Time']>;
  /** Campaign name */
  name?: InputMaybe<Scalars['String']>;
  /** Page number */
  page: Scalars['Int'];
  /** Page size */
  page_size: Scalars['Int'];
  /** Campaign combined query */
  search_query?: InputMaybe<Scalars['String']>;
  /** Sort by fields */
  sort_by?: InputMaybe<Array<GQLQueryCampaignsSortBy>>;
  /** Campaign start datetime */
  start_datetime?: InputMaybe<Scalars['Time']>;
  /** Campaign status */
  status?: InputMaybe<Array<GQLCampaignStatus>>;
  /** Campaign types */
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type GQLCancelQuotationInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /** reason of cancellation */
  reason?: InputMaybe<Scalars['String']>;
};

export type GQLCatalog = {
  __typename?: 'Catalog';
  categories: Array<GQLCategory>;
  id: Scalars['ID'];
};

export type GQLCategory = {
  __typename?: 'Category';
  /** Media assets coming from Soteria */
  assets?: Maybe<Array<GQLAsset>>;
  child_categories: Array<GQLCategory>;
  /** Get all variants, components and items that fall below this category. */
  entries: GQLEntriesInCategory;
  id: Scalars['ID'];
  /** Get all items that fall under this category, that this user is entitled to see. */
  items: GQLItemsInCategory;
  /** @deprecated use assets field instead */
  media_assets: Array<GQLMediaAsset>;
  name: GQLLocalizedValue;
  paths: Array<Array<GQLCategory>>;
};


export type GQLCategoryEntriesArgs = {
  filters?: InputMaybe<GQLFilterInput>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};


export type GQLCategoryItemsArgs = {
  filters?: InputMaybe<GQLFilterInput>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};

export type GQLCategoryEntry = GQLComponent | GQLItem | GQLVariant;

export type GQLCategorySuggestion = {
  __typename?: 'CategorySuggestion';
  category_id: Scalars['String'];
  name: Scalars['String'];
  number_of_items: Scalars['Int'];
};

export type GQLCategorySuggestionProperties = {
  maximum_suggestions: Scalars['Int'];
};

export type GQLChangelogItem = {
  __typename?: 'ChangelogItem';
  application: Scalars['String'];
  description: Scalars['String'];
  version: Scalars['String'];
};

export enum GQLChannelType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type GQLClaimQuotationInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLClassification = {
  __typename?: 'Classification';
  code: Scalars['String'];
  values: Array<GQLMapValue>;
};

export type GQLClearQuotationLinePropertiesInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLClearQuotationLineReferencesInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLClearQuotationPropertiesInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLComment = {
  __typename?: 'Comment';
  /** Text of the comment */
  comment: Scalars['String'];
  /** Time when the comment was created */
  created_at: Scalars['Time'];
  /** User who created the comment */
  created_by: Scalars['String'];
  /** Experiment ID */
  experiment_id: Scalars['String'];
  /** Comment ID */
  id: Scalars['String'];
  /** Time when the comment was last updated */
  updated_at: Scalars['Time'];
};

export type GQLCommunicationContent = {
  __typename?: 'CommunicationContent';
  content_id_to_content: Array<GQLMapValue>;
};

export type GQLCommunicationPreference = {
  __typename?: 'CommunicationPreference';
  account_id?: Maybe<Scalars['String']>;
  channel: Scalars['String'];
  communication_details: Array<Scalars['String']>;
  event_type: Scalars['String'];
  language: Scalars['String'];
  organization_id?: Maybe<Scalars['String']>;
};

export type GQLComponent = {
  __typename?: 'Component';
  /** Media assets coming from Soteria */
  assets?: Maybe<Array<GQLAsset>>;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Get all items usable for this component that this user is entitled to see. */
  items: GQLItemsUsableForComponent;
  /** Localized name of the Component */
  name: GQLLocalizedValue;
  path: Array<GQLCategory>;
};


export type GQLComponentItemsArgs = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};


export type GQLComponentPathArgs = {
  catalog_id: Scalars['String'];
};

export type GQLCondition = {
  __typename?: 'Condition';
  configurationValue: Scalars['String'];
  description: Scalars['String'];
  /** Unique Identifier for a condition */
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GQLConditionInput = {
  /** Phoenix Configuration ID */
  configurationValue: Scalars['String'];
  /** Description of the condition */
  description: Scalars['String'];
  /** Name of the condition */
  name: Scalars['String'];
  /** Same Service_Name used on a experiment */
  serviceName: Scalars['String'];
};

/** Condition Operator */
export enum GQLConditionOperator {
  Equals = 'EQUALS',
  Unknown = 'UNKNOWN'
}

export type GQLConditions = {
  __typename?: 'Conditions';
  conditionOperator: GQLConditionOperator;
  propertyId: Scalars['String'];
  value: Scalars['String'];
};

/** Delivery Conditions Response */
export type GQLConditionsResponse = {
  __typename?: 'ConditionsResponse';
  operators?: Maybe<Array<GQLConditionOperator>>;
  propertyId?: Maybe<Scalars['String']>;
  propertyName?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type GQLConfigurationSpecificationSortOrderInput = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field_type: GQLFieldType;
};

export enum GQLConfigurationType {
  Boolean = 'BOOLEAN',
  Configuration = 'CONFIGURATION',
  Double = 'DOUBLE',
  Integer = 'INTEGER',
  String = 'STRING',
  Unknown = 'UNKNOWN'
}

export type GQLConfigurationValueSpecInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  owner: Scalars['String'];
  parameters: Array<GQLParameterSpecPayload>;
};

export type GQLConsolidatedFreightCharge = {
  __typename?: 'ConsolidatedFreightCharge';
  /** Consolidated freight charge amount */
  consolidated_freight_charge_amount?: Maybe<GQLMonetaryValue>;
  /** Freight charge until delivery become free */
  free_freight_charge_class?: Maybe<GQLFreightChargeClass>;
  /** Freight charge that is the next bucket, null if free class is next */
  next_freight_charge_class?: Maybe<GQLFreightChargeClass>;
};

export type GQLConvertQuotationProposalInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLCopyPricePromotionCampaignItemsInput = {
  /** Source Campaign ID */
  campaign_source_id: Scalars['ID'];
  /** target Campaign ID */
  campaign_target_id: Scalars['ID'];
};

export type GQLCorporateIdentityAndLocaleInput = {
  corporate_identity: Scalars['String'];
  locale: Scalars['String'];
};

export type GQLCountFeatureToggleValuesInput = {
  /** List/search the values for the given feature toggle. Should be the full name of the feature toggle and respecting casing, basically treating it as an identifier. If left empty then not 'drilling down' on values for a specific feature toggle, but listing/searching all feature toggle values. */
  feature_toggle_name: Scalars['String'];
  search_for?: InputMaybe<GQLFeatureToggleValueSearchFor>;
};

export type GQLCountFeatureToggleValuesOutput = {
  __typename?: 'CountFeatureToggleValuesOutput';
  total_results: Scalars['Int'];
};

export type GQLCountFeatureTogglesInput = {
  search_for?: InputMaybe<GQLFeatureToggleSearchFor>;
};

export type GQLCountFeatureTogglesOutput = {
  __typename?: 'CountFeatureTogglesOutput';
  total_results: Scalars['Int'];
};

export type GQLCreateAccountFederationInput = {
  account_id: Scalars['ID'];
  external_logon_id: Scalars['String'];
  external_provider: Scalars['String'];
};

export type GQLCreateAccountInput = {
  account_id?: InputMaybe<Scalars['ID']>;
  email: Scalars['String'];
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  logon_id: Scalars['ID'];
  organization_id: Scalars['String'];
};

export type GQLCreateAddressInput = {
  address_text: Scalars['String'];
  address_type: Array<GQLAddressType>;
  city: Scalars['String'];
  country_code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  geo_location?: InputMaybe<GQLGeoLocationInput>;
  label: Scalars['String'];
  /** Deprecated: Set this to empty string and use recipient_name instead. */
  name: Scalars['String'];
  organization_id: Scalars['String'];
  post_code: Scalars['String'];
  recipient_name: Scalars['String'];
  settings?: InputMaybe<Array<GQLMapValueInput>>;
};

export type GQLCreateCampaignInput = {
  campaign_id?: InputMaybe<Scalars['ID']>;
  end_datetime?: InputMaybe<Scalars['Time']>;
  name: Scalars['String'];
  source: Scalars['String'];
  start_datetime?: InputMaybe<Scalars['Time']>;
  status: GQLCampaignStatus;
  tags: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type GQLCreateFeatureToggleInput = {
  /** Defines for which contexts the toggle is applicable. Currently we know 'webshop' and 'app' [not required] */
  contexts?: InputMaybe<Array<Scalars['String']>>;
  /** Can be any value, but most often boolean logic is used, thus 'true' or 'false' [not required] */
  default_value?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  /** The name of the feature. E.g. 'FreightCharges' [required] */
  feature_id: Scalars['String'];
  /** Defines for which targets the feature toggle should have a specific value (overriding the optional default value) [not required] */
  targets_and_values?: InputMaybe<Array<GQLFeatureToggleValueForTargetInput>>;
};

/** The CreateFeatureToggle operation doesn't return anything. This is more like a place holder */
export type GQLCreateFeatureToggleOutput = {
  __typename?: 'CreateFeatureToggleOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLCreateOrUpdateRestrictionRulesInput = {
  deliveryOptionConditions: Array<GQLDeliveryOptionsConditionsInput>;
  deliveryOptionIds: Array<Scalars['String']>;
  description: Scalars['String'];
  ruleId?: InputMaybe<Scalars['String']>;
};

export type GQLCreatePhoenixConfigurationInput = {
  configuration: GQLPhoenixConfigurationInput;
};

export type GQLCreatePhoenixConfigurationSpecInput = {
  description: Scalars['String'];
  name: Scalars['String'];
  owner: Scalars['String'];
  parameters: Array<GQLParameterSpecPayload>;
};

export type GQLCreateQuotationInput = {
  /**
   * Account (belonging to e.g. a salesrep) to which the quotation is assigned. Assigned to is only an indicator,
   *     there are no enforced rules based on this field.
   */
  assigned_to_account_id?: InputMaybe<Scalars['String']>;
  /** Free text providing insights in the quotation from the perspective of the customer. */
  comment?: InputMaybe<Scalars['String']>;
  /** indicates if this quotation needs to be set as the default quotation. */
  default: Scalars['Boolean'];
  /** Free text describing instructions for fulfillment. */
  fulfillment_instructions?: InputMaybe<Scalars['String']>;
  /** indicates if a separate invoice is to be sent, specifically for this quotation. */
  invoiced_separately: Scalars['Boolean'];
  /** indicates if this quotation is a draft (optional) */
  is_draft?: InputMaybe<Scalars['Boolean']>;
  /** Name of the quotation, optional custom name that can be supplied by the channel when creating the quotation. */
  name: Scalars['String'];
  /** The type of quotation to be created, STANDARD being the default if not specified */
  quotation_type?: InputMaybe<GQLQuotationType>;
  /**
   * Field with free text with a reference from another system, might be used in automated channels to relate
   *     the quotation to something in the system it originated from
   */
  reference?: InputMaybe<Scalars['String']>;
  /** Indicator if the quotation is private or shared, see Quotation.shared for more information */
  shared: Scalars['Boolean'];
};

export type GQLCreateRestrictionRuleInput = {
  /** list of conditions */
  delivery_option_conditions: Array<GQLDeliveryOptionRestrictionCondition>;
  /** list of delivery option ids which are affected */
  delivery_option_ids: Array<Scalars['String']>;
  /** description of the created rule */
  description: Scalars['String'];
  /** restriction rule id, [optional] needed only to update */
  rule_id?: InputMaybe<Scalars['String']>;
};

export type GQLCreateRoleInput = {
  can_assign_roles?: InputMaybe<Array<Scalars['Int']>>;
  is_assignable_by_role_ids?: InputMaybe<Array<Scalars['Int']>>;
  role_description: Scalars['String'];
  role_name: Scalars['String'];
};

export type GQLCreateValuesForFeatureToggleInput = {
  /** The name of the feature. E.g. 'FreightCharges' */
  feature_id: Scalars['String'];
  /** The values (or rules) that need to be added for the feature toggle */
  targets_and_values: Array<GQLFeatureToggleValueForTargetInput>;
};

export type GQLCreateValuesForFeatureToggleOutput = {
  __typename?: 'CreateValuesForFeatureToggleOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLCreateWishlistInput = {
  comment?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  set_as_default: Scalars['Boolean'];
  shared: Scalars['Boolean'];
};

/**
 * Used to represent decimal values without running into rounding problems usual for floating point numbers.
 * This decimal representation consists of an unscaled_value and a scale, The unscaled_value determines the exact
 * digits in the decimal, and the scale encodes the placement of the decimal point. The length in digits of the
 * unscaled value can be interpreted as the precision of the decimal. This representation can store signed numbers with
 * unscaled value not greater than 2^64 = 18446744073709551616, in other words, the maximum precision is 19 digits.
 * The represented value is calculated as: value = unscaled_value*10^(-1*scale).
 * For a string representation of this decimal it is advised to use the following format r\"-?[0-9][0-9]*(.[0-9]+)?\".
 * where trailing zeros in the unscaled value are included to keep the precision.
 * Examples:
 * \"1.0\" (unscaledvalue=10,scale=1)
 * \"-0.123\" (unscaledvalue=-123,scale=3)
 * \"10.80\" (unscaledvalue=1080,scale=2)
 * DEPRECATION NOTES: Precision and rounding_mode were (and might still be) interpreted by some servers and clients.
 * In this case the unscaled value and scale are adjusted such that the required precision is achieved, using
 * rounding_mode if necessary. It is deprecated because the api results in undefined behaviour when the requested
 * precision is lower than supplied in the unscaled value and the rounding mode is not specified.
 * Decimal message definition is inspired by Java definition of BigDecimal.
 */
export type GQLDecimalValue = {
  __typename?: 'DecimalValue';
  /**
   * The scale of the decimal, determines how many place to move the decimal point to the left (positive) or to the
   *     right (negative) starting from the right of the last digit of the unscaled value. Example: \"-42.1\" has scale 1.
   */
  scale: Scalars['Int'];
  /** Unscaled and signed value of the decimal. Example: "-42.1" has unscaled value -421. */
  unscaled_value: Scalars['Int'];
};

export type GQLDefaultDeliveryOptionInput = {
  lines: Array<GQLItemDeliveryOptionLine>;
};

export type GQLDefineAssetUsageForEntityInput = {
  entity_id: Scalars['ID'];
  entity_type: GQLSoteriaEntityType;
  replace_all_existing_usages: Scalars['Boolean'];
  usages: Array<GQLEntityAssetUsageDefinitionInput>;
};

export type GQLDeleteAccountFederationInput = {
  account_id: Scalars['ID'];
  external_provider: Scalars['String'];
};

export type GQLDeleteAccountInput = {
  account_id: Scalars['ID'];
};

export type GQLDeleteAddressGeoLocationInput = {
  address_id: Scalars['ID'];
};

export type GQLDeleteAddressInput = {
  address_id: Scalars['ID'];
};

export type GQLDeleteAssetUsageForEntityInput = {
  asset_id: Scalars['ID'];
  entity_id: Scalars['ID'];
  entity_type: GQLSoteriaEntityType;
  usage_type: Scalars['String'];
};

export type GQLDeleteCampaignInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
};

export type GQLDeleteCampaignItemsInput = {
  campaign_id: Scalars['ID'];
  item_ids: Array<Scalars['ID']>;
  source: Scalars['String'];
};

export type GQLDeleteCampaignPropertiesInput = {
  campaign_id: Scalars['ID'];
  properties_keys: Array<Scalars['String']>;
  source: Scalars['String'];
};

export type GQLDeleteCampaignTagsInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type GQLDeleteCampaignTargetGroupsInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
  target_groups: Array<Scalars['String']>;
};

export type GQLDeleteFeatureToggleValueInput = {
  feature_toggle_value_ids: Array<Scalars['String']>;
};

export type GQLDeleteFeatureToggleValueOutput = {
  __typename?: 'DeleteFeatureToggleValueOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLDeleteFreightChargeRuleInput = {
  /** Currency for the minimum effective price and the amount */
  currency: Scalars['String'];
  /** Delivery option as known to Pythia */
  delivery_option_id: Scalars['String'];
  /** Freight Plans */
  freight_plan: Scalars['String'];
  /** The minimum effective price */
  minimum_effective_price: Scalars['Float'];
};

/** Delete freight charge rule */
export type GQLDeleteFreightChargeRuleInputXpl = {
  currency: Scalars['String'];
  delivery_option_id: Scalars['String'];
  freight_plan: Scalars['String'];
  minimum_effective_price: Scalars['Float'];
};

export type GQLDeleteLocalizedContentInput = {
  customization_owner?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
};

export type GQLDeleteLocalizedDeliveryOptionInput = {
  delivery_option_id: Scalars['String'];
  locale: Scalars['String'];
};

export type GQLDeleteOrganizationLinkInput = {
  child_id: Scalars['String'];
  parent_id: Scalars['String'];
};

export type GQLDeleteQuotationLineInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLDeleteQuotationLinePropertiesInput = {
  /** Map of free text properties */
  property_keys: Array<Scalars['String']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLDeleteQuotationLineReferencesInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /**
   * Map of free text references from another system. Might be used in automated channels to relate
   *     the quotation line to something in the system it originated from.
   */
  reference_keys: Array<Scalars['String']>;
};

export type GQLDeleteQuotationPropertiesInput = {
  /** Map of free text properties */
  property_keys: Array<Scalars['String']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLDeleteRoleInput = {
  role_id: Scalars['Int'];
};

export type GQLDeleteStylePropertiesInput = {
  customization_owner?: InputMaybe<Scalars['String']>;
  properties: Array<Scalars['String']>;
};

export type GQLDeleteWishlistLinesInput = {
  wishlist_id: Scalars['ID'];
  wishlist_lines_ids: Array<Scalars['ID']>;
};

export type GQLDeleteWishlistsInput = {
  wishlist_ids: Array<Scalars['ID']>;
};

export type GQLDelivery = {
  __typename?: 'Delivery';
  /** Availability: PRIMARY (it is available and item warehouse and user default warehouse are equals); SECONDARY (it is available, but item warehouse and user default warehouse are different), VIRTUAL (not available); UNKNOWN */
  availability?: Maybe<GQLAvailability>;
  /** Time indicating when the contents of this delivery could be delivered */
  delivery_time: Scalars['Time'];
  /** Time until when the contents of this entry are valid */
  delivery_time_valid_until: Scalars['Time'];
  /** Item identifier for this delivery, it could be different from the requested item id if a replacement is selected */
  item_id: Scalars['String'];
  /** Quantity of the item that is available within the current stock, or reserved in the allocation */
  quantity: Scalars['Float'];
};

export type GQLDeliveryAddress = {
  __typename?: 'DeliveryAddress';
  address?: Maybe<GQLAddress>;
  /** catlyn address identifier, not provided for custom addresses */
  address_id?: Maybe<Scalars['ID']>;
  address_text: Scalars['String'];
  city: Scalars['String'];
  country_code: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  /** fields added by the DC3 Experience Layer */
  extended: GQLExtendedDeliveryAddress;
  label?: Maybe<Scalars['String']>;
  postal_code: Scalars['String'];
  recipient_name?: Maybe<Scalars['String']>;
};

export type GQLDeliveryAddressInput = {
  address_id?: InputMaybe<Scalars['ID']>;
  address_text: Scalars['String'];
  city: Scalars['String'];
  country_code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  postal_code: Scalars['String'];
  recipient_name?: InputMaybe<Scalars['String']>;
};

export type GQLDeliveryAddresses = {
  __typename?: 'DeliveryAddresses';
  addresses: Array<GQLDeliveryAddress>;
  default_address?: Maybe<GQLDeliveryAddress>;
  /** id of the organization, uses the ctx-organization-id header from the request, added by the DC3 Experience Layer */
  id: Scalars['String'];
};

export type GQLDeliveryOption = {
  __typename?: 'DeliveryOption';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type GQLDeliveryOptionFreightCharge = {
  __typename?: 'DeliveryOptionFreightCharge';
  delivery_option?: Maybe<GQLDeliveryOption>;
  /** @deprecated Use delivery_option object instead */
  delivery_option_id?: Maybe<Scalars['String']>;
  freight_charge?: Maybe<GQLMonetaryValue>;
};

export type GQLDeliveryOptionFreightChargeRanges = {
  __typename?: 'DeliveryOptionFreightChargeRanges';
  delivery_option?: Maybe<GQLDeliveryOption>;
  /** @deprecated Use delivery_option object instead */
  delivery_option_id: Scalars['String'];
  freight_charge_range: Array<GQLFreightChargeRange>;
  /** Freight surcharges that apply to this delivery option and address combination */
  freight_surcharges?: Maybe<Array<GQLFreightSurcharge>>;
  order_buffer_total: GQLMonetaryValue;
};

export type GQLDeliveryOptionResponse = {
  __typename?: 'DeliveryOptionResponse';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type GQLDeliveryOptionRestrictionCondition = {
  condition_operator: GQLConditionOperator;
  property_id: Scalars['String'];
  value: Scalars['String'];
};

export type GQLDeliveryOptionRestrictionConditionType = {
  __typename?: 'DeliveryOptionRestrictionConditionType';
  condition_operator: GQLConditionOperator;
  property_id: Scalars['String'];
  value: Scalars['String'];
};

export type GQLDeliveryOptionWithDescriptions = {
  __typename?: 'DeliveryOptionWithDescriptions';
  descriptions: Array<GQLDescriptionForLocale>;
  id: Scalars['ID'];
};

export type GQLDeliveryOptionsConditionsInput = {
  conditionOperator: GQLConditionOperator;
  propertyId: Scalars['String'];
  value: Scalars['String'];
};

export type GQLDeliveryOptionsInput = {
  delivery_address?: InputMaybe<GQLDeliveryAddressInput>;
  lines: Array<GQLItemDeliveryOptionLine>;
};

export type GQLDeliveryPlan = {
  __typename?: 'DeliveryPlan';
  /** Quantity of the item that is avalable within the current stock, or reserved in the allocations. This is the sum of all the quantities within the delivery entries. */
  available_quantity: Scalars['Float'];
  /** Individual warehouse specific delivery entries, that make up the delivery plan. The entries contain information about which amount of the item is available in what timeframe. */
  deliveries: Array<GQLDelivery>;
  /** Identifier of the item for which the delivery plan is made. */
  item_id: Scalars['String'];
  /** The delivery option that would be used based on the preferences of the buyer */
  preferred_delivery_option: GQLDeliveryOption;
  /** Requested quantity for the item */
  requested_quantity: Scalars['Float'];
  /** The delivery option used. It might differ from the preferred due to logistical and commercial restrictions. */
  used_delivery_option: GQLDeliveryOption;
  /** Time until when the delivery entries are valid, i.e. the time when the first entry becomes invalid. */
  valid_until: Scalars['Time'];
};

export type GQLDeliveryPlanInput = {
  item_id: Scalars['ID'];
  quantity: Scalars['Float'];
};

/** Delivery Response */
export type GQLDeliveryResponse = {
  __typename?: 'DeliveryResponse';
  conditions?: Maybe<Array<GQLConditionsResponse>>;
  descriptions?: Maybe<Array<GQLNormalizeTranslationData>>;
  restrictionRules?: Maybe<Array<GQLRestrictionRulesResponse>>;
};

export type GQLDeliverySummaryInput = {
  date_from?: InputMaybe<Scalars['Date']>;
  date_to?: InputMaybe<Scalars['Date']>;
};

export type GQLDeliverySummaryResult = {
  __typename?: 'DeliverySummaryResult';
  delayed_count: Scalars['Int'];
  delivered_count: Scalars['Int'];
  in_transit_count: Scalars['Int'];
};

export type GQLDescriptionForLocale = {
  __typename?: 'DescriptionForLocale';
  description: Scalars['String'];
  locale: Scalars['String'];
};

export type GQLDescriptionLocale = {
  __typename?: 'DescriptionLocale';
  description: Scalars['String'];
  locale: Scalars['String'];
};

export type GQLDetailedFreightCharge = {
  __typename?: 'DetailedFreightCharge';
  /** Consolidated freight charge */
  consolidated_freight_charge?: Maybe<GQLConsolidatedFreightCharge>;
  /** Manual freight charges */
  quotation_freight_charges: Array<Maybe<GQLQuotationFreightCharge>>;
  /** List of surcharges */
  surcharges: Array<Maybe<GQLFreightSurcharge>>;
  /** The total delivery costs per address and delivery option */
  total_amount?: Maybe<GQLMonetaryValue>;
  /** The total order amount per address and delivery option */
  total_order_amount?: Maybe<GQLMonetaryValue>;
};

export type GQLDetailedFreightChargeFilter = {
  /** filter time of the freight charge (if in the period), current day if not specified */
  date_time?: InputMaybe<Scalars['Time']>;
  /** Delivery address of the freight charge */
  delivery_address?: InputMaybe<Scalars['String']>;
  /** Delivery option id of the freight charge */
  delivery_option_id?: InputMaybe<Scalars['String']>;
};

export type GQLDetailedFreightCharges = {
  __typename?: 'DetailedFreightCharges';
  /** List of detailed freight charges per address */
  detailed_freight_charges_per_address?: Maybe<Array<GQLDetailedFreightChargesPerAddress>>;
  /** The total effective delivery costs for today */
  total_amount: GQLMonetaryValue;
};

export type GQLDetailedFreightChargesPerAddress = {
  __typename?: 'DetailedFreightChargesPerAddress';
  /** Address address of the freight charge */
  delivery_address: GQLDeliveryAddress;
  /** List Of detailed freight charges per delivery option */
  detailed_freight_charges_per_delivery_option: Array<Maybe<GQLDetailedFreightChargesPerDeliveryOption>>;
};

export type GQLDetailedFreightChargesPerDeliveryOption = {
  __typename?: 'DetailedFreightChargesPerDeliveryOption';
  /** Delivery option id */
  delivery_option?: Maybe<GQLDeliveryOption>;
  /** Detailed freight charges based on address and delivery option */
  detailed_freight_charge?: Maybe<GQLDetailedFreightCharge>;
};

export type GQLDiscount = {
  __typename?: 'Discount';
  /** discount to be applied on the full quotation net price as a percentage (e.g.: 1 means 1% additional discount) */
  percentage: Scalars['Float'];
  /** reason for the pricing adjustment */
  reason?: Maybe<Scalars['String']>;
  /** timestamp until when the discount pricing adjustment is valid */
  valid_until?: Maybe<Scalars['Time']>;
};

export enum GQLDiscountType {
  /** Additional percentage */
  AdditionalPercentage = 'ADDITIONAL_PERCENTAGE',
  /** Fixed gross discount */
  FixGrossPercentage = 'FIX_GROSS_PERCENTAGE',
  /** Fixed gross price */
  FixGrossPrice = 'FIX_GROSS_PRICE',
  /** Fix net price */
  FixNetPrice = 'FIX_NET_PRICE',
  /** Fix percentage */
  FixPercentage = 'FIX_PERCENTAGE'
}

export type GQLDiscreteValueSpec = {
  __typename?: 'DiscreteValueSpec';
  allowed_values: Array<Scalars['Float']>;
  default_value: Scalars['Float'];
};

export type GQLDiscreteValueSpecInput = {
  allowed_values: Array<Scalars['Float']>;
  default_value: Scalars['Float'];
};

export type GQLDocument = {
  __typename?: 'Document';
  /** Acceptance state */
  acceptance: GQLDocumentAcceptanceState;
  /** Document ID */
  id: Scalars['ID'];
  /** Organization ID */
  organization_id: Scalars['ID'];
  /** Publish date; nil date means document is still a draft */
  published_at?: Maybe<Scalars['Time']>;
  /** Translations for this document: 'nl_NL' -> 'Dutch Content..' */
  translations: Array<Maybe<GQLTranslation>>;
  /** Unique document type */
  type: Scalars['String'];
};

export type GQLDocumentAcceptanceState = {
  __typename?: 'DocumentAcceptanceState';
  /** Whether the user has accepted this document */
  accepted: Scalars['Boolean'];
  /** If so, when was this accepted */
  accepted_at?: Maybe<Scalars['Time']>;
};

export type GQLDraftSalesCatalog = {
  __typename?: 'DraftSalesCatalog';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLDraftSalesCategoriesVersion = {
  __typename?: 'DraftSalesCategoriesVersion';
  id: Scalars['ID'];
  status: GQLDraftSalesCategoriesVersionStatus;
  version_number: Scalars['String'];
};

export enum GQLDraftSalesCategoriesVersionStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  InProgress = 'IN_PROGRESS',
  Published = 'PUBLISHED',
  Unknown = 'UNKNOWN'
}

export type GQLDraftSalesCategory = {
  __typename?: 'DraftSalesCategory';
  /** Some examples of the category_type include: APPLICATION, WEB7, WEB6, SERIES, MAKE, TYPE and MODEL */
  category_type: Scalars['String'];
  descriptions: Array<GQLDraftSalesCategoryDescription>;
  /** Returns all the possible paths to this draft sales category */
  draft_sales_category_paths?: Maybe<Array<Array<GQLDraftSalesCategory>>>;
  id: Scalars['ID'];
  /** Returns the mappings between this draft sales category and items in a particular version */
  item_mappings?: Maybe<Array<GQLItem>>;
  /** Returns the mappings between this draft sales category and master sales categories in a particular version */
  master_category_mappings?: Maybe<Array<GQLMasterCategory>>;
  /** This may get used if the sort_order is not set/used */
  sort_children_alphabetically: Scalars['Boolean'];
  version: Scalars['ID'];
};


export type GQLDraftSalesCategoryDraft_Sales_Category_PathsArgs = {
  draft_catalog_id: Scalars['ID'];
};


export type GQLDraftSalesCategoryItem_MappingsArgs = {
  version_id: Scalars['ID'];
};


export type GQLDraftSalesCategoryMaster_Category_MappingsArgs = {
  version_id: Scalars['ID'];
};

export type GQLDraftSalesCategoryDescription = {
  __typename?: 'DraftSalesCategoryDescription';
  description: GQLLocalizableValue;
  name: GQLLocalizableValue;
};

export type GQLDraftSalesCategoryHierarchyElement = {
  __typename?: 'DraftSalesCategoryHierarchyElement';
  draft_sales_category: GQLDraftSalesCategory;
  /** A sorted list of elements in the hierarchy */
  hierarchy?: Maybe<Array<GQLDraftSalesCategoryHierarchyElement>>;
  sort_order: Scalars['Int'];
};

export type GQLDraftSalesCategorySearchResult = {
  __typename?: 'DraftSalesCategorySearchResult';
  draftSalesCategory?: Maybe<GQLDraftSalesCategory>;
  /** DraftSalesCategory descriptions (Highlighted search results) */
  highlightedDescriptions?: Maybe<Array<GQLDraftSalesCategoryDescription>>;
};

export type GQLDraftSalesCategorySortOrder = {
  draft_sales_category_id: Scalars['ID'];
  sort_order: Scalars['Int'];
};

export type GQLDraftSalesCategorySortOrderUpdateResult = {
  __typename?: 'DraftSalesCategorySortOrderUpdateResult';
  draft_sales_category_id: Scalars['ID'];
  status: GQLDraftSalesCategorySortOrderUpdateStatus;
};

export enum GQLDraftSalesCategorySortOrderUpdateStatus {
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export type GQLEntitledAssortments = {
  __typename?: 'EntitledAssortments';
  allowedAssortmentsIds: Array<Scalars['String']>;
  blockedAssortmentsIds: Array<Scalars['String']>;
};

export type GQLEntityAssetUsageDefinitionInput = {
  asset_id: Scalars['ID'];
  show_for_locales: Array<Scalars['String']>;
  sort_order: Scalars['Int'];
  usage_type: Scalars['String'];
};

export type GQLEntityContext = {
  /** The unique, public identifier of the entity */
  entity_id: Scalars['String'];
  /** For entities recognized by KrampHub use one from the list https://kramp-hub.readme.io/docs/functional-logging#known-entity-types, for non-standard make up your own */
  entity_type: Scalars['String'];
};

export type GQLEntriesInCategory = {
  __typename?: 'EntriesInCategory';
  active_filters: Array<GQLFilter>;
  entries: Array<GQLCategoryEntry>;
  facets: Array<GQLFacet>;
  statistics: GQLStatistics;
};

export type GQLEnumValueSpec = {
  __typename?: 'EnumValueSpec';
  allowed_values: Array<Scalars['String']>;
  default_value: Scalars['String'];
};

export type GQLEnumValueSpecInput = {
  allowed_values: Array<Scalars['String']>;
  default_value: Scalars['String'];
};

export type GQLEventTriggerInput = {
  context: Array<GQLMapValueInput>;
  event_name: Scalars['ID'];
};

export type GQLExperiment = {
  __typename?: 'Experiment';
  /** User info who completed the experiment if any */
  completed_by?: Maybe<Scalars['String']>;
  /** Time info when the experiment was completed if any */
  completed_date?: Maybe<Scalars['Time']>;
  /** list of conditions for the experiment */
  conditions?: Maybe<Array<GQLCondition>>;
  /** Configuration of the experiment. */
  configuration: GQLExperimentConfiguration;
  /** Identifier of the experiment. */
  id: Scalars['String'];
  /** Current state of an experiment */
  state: GQLExperimentState;
};

export type GQLExperimentConfiguration = {
  __typename?: 'ExperimentConfiguration';
  conditions?: Maybe<Array<Scalars['String']>>;
  description?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['Time']>;
  experiment_type: GQLExperimentType;
  hypothesis?: Maybe<Scalars['String']>;
  initial_condition?: Maybe<Scalars['String']>;
  metadata_requirements?: Maybe<Array<GQLMetaDataRequirement>>;
  metric_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  ratio_for_distribution?: Maybe<Scalars['Int']>;
  required_metadata_keys?: Maybe<Array<Scalars['String']>>;
  research_info?: Maybe<Scalars['String']>;
  service_name?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['Time']>;
  tags?: Maybe<Array<Scalars['String']>>;
  target_audience?: Maybe<GQLTargetAudience>;
  target_metric_name?: Maybe<Scalars['String']>;
  winner_condition_id?: Maybe<Scalars['String']>;
};

export type GQLExperimentConfigurationInput = {
  /** List of condition identifiers (created via the condition api) to be used in the experiment. */
  conditions?: InputMaybe<Array<Scalars['String']>>;
  /** Configuration Specification Id to identify the experiment */
  configurationSpecId: Scalars['String'];
  /** Description for the experiment. */
  description?: InputMaybe<Scalars['String']>;
  /** Unix timestamp in milliseconds indicating when the experiment stops, if unset then the experiment will not */
  end_date?: InputMaybe<Scalars['Time']>;
  /** Type of the experiment executed, which controls how conditions will be returned for this experiment. */
  experiment_type?: InputMaybe<GQLExperimentType>;
  /** Hypothesis for experiment describing what this experiment should verify */
  hypothesis?: InputMaybe<Scalars['String']>;
  /** Identifier of the condition in the experiment that initially receives the majority of the traffi */
  initial_condition?: InputMaybe<Scalars['String']>;
  /** List of metadata requirement for experiment, to be able to activate experiments according to context */
  metadata_requirements?: InputMaybe<Array<GQLMetaDataRequirementInput>>;
  /** Readable name for the experiment. */
  name: Scalars['String'];
  /** Owner of the experiment. */
  owner?: InputMaybe<Scalars['String']>;
  /** Population ratio for the experiments with asymmetric distribution */
  ratio_for_distribution?: InputMaybe<Scalars['Int']>;
  /** Information about research done for the experiment */
  research_info?: InputMaybe<Scalars['String']>;
  /** Unix timestamp in milliseconds indicating when the experiment starts, if unset then the experiment will start */
  start_date?: InputMaybe<Scalars['Time']>;
  /** Set of tags that has to be specified on the request for a condition for this experiment to apply */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** Target audience for the experiment */
  target_audience?: InputMaybe<GQLTargetAudience>;
  /** Name of a target metric. Different from metric_name as it isn't used in feedback workflow */
  target_metric_name?: InputMaybe<Scalars['String']>;
};

export enum GQLExperimentState {
  /** Archived experiment */
  Archived = 'ARCHIVED',
  /** Manually cancelled experiment */
  Cancelled = 'CANCELLED',
  /** Successfully finished experiment. Should be calculated in real-time upon invocation */
  Completed = 'COMPLETED',
  /** Draft state, allows modifications */
  Draft = 'DRAFT',
  /** Currently running experiment. Should be calculated in real-time upon invocation */
  Running = 'RUNNING',
  /** Scheduled experiment */
  Scheduled = 'SCHEDULED',
  /** Default state UNKNOWN */
  Unknown = 'UNKNOWN'
}

export enum GQLExperimentType {
  /** Asymmetric distribution with selected ratio */
  Asymmetric = 'ASYMMETRIC',
  /** Let the service decide what type of experiment to use. */
  Default = 'DEFAULT',
  /** Select conditions using an epsilon greedy multi armed bandit strategy. */
  EpsilonGreedyMultiArmedBandit = 'EPSILON_GREEDY_MULTI_ARMED_BANDIT',
  /** Select uniformly from the conditions in the experiment. */
  Uniform = 'UNIFORM'
}

export type GQLExportRange = {
  endRange: Scalars['Int'];
  startRange: Scalars['Int'];
};

export type GQLExtendedAddressValue = {
  __typename?: 'ExtendedAddressValue';
  /** concatenated recipient_name, address_text, post_code, city and country_code */
  display_address: Scalars['String'];
};

export type GQLExtendedDeliveryAddress = {
  __typename?: 'ExtendedDeliveryAddress';
  /** concatenated recipient_name, address_text, postal_code, city and country_code */
  display_address: Scalars['String'];
};

export type GQLExtendedOrganization = {
  __typename?: 'ExtendedOrganization';
  /** combination of organization.country_code and organization.settings.language e.g. 'en_IE' */
  locale: Scalars['String'];
};

export type GQLExtendedQuotation = {
  __typename?: 'ExtendedQuotation';
  /** most recent proposal on the quotation */
  latest_proposal?: Maybe<GQLQuotationProposal>;
};

export type GQLFacet = {
  __typename?: 'Facet';
  description: GQLLocalizedValue;
  id: Scalars['ID'];
  name: GQLLocalizedValue;
  type: GQLFacetType;
  values?: Maybe<GQLFacetValues>;
};

export enum GQLFacetType {
  Range = 'RANGE',
  SingleSelect = 'SINGLE_SELECT',
  Slider = 'SLIDER',
  Term = 'TERM'
}

export type GQLFacetValues = GQLRangeFacetValues | GQLValueFacetValues;

export type GQLFallbackDeliveryOption = {
  __typename?: 'FallbackDeliveryOption';
  delivery_option_id: Scalars['String'];
  priority: Scalars['Int'];
};

export type GQLFallbackDeliveryOptionInput = {
  delivery_option_id: Scalars['String'];
  priority: Scalars['Int'];
};

export type GQLFeature = {
  __typename?: 'Feature';
  id: Scalars['ID'];
  toggle: GQLFeatureToggle;
};

export type GQLFeatureBasedTax = {
  __typename?: 'FeatureBasedTax';
  /** Feature of the item where the tax is calculated on */
  feature: GQLTaxFeature;
  /** Feature based tax amount */
  feature_based_tax_amount: GQLMonetaryValue;
  /** Base unit per which the tax would be applied. Eg., "Litre" */
  tax_base_unit?: Maybe<GQLTaxBaseUnit>;
  /** Total quantity in terms of the tax base unit to which the tax is applied */
  tax_base_unit_quantity: Scalars['Float'];
  /** Tax description. Eg., "Environmental Tax" */
  tax_description?: Maybe<Scalars['String']>;
  /** Tax value per base unit. Eg.,"0.50€ environmental tax per litre of acrylic paint." */
  tax_value_per_base_unit?: Maybe<GQLMonetaryValue>;
  /** Total tax amount */
  total_tax_amount: GQLMonetaryValue;
  /** Value based tax amount */
  value_based_tax_amount: GQLMonetaryValue;
  /** Value based taxes additionally applicable (if any) on the feature based tax. */
  value_based_tax_lines?: Maybe<Array<GQLValueBasedTax>>;
};

export type GQLFeatureToggle = GQLFeatureToggleBoolean | GQLFeatureToggleComplex;

export type GQLFeatureToggleBoolean = {
  __typename?: 'FeatureToggleBoolean';
  enabled: Scalars['Boolean'];
};

export type GQLFeatureToggleComplex = {
  __typename?: 'FeatureToggleComplex';
  value: Scalars['String'];
};

export enum GQLFeatureToggleSearchField {
  Ci = 'CI',
  Context = 'CONTEXT',
  Country = 'COUNTRY',
  Name = 'NAME',
  Unknown = 'UNKNOWN'
}

export type GQLFeatureToggleSearchFor = {
  /** Respecting the casing in the search_value or not */
  case_sensitive?: InputMaybe<Scalars['Boolean']>;
  /** Field on which the sorting is done */
  field: GQLFeatureToggleSearchField;
  /** How to perform the search */
  search_strategy: GQLFeatureToggleSearchStrategy;
  /** What to search for */
  search_value: Scalars['String'];
};

export enum GQLFeatureToggleSearchStrategy {
  ExactMatch = 'EXACT_MATCH',
  PartialMatch = 'PARTIAL_MATCH',
  Unknown = 'UNKNOWN'
}

export type GQLFeatureToggleSortBy = {
  /** How the sorting is done */
  descending: Scalars['Boolean'];
  /** Field on which the sorting is done */
  field: GQLFeatureToggleSortField;
};

export enum GQLFeatureToggleSortField {
  Name = 'NAME',
  Unknown = 'UNKNOWN'
}

export type GQLFeatureToggleSummary = {
  __typename?: 'FeatureToggleSummary';
  /** The contexts for which this feature toggle is active. */
  contexts?: Maybe<Array<Scalars['String']>>;
  /** All corporate identities for which the toggle has been configured. */
  corporate_identities?: Maybe<Array<Scalars['String']>>;
  /** All countries for which the toggle has been configured. */
  countries?: Maybe<Array<Scalars['String']>>;
  created_by?: Maybe<GQLAccount>;
  created_on?: Maybe<Scalars['Time']>;
  /** The default (fallback) value that is configured for this toggle. */
  default_value?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  last_modified_by?: Maybe<GQLAccount>;
  last_modified_on?: Maybe<Scalars['Time']>;
  /** The name of the feature toggle. */
  name: Scalars['String'];
  /** The total number of configured values for this feature toggle. Sometimes also called 'rules'. */
  total_number_of_values?: Maybe<Scalars['Int']>;
  /** All unique values that have been configured for this toggle. */
  unique_values?: Maybe<Array<Scalars['String']>>;
};

export type GQLFeatureToggleValue = {
  __typename?: 'FeatureToggleValue';
  /** Has a value if it was configured for an account (not in use in practice?). */
  account?: Maybe<Scalars['String']>;
  /** Has a value if it was configured for a corporate identity. */
  corporate_identity?: Maybe<Scalars['String']>;
  /** Has a value if it was configured for a country. */
  country?: Maybe<Scalars['String']>;
  created_by?: Maybe<GQLAccount>;
  created_on?: Maybe<Scalars['Time']>;
  /** The name of the feature toggle to which this value belongs. */
  feature_toggle_name: Scalars['String'];
  /** The internal identifier (does not have a specific meaning other than being the records primary key). */
  id: Scalars['String'];
  /** A higher value means a higher precedence (can be seen as weight basically). */
  importance: Scalars['Int'];
  last_modified_by?: Maybe<GQLAccount>;
  last_modified_on?: Maybe<Scalars['Time']>;
  /** Has a value if it was configured for an organization. */
  organization?: Maybe<Scalars['String']>;
  /** The configured value. */
  value: Scalars['String'];
};

/** Consider the 'target_' fields as 'one-of' */
export type GQLFeatureToggleValueForTargetInput = {
  importance: Scalars['Int'];
  target_account_id?: InputMaybe<Scalars['String']>;
  target_corporate_identity?: InputMaybe<Scalars['String']>;
  target_corporate_identity_and_locale?: InputMaybe<GQLCorporateIdentityAndLocaleInput>;
  target_locale?: InputMaybe<Scalars['String']>;
  target_organization_id?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export enum GQLFeatureToggleValueSearchField {
  Any = 'ANY',
  Ci = 'CI',
  Country = 'COUNTRY',
  Organization = 'ORGANIZATION',
  Unknown = 'UNKNOWN'
}

export type GQLFeatureToggleValueSearchFor = {
  /** Respecting the casing in the search_value or not */
  case_sensitive?: InputMaybe<Scalars['Boolean']>;
  /** Field on which the sorting is done */
  field: GQLFeatureToggleValueSearchField;
  /** How to perform the search */
  search_strategy: GQLFeatureToggleValueSearchStrategy;
  /** What to search for */
  search_value: Scalars['String'];
};

export enum GQLFeatureToggleValueSearchStrategy {
  ExactMatch = 'EXACT_MATCH',
  PartialMatch = 'PARTIAL_MATCH',
  Unknown = 'UNKNOWN'
}

export type GQLFeatureToggleValueSortBy = {
  /** How the sorting is done */
  descending?: InputMaybe<Scalars['Boolean']>;
  /** Field on which the sorting is done */
  field: GQLFeatureToggleValueSortField;
};

export enum GQLFeatureToggleValueSortField {
  Ci = 'CI',
  Country = 'COUNTRY',
  Importance = 'IMPORTANCE',
  Organization = 'ORGANIZATION',
  Unknown = 'UNKNOWN',
  Value = 'VALUE'
}

export enum GQLFieldType {
  Name = 'NAME',
  NoFieldSpecified = 'NO_FIELD_SPECIFIED',
  Owner = 'OWNER',
  Version = 'VERSION'
}

export type GQLFilter = {
  __typename?: 'Filter';
  field: Scalars['ID'];
  value: GQLFilterValue;
};

export type GQLFilterInput = {
  range_filters?: InputMaybe<Array<GQLRangeFilterInput>>;
  value_filters?: InputMaybe<Array<GQLValueFilterInput>>;
  values_filters?: InputMaybe<Array<GQLValuesFilterInput>>;
};

export type GQLFilterListConfigurationSpec = {
  field_type: GQLFieldType;
  search_type?: InputMaybe<GQLSearchType>;
  value?: InputMaybe<Scalars['String']>;
};

export type GQLFilterValue = GQLRangeFilter | GQLValueFilter | GQLValuesFilter;

export type GQLFindItemsResult = {
  __typename?: 'FindItemsResult';
  /** The filters that were used to produce these search results */
  active_filters: Array<GQLFilter>;
  facets: Array<GQLFacet>;
  items: Array<GQLItem>;
  /** Request interpretations are changes that Sherlock has applied to the request to (hopefully) improve relevance. */
  request_interpretations: GQLRequestInterpretations;
  /** Spellcheck suggestions for the query. */
  spellcheck_suggestions: GQLSpellcheckSuggestions;
  statistics: GQLStatistics;
};

export type GQLFindSearchResultSuppressionsResponse = {
  __typename?: 'FindSearchResultSuppressionsResponse';
  search_result_suppressions: Array<GQLSearchResultSuppression>;
};

export type GQLFindSearchResultSuppressionsResponseV2 = {
  __typename?: 'FindSearchResultSuppressionsResponseV2';
  searchResultSuppressions: Array<GQLSearchResultSuppressionV2>;
};

export type GQLFloatValue = {
  __typename?: 'FloatValue';
  value: Scalars['Float'];
};

export type GQLFloatValueInput = {
  value: Scalars['Float'];
};

export type GQLFloatValueSpec = {
  __typename?: 'FloatValueSpec';
  default_value: Scalars['Float'];
  max_value: Scalars['Float'];
  min_value: Scalars['Float'];
};

export type GQLFloatValueSpecInput = {
  default_value: Scalars['Float'];
  max_value: Scalars['Float'];
  min_value: Scalars['Float'];
};

export type GQLFreightChargeClass = {
  __typename?: 'FreightChargeClass';
  /** Amount of the Freight Charge */
  amount?: Maybe<GQLMonetaryValue>;
  /** Remaining amount needed to reach this Freight Charge class */
  remaining_amount?: Maybe<GQLMonetaryValue>;
};

export type GQLFreightChargeInput = {
  /** If true, quotation_effective_price will be combined with the effective prices of past orders within the same order period, e.g. 1 day. */
  consolidate_with_previous_orders: Scalars['Boolean'];
  /** The delivery address id to be used for the freight charge calculation. Custom addresses are not supported at this time. */
  delivery_address_id?: InputMaybe<Scalars['String']>;
  /** The delivery options as known to Pythia. */
  delivery_option_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Item ids that are in the current quotation */
  item_ids?: InputMaybe<Array<Scalars['String']>>;
};

export type GQLFreightChargeInputForRanges = {
  /** The delivery address id to be used for the freight charge calculation. Custom addresses are not supported at this time. */
  delivery_address_id?: InputMaybe<Scalars['String']>;
  /** The delivery option as known to Pythia. */
  delivery_option_id?: InputMaybe<Scalars['String']>;
  /** Item ids that are in the current quotation */
  item_ids?: InputMaybe<Array<Scalars['String']>>;
  /** The mode to use when consolidating the request parameters and orders from the daily buffer */
  order_consolidation_mode: GQLOrderConsolidationMode;
};

export type GQLFreightChargeRange = {
  __typename?: 'FreightChargeRange';
  /** The minimum order amount still required to qualify for this freight charge_amount */
  amount_from: GQLMonetaryValue;
  /** The freight charge_amount */
  charge_amount: GQLMonetaryValue;
};

export type GQLFreightChargeRangesWithTotal = {
  __typename?: 'FreightChargeRangesWithTotal';
  address_freight_charge_ranges: Array<GQLAddressDeliveryOptionsFreightChargeRanges>;
  /** total amount of freight charges */
  total_freight_charge?: Maybe<GQLMonetaryValue>;
  /** total amount of freight surcharges */
  total_freight_surcharge?: Maybe<GQLMonetaryValue>;
  /** sum of total freight surcharges and freight charges */
  total_shipping_costs?: Maybe<GQLMonetaryValue>;
};

export type GQLFreightChargeRule = {
  __typename?: 'FreightChargeRule';
  /** The freight charge amount */
  amount: GQLDecimalValue;
  /** Currency for the minimum effective price and the amount */
  currency: Scalars['String'];
  /** Delivery option as known to Pythia */
  delivery_option_id: Scalars['String'];
  /** Freight Plans */
  freight_plan: Scalars['String'];
  /** last modified date */
  last_modified_time?: Maybe<Scalars['Time']>;
  /** The minimum effective price */
  minimum_effective_price: GQLDecimalValue;
};

/** Upsert freight charge rule */
export type GQLFreightChargeRuleInputXpl = {
  amount: Scalars['Float'];
  currency: Scalars['String'];
  delivery_option_id: Scalars['String'];
  freight_plan: Scalars['String'];
  minimum_effective_price: Scalars['Float'];
};

/** Freight charge rule */
export type GQLFreightChargeRuleXpl = {
  __typename?: 'FreightChargeRuleXPL';
  chargeAmount?: Maybe<Scalars['Float']>;
  chargeAmounts?: Maybe<Array<Scalars['Float']>>;
  currency: Scalars['String'];
  deliveryOptionId: Scalars['String'];
  freightPlan: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  lastModifiedTime?: Maybe<Scalars['String']>;
  lastModifiedTimes?: Maybe<Array<Scalars['String']>>;
  minimumEffectivePrice?: Maybe<Scalars['Float']>;
  minimumEffectivePrices?: Maybe<Array<Scalars['Float']>>;
};

export type GQLFreightChargesMap = {
  __typename?: 'FreightChargesMap';
  freight_charges?: Maybe<Array<Maybe<GQLDeliveryOptionFreightCharge>>>;
};

export type GQLFreightSurcharge = {
  __typename?: 'FreightSurcharge';
  /** Item as described by ciske that contributes to this surcharge */
  items: Array<GQLItem>;
  /** Description of the freight surcharge e.g. 'Surcharge applied due to the length of the product' */
  localized_description: Scalars['String'];
  /** Short name of the freight surcharge e.g. LENGTH */
  localized_name: Scalars['String'];
  /** The total freight surcharge amount for this group of items */
  total_amount: GQLMonetaryValue;
};

export type GQLGenerateTurnoverReportInput = {
  /** Month on which the cumulative lines will be generated */
  base_month: Scalars['Int'];
  /** Year on which the previous year calculations will be based */
  base_year: Scalars['Int'];
  /** Organization whose report will be generated */
  organization_id: Scalars['String'];
};

export type GQLGenerateTurnoverReportResponse = {
  __typename?: 'GenerateTurnoverReportResponse';
  lines?: Maybe<Array<GQLTurnoverReportLine>>;
  organization_id?: Maybe<Scalars['String']>;
  totals?: Maybe<Array<GQLTurnoverReportValue>>;
};

export type GQLGeoLocation = {
  __typename?: 'GeoLocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GQLGeoLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GQLGetAssetsInput = {
  entity_id: Scalars['ID'];
  entity_type: GQLSoteriaEntityType;
};

export type GQLGetCollidingExperimentsInput = {
  configuration: GQLExperimentConfigurationInput;
};

export type GQLGetCommunicationContentInput = {
  event_id: Scalars['String'];
  event_type: Scalars['String'];
};

export type GQLGetCommunicationPreferencesInput = {
  account_id?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<GQLChannelType>;
  event_type: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['String']>;
};

export type GQLGetContentInput = {
  event_name: Scalars['ID'];
  mode: GQLGetContentMode;
};

export enum GQLGetContentMode {
  Preview = 'PREVIEW',
  Published = 'PUBLISHED'
}

export type GQLGetDetailedFreightChargeInput = {
  detailed_freight_charge_filter?: InputMaybe<GQLDetailedFreightChargeFilter>;
  quotation_to_consolidate?: InputMaybe<GQLQuotationToConsolidate>;
};

export type GQLGetEntityFeatureValuesInput = {
  entity_ids: Array<Scalars['ID']>;
  entity_type: Scalars['String'];
};

export type GQLGetItemSurchargesInput = {
  /** The delivery address id to be used for the surcharge calculation. If not set the default delivery address will be used. */
  delivery_address_id?: InputMaybe<Scalars['String']>;
  /** The delivery option id to be used for the surcharge calculation. If not set the default delivery option will be used. */
  delivery_option_id?: InputMaybe<Scalars['String']>;
  /** List of item ids to determine surcharges for. */
  item_ids: Array<Scalars['String']>;
};

export type GQLGetOrganizationAccessInput = {
  organization_id: Scalars['String'];
};

export type GQLGetPagedCommunicationPreferenceInput = {
  account_id?: InputMaybe<Scalars['String']>;
  channel?: InputMaybe<GQLChannelType>;
  event_type?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['String']>;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};

export type GQLGetReverseMakeModelInput = {
  item_id: Scalars['String'];
  search_query?: InputMaybe<Scalars['String']>;
};

export type GQLGetReverseMakeModelResult = {
  __typename?: 'GetReverseMakeModelResult';
  types: Array<GQLReverseMakeModelType>;
};

export type GQLGrannyEntity = {
  __typename?: 'GrannyEntity';
  id: Scalars['ID'];
  informants: Array<GQLGrannyInformant>;
};

export type GQLGrannyFeatureValue = {
  __typename?: 'GrannyFeatureValue';
  boolean?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  string?: Maybe<Scalars['String']>;
  string_map?: Maybe<Array<GQLMapValue>>;
};

export type GQLGrannyInformant = {
  __typename?: 'GrannyInformant';
  feature_values: Array<GQLGrannyFeatureValue>;
  id: Scalars['ID'];
};

export type GQLGrantOrganizationAccessInput = {
  corporate_identity: Scalars['String'];
  country_list: Array<Scalars['String']>;
  organization_id: Scalars['String'];
};

export type GQLGroupingConfiguration = {
  target_audience?: InputMaybe<GQLTargetAudience>;
};

export type GQLGuestIdentity = {
  __typename?: 'GuestIdentity';
  id_token: Scalars['String'];
  refresh_token: Scalars['String'];
};

export type GQLImage = {
  __typename?: 'Image';
  annotations: Array<GQLKv>;
  description?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type GQLInsteadSearchForSynonym = {
  __typename?: 'InsteadSearchForSynonym';
  /** What account_id has initially created this synonym */
  created_by_account_id?: Maybe<GQLAccount>;
  /** When was this synonym exactly created */
  creation_timestamp: Scalars['Time'];
  /** Identifier for this synonym */
  id: Scalars['String'];
  /** Language code that this synonym is applicable to */
  language: Scalars['String'];
  /** When was this update of suppressed items for this search term */
  last_update_timestamp: Scalars['Time'];
  /** When account_id has last modified this synonym */
  last_updated_by_account_id?: Maybe<GQLAccount>;
  /** Input terms for this synonym, when a user searches for any of these terms they will instead search for the terms in the search_terms field */
  query_terms?: Maybe<Array<Scalars['String']>>;
  /** What problem is this synonym supposed to resolve */
  resolve_type?: Maybe<GQLSynonymResolveType>;
  /** Search terms for this synonym, when a user searches for any of the terms in the query_terms field they will only search for the terms in this field */
  search_terms?: Maybe<Array<Scalars['String']>>;
};

export type GQLIntValue = {
  __typename?: 'IntValue';
  value: Scalars['Int'];
};

export type GQLIntValueInput = {
  value: Scalars['Int'];
};

export type GQLIntValueSpec = {
  __typename?: 'IntValueSpec';
  default_value: Scalars['Int'];
  max_value: Scalars['Int'];
  min_value: Scalars['Int'];
};

export type GQLIntValueSpecInput = {
  default_value: Scalars['Int'];
  max_value: Scalars['Int'];
  min_value: Scalars['Int'];
};

export type GQLIntent = {
  /** Any attributes relevant for the event. E.g. for add-to-quotation the quantity is relevant */
  attributes?: InputMaybe<Array<GQLIntentAttribute>>;
  /** Timestamp (in milliseconds) of the moment the user expressed their intent. In case it's left out, the moment of collection will be used. */
  client_event_timestamp?: InputMaybe<Scalars['Time']>;
  /** Any entities relevant for the intent. E.g. for add-to-quotation the quotation identifier and the offer identifier are relevant */
  entity_contexts?: InputMaybe<Array<GQLEntityContext>>;
  /** For standard events use one from the list at https://kramp-hub.readme.io/docs/functional-logging#known-event-names, for non-standard make up your own */
  event_name: Scalars['String'];
  /** The path to the UI component that was used to express the intent. E.g. the DOM path to the add-to-quotation button */
  ui_components?: InputMaybe<Array<GQLUiComponent>>;
};

export type GQLIntentAttribute = {
  /** The key of the attribute */
  key: Scalars['String'];
  /** The value of the attribute */
  value: Scalars['String'];
};

export type GQLInvoice = {
  __typename?: 'Invoice';
  buyer_organization_ids: Array<Scalars['String']>;
  currency: Scalars['String'];
  id: Scalars['ID'];
  invoice_due_date?: Maybe<Scalars['Time']>;
  invoice_issue_date?: Maybe<Scalars['Time']>;
  invoice_lines: Array<GQLInvoiceLine>;
  invoice_type: GQLInvoiceType;
  organization_id: Scalars['ID'];
  readable_invoice_id: Scalars['ID'];
  terms_of_payment: Scalars['String'];
  total_net_amount: GQLMonetaryValue;
};

export type GQLInvoiceLine = {
  __typename?: 'InvoiceLine';
  description?: Maybe<Scalars['String']>;
  /** Item */
  item?: Maybe<GQLItem>;
  item_id: Scalars['ID'];
  order_id: Scalars['ID'];
  order_line_comment?: Maybe<Scalars['String']>;
  order_line_id: Scalars['ID'];
  order_name: Scalars['String'];
  order_reference: Scalars['String'];
  quantity: Scalars['Float'];
  readable_order_id: Scalars['ID'];
  sales_unit: Scalars['String'];
  total_net_price: GQLMonetaryValue;
  unit_of_measurement?: Maybe<GQLUnitOfMeasurement>;
};

export enum GQLInvoiceType {
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE'
}

export enum GQLInvoicesQueryOrganizationRelationType {
  All = 'ALL',
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  Own = 'OWN'
}

export type GQLInvoicesSearchQuery = {
  description?: InputMaybe<Scalars['String']>;
  due_date_from?: InputMaybe<Scalars['Time']>;
  due_date_to?: InputMaybe<Scalars['Time']>;
  invoice_type?: InputMaybe<GQLInvoiceType>;
  issue_date_from?: InputMaybe<Scalars['Time']>;
  issue_date_to?: InputMaybe<Scalars['Time']>;
  item_id?: InputMaybe<Scalars['String']>;
  order_name?: InputMaybe<Scalars['String']>;
  order_reference?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['ID']>;
  organization_rel_type?: InputMaybe<GQLInvoicesQueryOrganizationRelationType>;
  readable_invoice_ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type GQLInvoicesSearchQuerySortBy = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field: GQLInvoicesSearchQuerySortField;
};

export enum GQLInvoicesSearchQuerySortField {
  InvoiceDueDate = 'INVOICE_DUE_DATE',
  InvoiceId = 'INVOICE_ID',
  InvoiceIssueDate = 'INVOICE_ISSUE_DATE',
  InvoiceTotalNetAmount = 'INVOICE_TOTAL_NET_AMOUNT',
  InvoiceType = 'INVOICE_TYPE',
  OrderDate = 'ORDER_DATE',
  OrderId = 'ORDER_ID',
  OrderReference = 'ORDER_REFERENCE',
  OrganizationId = 'ORGANIZATION_ID'
}

export type GQLItem = {
  __typename?: 'Item';
  /** Media assets coming from Soteria */
  assets?: Maybe<Array<GQLAsset>>;
  /** Brand of the item */
  brand?: Maybe<GQLBrand>;
  /** Classifications that describe the characteristics of an item. */
  classifications: Array<GQLClassification>;
  /** Atributes from the usages within a component */
  component_attributes_values: Array<GQLAttributeValue>;
  /** AttributeValues defining the item */
  defining_attribute_values: Array<GQLAttributeValue>;
  /** Localized description of the item */
  description: GQLLocalizedValue;
  /** AttributeValues describing the item */
  descriptive_attribute_values: Array<GQLAttributeValue>;
  /** An item can have zero or more European Article Numbers. */
  eans: Array<Scalars['ID']>;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Indicator for presence of volume discounts, Replaces: volume_discount_present */
  is_volume_discount_present?: Maybe<Scalars['Boolean']>;
  item_type: GQLItemType;
  /** Returns the legal documents that are attached to this Item */
  legal_documents?: Maybe<Array<GQLDocument>>;
  /** The path to the item in master catalog */
  master_category_path: Array<GQLMasterCategory>;
  /** Merchandising association relation with other items */
  merchandising_associations: Array<GQLMerchandisingAssociation>;
  /** Quantities ordered must be at least the minimum_quantity */
  minimum_quantity: Scalars['Float'];
  /** Localized name of the item */
  name: GQLLocalizedValue;
  path: Array<GQLCategory>;
  paths: Array<Array<GQLCategory>>;
  /** Unit of the quantity fields */
  quantity_unit: GQLUnitOfMeasurement;
  /** Item which replaces this item */
  replaced_by_item?: Maybe<GQLItem>;
  /** Quantities ordered must be a multiple of rounding_quantity */
  rounding_quantity: Scalars['Float'];
  /** Surcharge information for the item. */
  surcharge?: Maybe<GQLItemSurcharge>;
  /**
   * Gross price for sales unit, Deprecated: Use unit_grossprice instead
   * @deprecated Use unit_grossprice instead
   */
  unit_gross_price: GQLMonetaryValue;
  /** Unit gross promotion for none-configured items, for configured items use unit_gross_promotion in Quotation instead */
  unit_gross_promotion?: Maybe<GQLPromotion>;
  /**
   * Gross price for sales unit, Replaces: unit_gross_price, Deprecated: Use line_unit_gross_price on quotation line instead for Quotation
   * @deprecated Use line_unit_gross_price instead
   */
  unit_grossprice?: Maybe<GQLMonetaryValue>;
  /** Variant of the item */
  variant?: Maybe<GQLVariant>;
  /**
   * Indicator for presence of volume discounts, Deprecated: Use is_volume_discount_present instead
   * @deprecated Use is_volume_discount_present instead
   */
  volume_discount_present: Scalars['Boolean'];
};


export type GQLItemAssetsArgs = {
  isIncluded?: InputMaybe<Scalars['Boolean']>;
  usage_type?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLItemClassificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLItemComponent_Attributes_ValuesArgs = {
  component_id: Scalars['ID'];
};


export type GQLItemDefining_Attribute_ValuesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLItemDescriptive_Attribute_ValuesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLItemPathArgs = {
  catalog_id: Scalars['String'];
};


export type GQLItemPathsArgs = {
  catalog_id: Scalars['String'];
};

export type GQLItemDeliveryOptionLine = {
  item_id: Scalars['ID'];
  quantity: Scalars['Float'];
  quotation_line_id: Scalars['ID'];
};

export type GQLItemDeliveryOptions = {
  __typename?: 'ItemDeliveryOptions';
  delivery_options: Array<GQLDeliveryOption>;
  item_id: Scalars['ID'];
  promoted_delivery_option?: Maybe<GQLDeliveryOption>;
  quotation_line_id: Scalars['ID'];
};

export type GQLItemPriceOverview = {
  __typename?: 'ItemPriceOverview';
  /** Discount amount for the item */
  discount_amount: Scalars['Float'];
  /** Percentage discount of the item */
  discount_percentage: Scalars['Float'];
  /** Net margin percentage of the item */
  margin_percentage: Scalars['Float'];
  /** Minimum net margin percentage for the item */
  minimum_net_margin: Scalars['Float'];
  /** Effective minimum net price of the item */
  minimum_net_price: Scalars['Float'];
  /** Effective net price of the item */
  net_price: Scalars['Float'];
  /** Indicator if the quantity is rounded */
  quantity_is_rounded: Scalars['Boolean'];
  /** Rounded quantity of the item in the quotation line */
  rounded_quantity: Scalars['Float'];
  /** Global cost of the item */
  total_global_cost: Scalars['Float'];
  /** Total gross price of the item */
  total_gross_price: Scalars['Float'];
  /** Total local cost of the item */
  total_local_cost: Scalars['Float'];
  /** Total gross price of the unit */
  unit_gross_price: Scalars['Float'];
  /** Effective unit net price of the item */
  unit_net_price: Scalars['Float'];
};

export type GQLItemPriceOverviewInput = {
  /** Currency that the price is asked in */
  currency: Scalars['String'];
  /** Item related input */
  item?: InputMaybe<GQLPriceOverviewItem>;
  /** id of the organization that is requesting the price */
  organization_id: Scalars['String'];
};

export type GQLItemResult = {
  __typename?: 'ItemResult';
  item?: Maybe<GQLItem>;
  status?: Maybe<GQLItemStatus>;
};

export enum GQLItemStatus {
  Deleted = 'DELETED',
  Error = 'ERROR',
  NotFound = 'NOT_FOUND',
  Success = 'SUCCESS'
}

export type GQLItemSuggestion = {
  __typename?: 'ItemSuggestion';
  description: Scalars['String'];
  item: GQLItem;
  item_id: Scalars['String'];
  thumbnail: Scalars['String'];
};

export type GQLItemSuggestionProperties = {
  maximum_suggestions: Scalars['Int'];
};

export type GQLItemSurcharge = {
  __typename?: 'ItemSurcharge';
  /** Item id */
  item_id: Scalars['String'];
  /** Surcharge localized description if a surcharge applies */
  localized_surcharge_description: Scalars['String'];
  /** Surcharge localized name if a surcharge applies */
  localized_surcharge_name: Scalars['String'];
};

export enum GQLItemType {
  /** A configurable item can be further specified by the customer (for example a hose for which the connectors can be configured). */
  Configurable = 'CONFIGURABLE',
  /** SET means that the item is a set of items, these items are defined in the Bill Of Materials. */
  Set = 'SET',
  /** SKU (Stock Keeping Unit) means that the item is a regular item (not a Set - thus a Bill Of Materials is not applicable - and not configurable). */
  Sku = 'SKU',
  /** If the type is undefined then we threat the item as a regular item. */
  Unknown = 'UNKNOWN'
}

export type GQLItemUnitConversion = {
  __typename?: 'ItemUnitConversion';
  factor: Scalars['Float'];
  offer_id: Scalars['ID'];
  source_unit: Scalars['String'];
  target_unit: Scalars['String'];
};

export type GQLItemsInCategory = {
  __typename?: 'ItemsInCategory';
  /** The filters that were used to produce these search results */
  active_filters: Array<GQLFilter>;
  facets: Array<GQLFacet>;
  items: Array<GQLItem>;
  statistics: GQLStatistics;
};

export type GQLItemsInVariant = {
  __typename?: 'ItemsInVariant';
  items: Array<GQLItem>;
  statistics: GQLStatistics;
};

export type GQLItemsUsableForComponent = {
  __typename?: 'ItemsUsableForComponent';
  items: Array<GQLItem>;
  statistics: GQLStatistics;
};

export type GQLJob = {
  __typename?: 'Job';
  creation_time: Scalars['Time'];
  id: Scalars['ID'];
  is_created_by: Scalars['ID'];
  job_context: Array<GQLMapValue>;
  job_type: Scalars['ID'];
  products?: Maybe<Array<GQLJobProduct>>;
  status: GQLJobStatus;
  subscription_id: Scalars['ID'];
};

export type GQLJobProduct = {
  __typename?: 'JobProduct';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type GQLJobProductInput = {
  job_id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type GQLJobStatus = {
  __typename?: 'JobStatus';
  code: GQLJobStatusCode;
};

export enum GQLJobStatusCode {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  Finished = 'FINISHED',
  Pending = 'PENDING',
  QuotaExceeded = 'QUOTA_EXCEEDED',
  Running = 'RUNNING'
}

export type GQLJobStatusInput = {
  code: GQLJobStatusCode;
  job_id: Scalars['ID'];
};

export type GQLJobSubscriptionQuotaInput = {
  max_frequency_in_minutes: Scalars['Int'];
};

export type GQLJurisdiction = {
  __typename?: 'Jurisdiction';
  country: Scalars['String'];
  id: Scalars['String'];
  is_active: Scalars['Boolean'];
  seller: Scalars['String'];
  zone: Scalars['String'];
};

export type GQLJurisdictionInput = {
  country: Scalars['String'];
  seller: Scalars['String'];
  zone: Scalars['String'];
};

export type GQLJurisdictionUpdateInput = {
  id: Scalars['String'];
  is_active: Scalars['Boolean'];
};

export type GQLJurisdictionValueAddedTaxRates = {
  __typename?: 'JurisdictionValueAddedTaxRates';
  jurisdiction: GQLJurisdiction;
  tax_rates: Array<GQLValueAddedTaxRate>;
};

export type GQLKv = {
  __typename?: 'KV';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GQLKeyValueEntry = {
  __typename?: 'KeyValueEntry';
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

export type GQLKeyValueInput = {
  key: Scalars['String'];
  value: Array<Scalars['String']>;
};

/** Inputs */
export type GQLLabelFilter = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export type GQLListCollidingExperiments = {
  __typename?: 'ListCollidingExperiments';
  experiments: Array<GQLExperiment>;
};

export type GQLListConfigurationSpecFilterInput = {
  filters?: InputMaybe<Array<InputMaybe<GQLFilterListConfigurationSpec>>>;
  orders?: InputMaybe<Array<InputMaybe<GQLConfigurationSpecificationSortOrderInput>>>;
  page_number: Scalars['Int'];
  page_size: Scalars['Int'];
};

export type GQLListConfigurationSpecificationByFilterResult = {
  __typename?: 'ListConfigurationSpecificationByFilterResult';
  configuration_specifications: Array<Maybe<GQLPhoenixConfigurationSpec>>;
  page_statistics: GQLStatistics;
};

export type GQLListExperimentCommentResponse = {
  __typename?: 'ListExperimentCommentResponse';
  comments?: Maybe<Array<GQLComment>>;
  statistics: GQLStatistics;
};

export type GQLListExperimentFilter = {
  field?: InputMaybe<GQLListExperimentsSearchField>;
  type?: InputMaybe<GQLListExperimentsSearchType>;
  value?: InputMaybe<Scalars['String']>;
};

export type GQLListExperimentFilterInput = {
  filters?: InputMaybe<Array<GQLListExperimentFilter>>;
};

export type GQLListExperimentResult = {
  __typename?: 'ListExperimentResult';
  experiments: Array<GQLExperiment>;
  statistics: GQLStatistics;
};

export type GQLListExperimentSortBy = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<GQLListExperimentsSortField>;
};

export type GQLListExperimentSortByInput = {
  sort_by?: InputMaybe<Array<GQLListExperimentSortBy>>;
};

export enum GQLListExperimentsSearchField {
  CreatedBy = 'CREATED_BY',
  EndDate = 'END_DATE',
  ExperimentType = 'EXPERIMENT_TYPE',
  MetricName = 'METRIC_NAME',
  Name = 'NAME',
  ServiceName = 'SERVICE_NAME',
  StartDate = 'START_DATE',
  State = 'STATE',
  TargetAudience = 'TARGET_AUDIENCE',
  TargetMetricName = 'TARGET_METRIC_NAME',
  Unspecified = 'UNSPECIFIED'
}

export enum GQLListExperimentsSearchType {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  IsAnyOf = 'IS_ANY_OF',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  Unspecified = 'UNSPECIFIED'
}

export enum GQLListExperimentsSortField {
  CreatedBy = 'CREATED_BY',
  EndDate = 'END_DATE',
  ExperimentType = 'EXPERIMENT_TYPE',
  MetricName = 'METRIC_NAME',
  Name = 'NAME',
  ServiceName = 'SERVICE_NAME',
  StartDate = 'START_DATE',
  State = 'STATE',
  TargetAudience = 'TARGET_AUDIENCE',
  TargetMetricName = 'TARGET_METRIC_NAME',
  Unspecified = 'UNSPECIFIED'
}

export type GQLListFeatureToggleValuesInput = {
  /** List/search the values for the given feature toggle. Should be the full name of the feature toggle and respecting casing, basically treating it as an identifier. If left empty then not 'drilling down' on values for a specific feature toggle, but listing/searching all feature toggle values. */
  feature_toggle_name: Scalars['String'];
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  search_for?: InputMaybe<GQLFeatureToggleValueSearchFor>;
  sort_by?: InputMaybe<GQLFeatureToggleValueSortBy>;
};

export type GQLListFeatureToggleValuesOutput = {
  __typename?: 'ListFeatureToggleValuesOutput';
  values?: Maybe<Array<GQLFeatureToggleValue>>;
};

export type GQLListFeatureTogglesInput = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  search_for?: InputMaybe<GQLFeatureToggleSearchFor>;
  sort_by?: InputMaybe<GQLFeatureToggleSortBy>;
};

export type GQLListFeatureTogglesOutput = {
  __typename?: 'ListFeatureTogglesOutput';
  feature_toggles?: Maybe<Array<GQLFeatureToggleSummary>>;
};

export type GQLLocalizableValue = {
  __typename?: 'LocalizableValue';
  /** Locale of the value. Optional field, will be null if unknown. */
  locale?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type GQLLocalizedContent = {
  __typename?: 'LocalizedContent';
  content: Scalars['String'];
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
};

export type GQLLocalizedValue = {
  __typename?: 'LocalizedValue';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type GQLMakeModel = {
  __typename?: 'MakeModel';
  make?: Maybe<GQLMakeModelDetails>;
  model?: Maybe<GQLMakeModelDetails>;
  series?: Maybe<GQLMakeModelDetails>;
  type?: Maybe<GQLMakeModelDetails>;
};

export type GQLMakeModelDetails = {
  __typename?: 'MakeModelDetails';
  name: Scalars['String'];
  sourceId: Array<Scalars['String']>;
};

export type GQLMakeModelInfo = {
  __typename?: 'MakeModelInfo';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GQLMakeModelMakesResult = {
  __typename?: 'MakeModelMakesResult';
  makes: Array<GQLMakeModelInfo>;
};

export type GQLMakeModelModelsResult = {
  __typename?: 'MakeModelModelsResult';
  models: Array<GQLMakeModelInfo>;
};

export type GQLMakeModelSuggestion = {
  __typename?: 'MakeModelSuggestion';
  make: GQLMakeModelSuggestionItem;
  model: GQLMakeModelSuggestionItem;
  series?: Maybe<GQLMakeModelSuggestionItem>;
  thumbnail?: Maybe<Scalars['String']>;
  type: GQLMakeModelSuggestionItem;
};

export type GQLMakeModelSuggestionItem = {
  __typename?: 'MakeModelSuggestionItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type GQLMakeModelSuggestionProperties = {
  maximum_suggestions: Scalars['Int'];
};

export enum GQLMakeModelType {
  Make = 'MAKE',
  Model = 'MODEL',
  Series = 'SERIES',
  Type = 'TYPE'
}

export type GQLMakeModelTypesResult = {
  __typename?: 'MakeModelTypesResult';
  types: Array<GQLMakeModelInfo>;
};

export type GQLMapValue = {
  __typename?: 'MapValue';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GQLMapValueInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GQLMasterCategory = {
  __typename?: 'MasterCategory';
  /**
   * The attributes that are directly linked to this category.
   *     Not included are the attributes linked to child categories below this category.
   *     Also not included are the attributes linked to the parent category of this category.
   *     Please note that the localization of the attribute and its unit of measurement will be reduced
   *     to only the preferred language of the tenant.
   */
  attributes?: Maybe<Array<Maybe<GQLAttribute>>>;
  /** The child categories of this category (directly below this category) */
  child_categories?: Maybe<Array<Maybe<GQLMasterCategoryReference>>>;
  /** The identifier of the category */
  id: Scalars['ID'];
  /** The mapping status of the category */
  mapping_type?: Maybe<GQLMasterCategoryMappingType>;
  /** The name of the category (in the preferred language of the tenant) */
  name: Scalars['String'];
  /** The parent of this category */
  parent_category?: Maybe<GQLMasterCategoryReference>;
  /** The path to the category in master catalog, starting from the farthest, ending with the immediate parent */
  path?: Maybe<Array<GQLMasterCategory>>;
  /** Time of publishing by tenant as a linux timestamp in milliseconds */
  tenant_published_at?: Maybe<Scalars['Time']>;
};

export type GQLMasterCategoryHierarchyElement = {
  __typename?: 'MasterCategoryHierarchyElement';
  /** A sorted list of elements in the hierarchy */
  hierarchy?: Maybe<Array<GQLMasterCategoryHierarchyElement>>;
  master_category: GQLMasterCategory;
  sort_order: Scalars['Int'];
};

export type GQLMasterCategoryItems = {
  __typename?: 'MasterCategoryItems';
  items: Array<GQLItem>;
  statistics: GQLStatistics;
};

export enum GQLMasterCategoryMappingType {
  /** It is directly mapped to a sales category */
  DirectlyMapped = 'DIRECTLY_MAPPED',
  /** Manually ignored master category */
  Ignored = 'IGNORED',
  /** At least one of it's parents is directly mapped to a sales category */
  IndirectlyMapped = 'INDIRECTLY_MAPPED',
  /** At least one of it's children categories or items is directly mapped to a sales category */
  PartiallyMapped = 'PARTIALLY_MAPPED',
  /** Not mapped */
  Unmapped = 'UNMAPPED',
  /** Master category is not directly/indirectly mapped and doesn't have any item related directly to it or to any of its children categories */
  UnmappedButEmpty = 'UNMAPPED_BUT_EMPTY'
}

export type GQLMasterCategoryReference = {
  __typename?: 'MasterCategoryReference';
  /** The identifier of the category */
  id: Scalars['ID'];
  /** The name of the category (in the preferred language of the tenant) */
  name: Scalars['String'];
};

export type GQLMediaAsset = {
  __typename?: 'MediaAsset';
  /** Asset description */
  description?: Maybe<GQLLocalizedValue>;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Locales for which this media asset is available. If empty then this media asset is for all locales. */
  locales: Array<Scalars['String']>;
  /** Pointer to public service delivering the asset */
  media_handler: Scalars['String'];
  /** Type of this media asset, e.g. image/jpg */
  mime_type: Scalars['String'];
  /** Location for the contents */
  resource_location: Scalars['String'];
  /** Usage of the media asset (e.g. logo, technical drawing, etc...) */
  usage: Scalars['String'];
};

export type GQLMerchandisingAssociation = {
  __typename?: 'MerchandisingAssociation';
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** MerchandisingAssociationRelation describing the direction and contains the 'other' Item of the relation */
  item: GQLMerchandisingAssociationItem;
  /** Quality for this association (e.g. for replacement quality can be 'equal' or 'less') */
  quality: GQLMerchandisingAssociationQuality;
  /** Quantity of item relating to the other item depending on the type of this association */
  quantity: Scalars['Float'];
  /** The unit of measure of the quantity */
  quantity_unit?: Maybe<GQLUnitOfMeasurement>;
  /** Type of this association */
  type: GQLMerchandisingAssociationType;
};

/** MerchandisingAssociationRelation describing the direction and contains the 'other' Item of the relation */
export type GQLMerchandisingAssociationItem = {
  __typename?: 'MerchandisingAssociationItem';
  direction: GQLMerchandisingAssociationItemDirection;
  item: GQLItem;
};

export enum GQLMerchandisingAssociationItemDirection {
  From = 'FROM',
  To = 'TO'
}

export enum GQLMerchandisingAssociationQuality {
  Equal = 'EQUAL',
  Higher = 'HIGHER',
  Lower = 'LOWER',
  Unknown = 'UNKNOWN'
}

export enum GQLMerchandisingAssociationType {
  Accessory = 'ACCESSORY',
  Alternative = 'ALTERNATIVE',
  ComponentOf = 'COMPONENT_OF',
  ConsistOf = 'CONSIST_OF',
  Opposite = 'OPPOSITE',
  Recommended = 'RECOMMENDED',
  RequiredAccessory = 'REQUIRED_ACCESSORY',
  Similar = 'SIMILAR',
  SparePart = 'SPARE_PART',
  Supplementary = 'SUPPLEMENTARY',
  ToolFor = 'TOOL_FOR',
  Unknown = 'UNKNOWN'
}

export enum GQLMetaDataRequiredValueRule {
  CollectionContains = 'COLLECTION_CONTAINS',
  StringBeginsWith = 'STRING_BEGINS_WITH',
  StringContains = 'STRING_CONTAINS',
  StringEndsWith = 'STRING_ENDS_WITH',
  StringEquals = 'STRING_EQUALS',
  StringMatchRegex = 'STRING_MATCH_REGEX',
  Unspecified = 'UNSPECIFIED'
}

export type GQLMetaDataRequirement = {
  __typename?: 'MetaDataRequirement';
  metadata_key?: Maybe<Scalars['String']>;
  negate_rule: Scalars['Boolean'];
  required_values?: Maybe<Array<Scalars['String']>>;
  rule_for_required_value?: Maybe<GQLMetaDataRequiredValueRule>;
};

export type GQLMetaDataRequirementInput = {
  metadata_key?: InputMaybe<Scalars['String']>;
  negate_rule: Scalars['Boolean'];
  required_values?: InputMaybe<Array<Scalars['String']>>;
  rule_for_required_value?: InputMaybe<GQLMetaDataRequiredValueRule>;
};

export type GQLMetric = {
  __typename?: 'Metric';
  created_time: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['String'];
  labels?: Maybe<Array<Scalars['String']>>;
  last_updated: Scalars['Time'];
  name: Scalars['String'];
  required_metadata?: Maybe<Array<Scalars['String']>>;
};

export type GQLMetricListResult = {
  __typename?: 'MetricListResult';
  metrics?: Maybe<Array<GQLMetric>>;
  statistics: GQLStatistics;
};

export type GQLMetricRequestFilter = {
  /** Partial search based on the metric name property */
  metric_name: Scalars['String'];
};

export type GQLMetricResult = {
  __typename?: 'MetricResult';
  metric: GQLMetric;
};

export type GQLMockPerson = {
  __typename?: 'MockPerson';
  email: Scalars['String'];
  gender: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLMonetaryValue = {
  __typename?: 'MonetaryValue';
  /** Currency ISO 4217 */
  currency: Scalars['String'];
  value: Scalars['Float'];
};

export type GQLMutation = {
  __typename?: 'Mutation';
  CreateOrUpdateRestrictionRulesXPL: Scalars['Boolean'];
  DeleteFreightChargeRuleXPL: Scalars['Boolean'];
  DeleteRestrictionRulesXPL: Scalars['Boolean'];
  UpsertFreightChargeRuleXPL: GQLFreightChargeRuleXpl;
  /** Accept the document by its ID for a specific locale */
  accept_document: Scalars['Boolean'];
  /** Add address types for a given address */
  add_address_types: GQLAddress;
  add_campaign_items: GQLCampaign;
  add_campaign_tags: GQLCampaign;
  add_campaign_target_groups: GQLCampaign;
  /** Add Comment to an Experiment */
  add_comment_to_experiment?: Maybe<GQLComment>;
  /** Add a mapping from an item to a draft sales category */
  add_item_to_draft_sales_category_mapping: Scalars['Boolean'];
  /**
   * Add an item to quotation. Deprecated: Use add_items_to_quotation instead
   * @deprecated use add_items_to_quotation instead
   */
  add_item_to_quotation: GQLQuotation;
  /** Add an batch of items to quotation */
  add_items_to_quotation: GQLQuotation;
  add_items_to_wishlist: GQLWishlist;
  add_job_product?: Maybe<Scalars['Boolean']>;
  /** Add a mapping from a master category to a draft sales category */
  add_master_category_to_draft_sales_category_mapping: Scalars['Boolean'];
  add_organization: Scalars['ID'];
  /** Add link between organizations using organization IDs */
  add_organization_link: Scalars['Boolean'];
  add_organizations_to_static_target_group: Scalars['Boolean'];
  answerQuestion?: Maybe<Scalars['Boolean']>;
  /** Archive experiment by id. Only experiments not in RUNNING can be archived. */
  archive_experiment?: Maybe<GQLExperiment>;
  /** Assign role to a given account within given organization */
  assign_account_role: GQLAccount;
  /**
   * Assign a quotation to an account
   *     This operation signals that the quotation is to be worked on by a particular account. It is only a marker, which
   *     means that there are no enforced additinoal restrictions in other operations as a consequence of the assignment.
   */
  assign_quotation: GQLQuotation;
  /** Update discounts for all price promotion campaign items */
  bulk_update_discounts_for_campaigns: Scalars['Boolean'];
  /** Cancel an Experiment in SCHEDULED state by its identifier. */
  cancel_experiment?: Maybe<GQLExperiment>;
  /**
   * Cancel a quotation
   *     The quotation will be cancelled and transfer to the CANCELLED QuotationState.
   */
  cancel_quotation: GQLQuotation;
  /**
   * Claim a quotation
   *     The quotation will be claimed by the account requesting the claim. It will transfer to the CLAIMED QuotationState
   *     and only the claimer will be able to do modifying operations on the quotation as long as it is claimed. A quotation
   *     can be released by calling the ReleaseQuotation operation. A Quotation cannot be claimed if it is already claimed
   *     by someone else.
   */
  claim_quotation: GQLQuotation;
  /** Clear all properties on a quotation line. */
  clear_quotation_line_properties: GQLQuotation;
  /** Clear all references on a quotation line. */
  clear_quotation_line_references: GQLQuotation;
  /** Clear all properties on a quotation. */
  clear_quotation_properties: GQLQuotation;
  /** Complete an Experiment in SCHEDULED state by its identifier. */
  complete_experiment?: Maybe<GQLExperiment>;
  /** Convert an Open quotation to a Draft quotation */
  convert_quotation_proposal_to_draft: GQLQuotation;
  copy_price_promotion_campaign_items: Scalars['Boolean'];
  copy_static_target_group: GQLStaticTargetGroup;
  create_account: GQLAccount;
  /** Create an accound federation for the given user and provider */
  create_account_federation: Scalars['Boolean'];
  create_address: GQLAddress;
  create_campaign: GQLCampaign;
  /** Create conditions for new experiments */
  create_condition?: Maybe<GQLCondition>;
  /** Creates new config spec by given values if config already exists returns the existing one */
  create_config_spec?: Maybe<GQLPhoenixConfigurationSpec>;
  /** Creates new configuration based on config spec values */
  create_configuration?: Maybe<GQLPhoenixConfiguration>;
  /** Create a Draft experiment to be filled out later in time. */
  create_draft_experiment?: Maybe<GQLExperiment>;
  /**
   * Creates a new draft sales categories version to be used to group changes made to draft sales categories.
   *     Provide base_version_id to create a new version based on an existing one.
   *     If no base_version_id is provided, a new version will be created from scratch by the service and sent back
   */
  create_draft_sales_categories_version?: Maybe<GQLDraftSalesCategoriesVersion>;
  /** Creates a draft sales category. ID will be generated automatically if not provided */
  create_draft_sales_category?: Maybe<GQLDraftSalesCategory>;
  /** Creates a relation between two draft sales categories */
  create_draft_sales_category_relation: Scalars['Boolean'];
  /** Can be used to create a new feature toggle (all initially required 'parts': default value, contexts and values for targets) */
  create_feature_toggle?: Maybe<GQLCreateFeatureToggleOutput>;
  /** Management Commercial filtering: create or update existing delivery option Restriction rule with applicable conditions */
  create_or_update_restriction_rule: Scalars['Boolean'];
  create_or_update_subscription: Scalars['ID'];
  /**
   * Create a new quotation
   *     required permission: quotation.modify
   *     A new quotation will be created with the information in the request object. Using impersonation a quotation can be created for some other identity. The identity fields will be used in the following way:<ul><li> without impersonation (no ctx- headers provided)<ul><li>organizaton_id -> JWT.organization</li><li>creator_account_id -> JWT.account</li></ul></li><li>with impersonation (caller has security.impersonate permission and<ul><li>organizaton_id -> ctx-organization-id</li><li>creator_account_id -> JWT.account</li></ul></li></ul>
   */
  create_quotation: GQLQuotation;
  /** Creates a role */
  create_role: GQLAdminRole;
  /** Creates a relation between a catalog's root and a draft sales category */
  create_root_draft_sales_category_relation: Scalars['Boolean'];
  /** Create Suppress one or more items for a given search term */
  create_search_result_suppression: GQLSearchResultSuppression;
  create_static_target_group: GQLStaticTargetGroup;
  /** Create a new synonym */
  create_synonym: GQLSynonym;
  /** Can be used to create new values for a feature toggle (a.k.a. the 'rules') */
  create_values_for_feature_toggle?: Maybe<GQLCreateValuesForFeatureToggleOutput>;
  create_wishlist: GQLWishlist;
  define_asset_usage_for_entity: Scalars['String'];
  delete_account: Scalars['ID'];
  /** Delete the account federation for the given user and external provider */
  delete_account_federation: Scalars['Boolean'];
  delete_address: Scalars['ID'];
  delete_address_geo_location: GQLAddress;
  delete_asset_usage_for_entity: Scalars['String'];
  delete_campaign: Scalars['ID'];
  delete_campaign_items: GQLCampaign;
  delete_campaign_properties: GQLCampaign;
  delete_campaign_tags: GQLCampaign;
  delete_campaign_target_groups: GQLCampaign;
  /** Deletes a draft sales categories version */
  delete_draft_sales_categories_version: Scalars['Boolean'];
  /** Deletes a draft sales category from the version provided */
  delete_draft_sales_category: Scalars['Boolean'];
  /** Can be used to delete one or more specific values (a.k.a. 'rules') for a feature toggle */
  delete_feature_toggle_value?: Maybe<GQLDeleteFeatureToggleValueOutput>;
  /** Delete freight charge rule */
  delete_freight_charge_rule: Scalars['Boolean'];
  /** Delete mapping from an item to a draft sales category */
  delete_item_to_draft_sales_category_mapping: Scalars['Boolean'];
  delete_jurisdictions: Scalars['Boolean'];
  delete_lines_from_wishlist: GQLWishlist;
  delete_localized_content: Scalars['Boolean'];
  /** delete localized delivery option known as translation for delivery option */
  delete_localized_delivery_option: Scalars['Boolean'];
  /** Delete mapping from a master category to a draft sales category */
  delete_master_category_to_draft_sales_category_mapping: Scalars['Boolean'];
  delete_organization: Scalars['Boolean'];
  /** Delete link between organizations using organization IDs */
  delete_organization_link: Scalars['Boolean'];
  /** Delete priority fallback delivery options based on country code */
  delete_priority_fallback_delivery_options: Scalars['Boolean'];
  delete_quotation_freight_charge: Scalars['Boolean'];
  /** Remove a line from a quotation */
  delete_quotation_line: GQLQuotation;
  /** Delete a set of properties by key on a quotation line. */
  delete_quotation_line_properties: GQLQuotation;
  /** Delete a set of references by key on a quotation line. */
  delete_quotation_line_references: GQLQuotation;
  /** Delete a set of properties by key on a quotation. */
  delete_quotation_properties: GQLQuotation;
  /** Management Commercial filtering: delete one or more delivery option Restriction rules with applicable conditions */
  delete_restriction_rules: Scalars['Boolean'];
  /** Deletes a role */
  delete_role: Scalars['Boolean'];
  /** Restore (undo a suppression) of one or more items for a given search term */
  delete_search_result_suppression: Scalars['Boolean'];
  delete_static_target_group: Scalars['Boolean'];
  delete_style_properties: Scalars['Boolean'];
  /** Delete a synonym */
  delete_synonym: Scalars['Boolean'];
  delete_wishlists: Scalars['Boolean'];
  /** Extend an Experiment duration by its identifier and number of weeks. */
  extend_experiment_duration?: Maybe<GQLExperiment>;
  grant_organization_access: Scalars['Boolean'];
  /** Logs visitors intent as recognized by a channel. */
  log_intent: Scalars['Boolean'];
  /** Moves the draft sales category to a new parent category in the same version provided */
  move_draft_sales_category: Scalars['Boolean'];
  /** Overwrite the existent types in the address with the ones in the input */
  overwrite_address_types: GQLAddress;
  overwrite_organization_access: Scalars['Boolean'];
  /** Add/Modify AccountProfile settings. Applies to AccountProfile based on JWT or impersonation headers. */
  patch_account_profile_settings: GQLAccountProfile;
  /** Add/Modify one or more settings of an address. Existing settings are to be overwritten. */
  patch_address_settings?: Maybe<GQLAddress>;
  /** Add/Modify one or more properties of an organization. Currently existing properties will be merged */
  patch_organization_properties: Scalars['Boolean'];
  /** Add/Modify one or more settings of an organization. Currently existing settings will be merged */
  patch_organization_settings: Scalars['Boolean'];
  /**
   * Add/update a set of properties on a quotation line. All properties in the existing quotation line will be kept, if they are absent in this message.
   *     properties present in both will be overwritten by the new value from the message.
   */
  patch_quotation_line_properties: GQLQuotation;
  /**
   * Add/update a set of references an a quotation line. All references in the existing quotation line will be kept, if they are absent in this message.
   *     References present in both will be overwritten by the new value from the message.
   */
  patch_quotation_line_references: GQLQuotation;
  /** Update quotation lines with new details */
  patch_quotation_lines: GQLQuotation;
  /**
   * Add/update a set of properties on a quotation. All properties in the existing quotation will be kept, if they are absent in this message.
   *     properties present in both will be overwritten by the new value from the message.
   */
  patch_quotation_properties: GQLQuotation;
  /** Adds new role translations or overwrites them if they already exist */
  patch_role_translations: GQLAdminRole;
  patch_style?: Maybe<Array<GQLMapValue>>;
  /** Publishes the changes related to the draft sales categories in the version passed as parameter */
  publish_draft_sales_categories_version: Scalars['Boolean'];
  put_all_campaign_properties: GQLCampaign;
  /** Refresh id token */
  refresh_id_token: GQLRefreshIdToken;
  /** Reject a quotation Proposal */
  reject_quotation_proposal: GQLQuotation;
  /**
   * Release a quotation
   *     The quotation will be released and transfer to the OPEN QuotationState. Only a quotation with CLAIMED
   *     QuotationState can be released.
   */
  release_quotation: GQLQuotation;
  /** Remove address types for a given address */
  remove_address_types: GQLAddress;
  /** Remove the organization properties by keys */
  remove_organization_properties: Scalars['Boolean'];
  /** Remove the organization settings by keys */
  remove_organization_settings: Scalars['Boolean'];
  remove_organizations_from_static_target_group: Scalars['Boolean'];
  /** Removes role translation */
  remove_role_translation: GQLAdminRole;
  /** Reset a manual discount on quotation line */
  reset_quotation_line_manual_discount: GQLQuotation;
  /** Reset a manual discount on quotation level */
  reset_quotation_manual_discount: GQLQuotation;
  /**
   * Resolve a validation error
   *     This operation resolves a validation error by removing the error. The operation will not check that the cause
   *     of the error is actually resolved, this is the responsibility of the caller. If the error was in fact not
   *     resolved it will reappear in reserving for, or submitting the quotation.
   */
  resolve_validation_error: GQLQuotation;
  restrict_organization_access: Scalars['Boolean'];
  /** Schedule an Experiment in DRAFT state by its identifier. */
  schedule_experiment?: Maybe<GQLExperiment>;
  /** Create a Proposal from a Draft quotation */
  send_quotation_proposal: GQLQuotation;
  set_address_geo_location: GQLAddress;
  set_campaign_active: GQLCampaign;
  set_campaign_end_datetime: GQLCampaign;
  set_campaign_name: GQLCampaign;
  set_campaign_start_datetime: GQLCampaign;
  /** Set the default delivery address */
  set_default_delivery_address: Scalars['ID'];
  /** Sets the default quotation. */
  set_default_quotation: GQLQuotation;
  /** Set the default wishlist */
  set_default_wishlist: GQLWishlist;
  /** Sets a description for the draft sales category */
  set_draft_sales_category_description: Scalars['Boolean'];
  /** Updates sort orders for multiple draft sales categories in relation to a parent node */
  set_draft_sales_category_relations_sort_order: Array<GQLDraftSalesCategorySortOrderUpdateResult>;
  /** Returns true on success */
  set_draft_sales_category_sort_children_alphabetically: Scalars['Boolean'];
  /** Set the external submission url for the callers or given organization */
  set_external_submission_URL: Scalars['String'];
  set_localized_content: Scalars['Boolean'];
  /** Set the billing address on a quotation */
  set_quotation_billing_address: GQLQuotation;
  /** Set a comment for the quotation, freely to be filled by the customer */
  set_quotation_comment: GQLQuotation;
  /** Set a quotation consolidate fulfillment on quotation level */
  set_quotation_consolidate_fulfillment: GQLQuotation;
  /** Set a contact account for the quotation */
  set_quotation_contact_account: GQLQuotation;
  /** Set the delivery address on a quotation */
  set_quotation_delivery_address: GQLQuotation;
  /**
   * Set the selected delivery option on a quotation
   * @deprecated provide delivery option in submit_quotation mutation instead
   */
  set_quotation_delivery_option: GQLQuotation;
  /** Set a fixed price on a quotation line */
  set_quotation_fixed_price_information: GQLQuotation;
  /** Set a fixed freight charge for a specific quotation */
  set_quotation_freight_charge: Scalars['Boolean'];
  /** Set fulfillment instructions for the quotation */
  set_quotation_fulfillment_instructions: GQLQuotation;
  /** Set the comment on a quotation line */
  set_quotation_line_comment: GQLQuotation;
  /** Set a fixed price on a quotation line */
  set_quotation_line_fixed_price: GQLQuotation;
  /** Set the fulfillment instructions on a quotation line */
  set_quotation_line_fulfillment_instructions: GQLQuotation;
  /**
   * Set a manual discount on quotation line. Deprecated: Use set_quotation_lines_manual_discount instead
   * @deprecated use set_quotation_lines_manual_discount instead
   */
  set_quotation_line_manual_discount: GQLQuotation;
  /** Set the quantity of a quotation line */
  set_quotation_line_quantity: GQLQuotation;
  /** Set a requested shipping date on a quotation line */
  set_quotation_line_requested_shipping_date: GQLQuotation;
  /** Set manual discounts on quotation lines */
  set_quotation_lines_manual_discount: GQLQuotation;
  /** Set a manual discount on quotation level */
  set_quotation_manual_discount: GQLQuotation;
  /** Set a name for the quotation */
  set_quotation_name: GQLQuotation;
  /** Set a reference for another system on the quotation */
  set_quotation_reference: GQLQuotation;
  /** Set the invoiced_separately field on a quotation */
  set_quotation_separate_invoice: GQLQuotation;
  /** Map permissions to a role */
  set_role_permissions_mapping: GQLAdminRole;
  /** Updates sort orders for multiple draft sales categories in relation to the root node */
  set_root_draft_sales_category_relations_sort_order: Array<GQLDraftSalesCategorySortOrderUpdateResult>;
  set_value_added_tax_rates: Scalars['Boolean'];
  /** Set a variant as a winner for an experiment */
  set_winner_variant: GQLExperimentConfiguration;
  set_wishlist_comment: GQLWishlist;
  set_wishlist_line_comment: GQLWishlist;
  set_wishlist_line_quantity: GQLWishlist;
  set_wishlist_name: GQLWishlist;
  /** Share the quotation for the organization. */
  share_quotation: GQLQuotation;
  /** Share the wishlist for the organization. */
  share_wishlist: GQLWishlist;
  /** Creates new communication content by given values if content already exists override the existing one */
  store_communication_content: Scalars['Boolean'];
  /** Creates new communication preference by given values if preference already exists override the existing one */
  store_communication_preference: Scalars['Boolean'];
  /** Submit a quotation */
  submit_quotation: GQLQuotation;
  trigger_event: Scalars['ID'];
  /** Unassign role on a given account within given organization */
  unassign_account_role: GQLAccount;
  /** Unset/Remove the external submission url for the callers or given organization */
  unset_external_submission_URL?: Maybe<Scalars['String']>;
  updateMakeModelName: Scalars['String'];
  update_account: GQLAccount;
  /** Changes the status of an account to for example block/unblock */
  update_account_status: GQLAccount;
  update_address: GQLAddress;
  /** Can be used to update the contexts for a feature toggle (e.g. 'webshop', 'app', 'edi') */
  update_contexts_for_feature_toggle?: Maybe<GQLUpdateContextsForFeatureToggleOutput>;
  /** Can be used to update the default value of a feature toggle (aka the 'fallback') */
  update_default_value_for_feature_toggle?: Maybe<GQLUpdateDefaultValueForFeatureToggleOutput>;
  /** Can be used to update the description for a feature toggle */
  update_description_for_feature_toggle?: Maybe<GQLUpdateDescriptionForFeatureToggleOutput>;
  /** Update an existing Draft Experiment with new data */
  update_draft_experiment?: Maybe<GQLExperiment>;
  /** Update an Experiment */
  update_experiment?: Maybe<GQLExperiment>;
  /** Update Comment of an Experiment */
  update_experiment_comment?: Maybe<GQLComment>;
  /** Can be used to update a specific value (a.k.a 'rule') for a feature toggle */
  update_feature_toggle_value?: Maybe<GQLUpdateFeatureToggleValueOutput>;
  update_job_status?: Maybe<Scalars['Boolean']>;
  update_jurisdictions: Scalars['Boolean'];
  /** updates the organization, settings and properties will not be merged, service will replace them. */
  update_organization: Scalars['ID'];
  /** Update link between organizations using organization IDs */
  update_organization_link: Scalars['Boolean'];
  /** Updates a role */
  update_role: GQLAdminRole;
  /** Restore (undo a suppression) of one or more items for a given search term */
  update_search_result_suppression: GQLSearchResultSuppression;
  /** Update an existing synonym */
  update_synonym: GQLSynonym;
  upload_asset: Scalars['String'];
  upload_assets_for_usage: Scalars['String'];
  /** Insert or update a freight charge rule */
  upsert_freight_charge_rule: GQLFreightChargeRule;
  /** create or update localized delivery option known as translation for delivery option */
  upsert_localized_delivery_option: Scalars['Boolean'];
  /** Add/Modify price promotion campaign items */
  upsert_price_promotion_campaign_items: Scalars['Boolean'];
  /** Insert or Update priority fallback delivery options based on country code */
  upsert_priority_fallback_delivery_options: Scalars['Boolean'];
  validate_and_get_quotation: GQLQuotation;
  /**
   * Validate Quotation
   * @deprecated use validate_and_get_quotation query instead
   */
  validate_quotation: GQLQuotation;
};


export type GQLMutationCreateOrUpdateRestrictionRulesXplArgs = {
  input: GQLCreateOrUpdateRestrictionRulesInput;
};


export type GQLMutationDeleteFreightChargeRuleXplArgs = {
  input: GQLDeleteFreightChargeRuleInputXpl;
};


export type GQLMutationDeleteRestrictionRulesXplArgs = {
  restrictionRuleIds?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLMutationUpsertFreightChargeRuleXplArgs = {
  input: GQLFreightChargeRuleInputXpl;
};


export type GQLMutationAccept_DocumentArgs = {
  document_id: Scalars['ID'];
  locale: Scalars['String'];
};


export type GQLMutationAdd_Address_TypesArgs = {
  input: GQLAddAddressTypesInput;
};


export type GQLMutationAdd_Campaign_ItemsArgs = {
  input: GQLAddCampaignItemsInput;
};


export type GQLMutationAdd_Campaign_TagsArgs = {
  input: GQLAddCampaignTagsInput;
};


export type GQLMutationAdd_Campaign_Target_GroupsArgs = {
  input: GQLAddCampaignTargetGroupsInput;
};


export type GQLMutationAdd_Comment_To_ExperimentArgs = {
  comment: Scalars['String'];
  experimentId: Scalars['String'];
};


export type GQLMutationAdd_Item_To_Draft_Sales_Category_MappingArgs = {
  draft_sales_category_id: Scalars['ID'];
  item_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLMutationAdd_Item_To_QuotationArgs = {
  input: GQLAddItemToQuotationInput;
};


export type GQLMutationAdd_Items_To_QuotationArgs = {
  input: GQLAddItemsToQuotationInput;
};


export type GQLMutationAdd_Items_To_WishlistArgs = {
  input: GQLAddItemsToWishlistInput;
};


export type GQLMutationAdd_Job_ProductArgs = {
  product: GQLJobProductInput;
};


export type GQLMutationAdd_Master_Category_To_Draft_Sales_Category_MappingArgs = {
  draft_sales_category_id: Scalars['ID'];
  master_category_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLMutationAdd_OrganizationArgs = {
  input: GQLAddOrganizationInput;
};


export type GQLMutationAdd_Organization_LinkArgs = {
  input: GQLAddOrganizationLinkInput;
};


export type GQLMutationAdd_Organizations_To_Static_Target_GroupArgs = {
  input: GQLAddOrganizationsToStaticTargetGroupInput;
};


export type GQLMutationAnswerQuestionArgs = {
  answerId: Scalars['String'];
  workItemId: Scalars['String'];
};


export type GQLMutationArchive_ExperimentArgs = {
  experimentId: Scalars['String'];
};


export type GQLMutationAssign_Account_RoleArgs = {
  input: GQLAssignAccountRoleInput;
};


export type GQLMutationAssign_QuotationArgs = {
  input: GQLAssignQuotationInput;
};


export type GQLMutationBulk_Update_Discounts_For_CampaignsArgs = {
  input: GQLBulkUpdateDiscountsForCampaignsInput;
};


export type GQLMutationCancel_ExperimentArgs = {
  experimentId: Scalars['String'];
};


export type GQLMutationCancel_QuotationArgs = {
  input: GQLCancelQuotationInput;
};


export type GQLMutationClaim_QuotationArgs = {
  input: GQLClaimQuotationInput;
};


export type GQLMutationClear_Quotation_Line_PropertiesArgs = {
  input: GQLClearQuotationLinePropertiesInput;
};


export type GQLMutationClear_Quotation_Line_ReferencesArgs = {
  input: GQLClearQuotationLineReferencesInput;
};


export type GQLMutationClear_Quotation_PropertiesArgs = {
  input: GQLClearQuotationPropertiesInput;
};


export type GQLMutationComplete_ExperimentArgs = {
  experimentId: Scalars['String'];
};


export type GQLMutationConvert_Quotation_Proposal_To_DraftArgs = {
  input: GQLConvertQuotationProposalInput;
};


export type GQLMutationCopy_Price_Promotion_Campaign_ItemsArgs = {
  input: GQLCopyPricePromotionCampaignItemsInput;
};


export type GQLMutationCopy_Static_Target_GroupArgs = {
  input: Scalars['ID'];
};


export type GQLMutationCreate_AccountArgs = {
  input: GQLCreateAccountInput;
};


export type GQLMutationCreate_Account_FederationArgs = {
  input: GQLCreateAccountFederationInput;
};


export type GQLMutationCreate_AddressArgs = {
  input: GQLCreateAddressInput;
};


export type GQLMutationCreate_CampaignArgs = {
  input: GQLCreateCampaignInput;
};


export type GQLMutationCreate_ConditionArgs = {
  condition: GQLConditionInput;
};


export type GQLMutationCreate_Config_SpecArgs = {
  config: GQLCreatePhoenixConfigurationSpecInput;
};


export type GQLMutationCreate_ConfigurationArgs = {
  configuration: GQLCreatePhoenixConfigurationInput;
};


export type GQLMutationCreate_Draft_ExperimentArgs = {
  experiment: GQLExperimentConfigurationInput;
};


export type GQLMutationCreate_Draft_Sales_Categories_VersionArgs = {
  base_version_id?: InputMaybe<Scalars['ID']>;
};


export type GQLMutationCreate_Draft_Sales_CategoryArgs = {
  category_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  sort_children_alphabetically: Scalars['Boolean'];
  version_id: Scalars['ID'];
};


export type GQLMutationCreate_Draft_Sales_Category_RelationArgs = {
  catalog_id: Scalars['ID'];
  child_draft_sales_category_id: Scalars['ID'];
  parent_draft_sales_category_id: Scalars['ID'];
  sort_order: Scalars['Int'];
  version_id: Scalars['ID'];
};


export type GQLMutationCreate_Feature_ToggleArgs = {
  input: GQLCreateFeatureToggleInput;
};


export type GQLMutationCreate_Or_Update_Restriction_RuleArgs = {
  input: GQLCreateRestrictionRuleInput;
};


export type GQLMutationCreate_Or_Update_SubscriptionArgs = {
  sub: GQLSubscriptionInput;
};


export type GQLMutationCreate_QuotationArgs = {
  input: GQLCreateQuotationInput;
};


export type GQLMutationCreate_RoleArgs = {
  input: GQLCreateRoleInput;
};


export type GQLMutationCreate_Root_Draft_Sales_Category_RelationArgs = {
  catalog_id: Scalars['ID'];
  draft_sales_category_id: Scalars['ID'];
  sort_order: Scalars['Int'];
  version_id: Scalars['ID'];
};


export type GQLMutationCreate_Search_Result_SuppressionArgs = {
  search_term: Scalars['String'];
  suppressed_item_ids?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLMutationCreate_SynonymArgs = {
  language: Scalars['String'];
  synonym: GQLSynonymInput;
};


export type GQLMutationCreate_Values_For_Feature_ToggleArgs = {
  input: GQLCreateValuesForFeatureToggleInput;
};


export type GQLMutationCreate_WishlistArgs = {
  input: GQLCreateWishlistInput;
};


export type GQLMutationDefine_Asset_Usage_For_EntityArgs = {
  input: GQLDefineAssetUsageForEntityInput;
};


export type GQLMutationDelete_AccountArgs = {
  input: GQLDeleteAccountInput;
};


export type GQLMutationDelete_Account_FederationArgs = {
  input: GQLDeleteAccountFederationInput;
};


export type GQLMutationDelete_AddressArgs = {
  input: GQLDeleteAddressInput;
};


export type GQLMutationDelete_Address_Geo_LocationArgs = {
  input: GQLDeleteAddressGeoLocationInput;
};


export type GQLMutationDelete_Asset_Usage_For_EntityArgs = {
  input: GQLDeleteAssetUsageForEntityInput;
};


export type GQLMutationDelete_CampaignArgs = {
  input: GQLDeleteCampaignInput;
};


export type GQLMutationDelete_Campaign_ItemsArgs = {
  input: GQLDeleteCampaignItemsInput;
};


export type GQLMutationDelete_Campaign_PropertiesArgs = {
  input: GQLDeleteCampaignPropertiesInput;
};


export type GQLMutationDelete_Campaign_TagsArgs = {
  input: GQLDeleteCampaignTagsInput;
};


export type GQLMutationDelete_Campaign_Target_GroupsArgs = {
  input: GQLDeleteCampaignTargetGroupsInput;
};


export type GQLMutationDelete_Draft_Sales_Categories_VersionArgs = {
  version_id: Scalars['ID'];
};


export type GQLMutationDelete_Draft_Sales_CategoryArgs = {
  id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLMutationDelete_Feature_Toggle_ValueArgs = {
  input: GQLDeleteFeatureToggleValueInput;
};


export type GQLMutationDelete_Freight_Charge_RuleArgs = {
  input: GQLDeleteFreightChargeRuleInput;
};


export type GQLMutationDelete_Item_To_Draft_Sales_Category_MappingArgs = {
  draft_sales_category_id: Scalars['ID'];
  item_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLMutationDelete_JurisdictionsArgs = {
  input: Array<Scalars['String']>;
};


export type GQLMutationDelete_Lines_From_WishlistArgs = {
  input: GQLDeleteWishlistLinesInput;
};


export type GQLMutationDelete_Localized_ContentArgs = {
  input: GQLDeleteLocalizedContentInput;
};


export type GQLMutationDelete_Localized_Delivery_OptionArgs = {
  input: GQLDeleteLocalizedDeliveryOptionInput;
};


export type GQLMutationDelete_Master_Category_To_Draft_Sales_Category_MappingArgs = {
  draft_sales_category_id: Scalars['ID'];
  master_category_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLMutationDelete_OrganizationArgs = {
  input: Scalars['ID'];
};


export type GQLMutationDelete_Organization_LinkArgs = {
  input: GQLDeleteOrganizationLinkInput;
};


export type GQLMutationDelete_Priority_Fallback_Delivery_OptionsArgs = {
  country: Scalars['String'];
};


export type GQLMutationDelete_Quotation_Freight_ChargeArgs = {
  input: Scalars['String'];
};


export type GQLMutationDelete_Quotation_LineArgs = {
  input: GQLDeleteQuotationLineInput;
};


export type GQLMutationDelete_Quotation_Line_PropertiesArgs = {
  input: GQLDeleteQuotationLinePropertiesInput;
};


export type GQLMutationDelete_Quotation_Line_ReferencesArgs = {
  input: GQLDeleteQuotationLineReferencesInput;
};


export type GQLMutationDelete_Quotation_PropertiesArgs = {
  input: GQLDeleteQuotationPropertiesInput;
};


export type GQLMutationDelete_Restriction_RulesArgs = {
  restriction_rule_ids: Array<Scalars['String']>;
};


export type GQLMutationDelete_RoleArgs = {
  input: GQLDeleteRoleInput;
};


export type GQLMutationDelete_Search_Result_SuppressionArgs = {
  search_term: Scalars['String'];
};


export type GQLMutationDelete_Static_Target_GroupArgs = {
  input: Scalars['ID'];
};


export type GQLMutationDelete_Style_PropertiesArgs = {
  input: GQLDeleteStylePropertiesInput;
};


export type GQLMutationDelete_SynonymArgs = {
  synonym_id: Scalars['String'];
};


export type GQLMutationDelete_WishlistsArgs = {
  input: GQLDeleteWishlistsInput;
};


export type GQLMutationExtend_Experiment_DurationArgs = {
  experimentId: Scalars['String'];
  numberOfWeeks: Scalars['Int'];
};


export type GQLMutationGrant_Organization_AccessArgs = {
  input: GQLGrantOrganizationAccessInput;
};


export type GQLMutationLog_IntentArgs = {
  intent: GQLIntent;
};


export type GQLMutationMove_Draft_Sales_CategoryArgs = {
  catalog_id: Scalars['ID'];
  current_parent_id: Scalars['ID'];
  draft_sales_category_id: Scalars['ID'];
  new_parent_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLMutationOverwrite_Address_TypesArgs = {
  address_id: Scalars['ID'];
  address_types: Array<GQLAddressType>;
};


export type GQLMutationOverwrite_Organization_AccessArgs = {
  input: GQLOverwriteOrganizationAccessInput;
};


export type GQLMutationPatch_Account_Profile_SettingsArgs = {
  settings: Array<GQLMapValueInput>;
};


export type GQLMutationPatch_Address_SettingsArgs = {
  id: Scalars['ID'];
  settings: Array<GQLMapValueInput>;
};


export type GQLMutationPatch_Organization_PropertiesArgs = {
  input: GQLPatchOrganizationPropertiesInput;
};


export type GQLMutationPatch_Organization_SettingsArgs = {
  input: GQLPatchOrganizationSettingsInput;
};


export type GQLMutationPatch_Quotation_Line_PropertiesArgs = {
  input: GQLPatchQuotationLinePropertiesInput;
};


export type GQLMutationPatch_Quotation_Line_ReferencesArgs = {
  input: GQLPatchQuotationLineReferencesInput;
};


export type GQLMutationPatch_Quotation_LinesArgs = {
  input: GQLPatchQuotationLinesInput;
};


export type GQLMutationPatch_Quotation_PropertiesArgs = {
  input: GQLPatchQuotationPropertiesInput;
};


export type GQLMutationPatch_Role_TranslationsArgs = {
  input: GQLPatchRoleTranslationsInput;
};


export type GQLMutationPatch_StyleArgs = {
  input: GQLPatchStyleInput;
};


export type GQLMutationPublish_Draft_Sales_Categories_VersionArgs = {
  version_id: Scalars['ID'];
};


export type GQLMutationPut_All_Campaign_PropertiesArgs = {
  input: GQLPutAllCampaignPropertiesInput;
};


export type GQLMutationRefresh_Id_TokenArgs = {
  input: GQLRefreshIdTokenInput;
};


export type GQLMutationReject_Quotation_ProposalArgs = {
  input: GQLRejectQuotationProposalInput;
};


export type GQLMutationRelease_QuotationArgs = {
  input: GQLReleaseQuotationInput;
};


export type GQLMutationRemove_Address_TypesArgs = {
  input: GQLRemoveAddressTypesInput;
};


export type GQLMutationRemove_Organization_PropertiesArgs = {
  input: GQLRemoveOrganizationPropertiesInput;
};


export type GQLMutationRemove_Organization_SettingsArgs = {
  input: GQLRemoveOrganizationSettingsInput;
};


export type GQLMutationRemove_Organizations_From_Static_Target_GroupArgs = {
  input: GQLRemoveOrganizationsFromStaticTargetGroupInput;
};


export type GQLMutationRemove_Role_TranslationArgs = {
  input: GQLRemoveRoleTranslationInput;
};


export type GQLMutationReset_Quotation_Line_Manual_DiscountArgs = {
  input: GQLResetQuotationLineManualDiscountInput;
};


export type GQLMutationReset_Quotation_Manual_DiscountArgs = {
  input: GQLResetQuotationManualDiscountInput;
};


export type GQLMutationResolve_Validation_ErrorArgs = {
  quotation_id: Scalars['ID'];
  validation_error_id: Scalars['ID'];
};


export type GQLMutationRestrict_Organization_AccessArgs = {
  input: GQLRestrictOrganizationAccessInput;
};


export type GQLMutationSchedule_ExperimentArgs = {
  experimentId: Scalars['String'];
};


export type GQLMutationSend_Quotation_ProposalArgs = {
  input: GQLSendQuotationProposalInput;
};


export type GQLMutationSet_Address_Geo_LocationArgs = {
  input: GQLSetAddressGeoLocationInput;
};


export type GQLMutationSet_Campaign_ActiveArgs = {
  input: GQLSetCampaignActiveInput;
};


export type GQLMutationSet_Campaign_End_DatetimeArgs = {
  input: GQLSetCampaignEndDatetimeInput;
};


export type GQLMutationSet_Campaign_NameArgs = {
  input: GQLSetCampaignNameInput;
};


export type GQLMutationSet_Campaign_Start_DatetimeArgs = {
  input: GQLSetCampaigStartDatetimeInput;
};


export type GQLMutationSet_Default_Delivery_AddressArgs = {
  addressId: Scalars['ID'];
};


export type GQLMutationSet_Default_QuotationArgs = {
  input: GQLSetDefaultQuotationInput;
};


export type GQLMutationSet_Default_WishlistArgs = {
  input: GQLSetDefaultWishlistInput;
};


export type GQLMutationSet_Draft_Sales_Category_DescriptionArgs = {
  description?: InputMaybe<Scalars['String']>;
  draft_sales_category_id: Scalars['ID'];
  locale: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  version_id: Scalars['ID'];
};


export type GQLMutationSet_Draft_Sales_Category_Relations_Sort_OrderArgs = {
  catalog_id: Scalars['ID'];
  parent_draft_sales_category_id: Scalars['ID'];
  sort_orders: Array<InputMaybe<GQLDraftSalesCategorySortOrder>>;
  version_id: Scalars['ID'];
};


export type GQLMutationSet_Draft_Sales_Category_Sort_Children_AlphabeticallyArgs = {
  draft_sales_category_id: Scalars['ID'];
  sort_children_alphabetically: Scalars['Boolean'];
  version_id: Scalars['ID'];
};


export type GQLMutationSet_External_Submission_UrlArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
};


export type GQLMutationSet_Localized_ContentArgs = {
  input: GQLSetLocalizedContentInput;
};


export type GQLMutationSet_Quotation_Billing_AddressArgs = {
  input: GQLSetQuotationBillingAddressInput;
};


export type GQLMutationSet_Quotation_CommentArgs = {
  input: GQLSetQuotationCommentInput;
};


export type GQLMutationSet_Quotation_Consolidate_FulfillmentArgs = {
  input: GQLSetQuotationConsolidateFulfillmentInput;
};


export type GQLMutationSet_Quotation_Contact_AccountArgs = {
  input: GQLSetQuotationContactAccountInput;
};


export type GQLMutationSet_Quotation_Delivery_AddressArgs = {
  input: GQLSetQuotationDeliveryAddressInput;
};


export type GQLMutationSet_Quotation_Delivery_OptionArgs = {
  input: GQLSetQuotationDeliveryOptionInput;
};


export type GQLMutationSet_Quotation_Fixed_Price_InformationArgs = {
  input: GQLSetQuotationFixedPriceInformationInput;
};


export type GQLMutationSet_Quotation_Freight_ChargeArgs = {
  input: GQLSetQuotationFreightChargeInput;
};


export type GQLMutationSet_Quotation_Fulfillment_InstructionsArgs = {
  input: GQLSetQuotationFulfillmentInstructions;
};


export type GQLMutationSet_Quotation_Line_CommentArgs = {
  input: GQLSetQuotationLineCommentInput;
};


export type GQLMutationSet_Quotation_Line_Fixed_PriceArgs = {
  input: GQLSetQuotationLineFixedPricesInput;
};


export type GQLMutationSet_Quotation_Line_Fulfillment_InstructionsArgs = {
  input: GQLSetQuotationLineFulfillmentInstructionsInput;
};


export type GQLMutationSet_Quotation_Line_Manual_DiscountArgs = {
  input: GQLQuotationLineManualDiscountInput;
};


export type GQLMutationSet_Quotation_Line_QuantityArgs = {
  input?: InputMaybe<GQLSetQuotationLineQuantityInput>;
};


export type GQLMutationSet_Quotation_Line_Requested_Shipping_DateArgs = {
  input: GQLSetQuotationLineRequestedShippingDateInput;
};


export type GQLMutationSet_Quotation_Lines_Manual_DiscountArgs = {
  input: GQLSetQuotationLinesManualDiscountInput;
};


export type GQLMutationSet_Quotation_Manual_DiscountArgs = {
  input: GQLQuotationManualDiscountInput;
};


export type GQLMutationSet_Quotation_NameArgs = {
  input: GQLSetQuotationNameInput;
};


export type GQLMutationSet_Quotation_ReferenceArgs = {
  input?: InputMaybe<GQLSetQuotationReferenceInput>;
};


export type GQLMutationSet_Quotation_Separate_InvoiceArgs = {
  input: GQLSetQuotationSeperateInvoiceInput;
};


export type GQLMutationSet_Role_Permissions_MappingArgs = {
  input: GQLUpsertRolePermissionsMappingInput;
};


export type GQLMutationSet_Root_Draft_Sales_Category_Relations_Sort_OrderArgs = {
  catalog_id: Scalars['ID'];
  sort_orders: Array<InputMaybe<GQLDraftSalesCategorySortOrder>>;
  version_id: Scalars['ID'];
};


export type GQLMutationSet_Value_Added_Tax_RatesArgs = {
  input: Array<GQLSetValueAddedTaxRatesInput>;
};


export type GQLMutationSet_Winner_VariantArgs = {
  winner: GQLSetWinnerVariantInput;
};


export type GQLMutationSet_Wishlist_CommentArgs = {
  input: GQLSetWishlistCommentInput;
};


export type GQLMutationSet_Wishlist_Line_CommentArgs = {
  input: GQLSetWishlistLineCommentInput;
};


export type GQLMutationSet_Wishlist_Line_QuantityArgs = {
  input: GQLSetWishlistLineQuantityInput;
};


export type GQLMutationSet_Wishlist_NameArgs = {
  input: GQLSetWishlistNameInput;
};


export type GQLMutationShare_QuotationArgs = {
  input: GQLShareQuotationInput;
};


export type GQLMutationShare_WishlistArgs = {
  input?: InputMaybe<GQLShareWishlistInput>;
};


export type GQLMutationStore_Communication_ContentArgs = {
  input: GQLStoreCommunicationContentInput;
};


export type GQLMutationStore_Communication_PreferenceArgs = {
  input: GQLStoreCommunicationPreferenceInput;
};


export type GQLMutationSubmit_QuotationArgs = {
  input: GQLSubmitQuotationInput;
};


export type GQLMutationTrigger_EventArgs = {
  event: GQLEventTriggerInput;
};


export type GQLMutationUnassign_Account_RoleArgs = {
  input: GQLUnassignAccountRoleInput;
};


export type GQLMutationUnset_External_Submission_UrlArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
};


export type GQLMutationUpdateMakeModelNameArgs = {
  makeModelType: GQLMakeModelType;
  newName: Scalars['String'];
  oldName: Scalars['String'];
};


export type GQLMutationUpdate_AccountArgs = {
  input: GQLUpdateAccountInput;
};


export type GQLMutationUpdate_Account_StatusArgs = {
  input: GQLUpdateAccountStatusInput;
};


export type GQLMutationUpdate_AddressArgs = {
  input: GQLUpdateAddressInput;
};


export type GQLMutationUpdate_Contexts_For_Feature_ToggleArgs = {
  input: GQLUpdateContextsForFeatureToggleInput;
};


export type GQLMutationUpdate_Default_Value_For_Feature_ToggleArgs = {
  input: GQLUpdateDefaultValueForFeatureToggleInput;
};


export type GQLMutationUpdate_Description_For_Feature_ToggleArgs = {
  input: GQLUpdateDescriptionForFeatureToggleInput;
};


export type GQLMutationUpdate_Draft_ExperimentArgs = {
  experiment: GQLExperimentConfigurationInput;
  experimentId: Scalars['String'];
};


export type GQLMutationUpdate_ExperimentArgs = {
  experiment: GQLUpdateExperimentInput;
  experimentId: Scalars['String'];
};


export type GQLMutationUpdate_Experiment_CommentArgs = {
  comment: Scalars['String'];
  commentId: Scalars['String'];
};


export type GQLMutationUpdate_Feature_Toggle_ValueArgs = {
  input: GQLUpdateFeatureToggleValueInput;
};


export type GQLMutationUpdate_Job_StatusArgs = {
  status: GQLJobStatusInput;
};


export type GQLMutationUpdate_JurisdictionsArgs = {
  input: Array<GQLJurisdictionUpdateInput>;
};


export type GQLMutationUpdate_OrganizationArgs = {
  input: GQLUpdateOrganizationInput;
};


export type GQLMutationUpdate_Organization_LinkArgs = {
  input: GQLUpdateOrganizationLinkInput;
};


export type GQLMutationUpdate_RoleArgs = {
  input: GQLUpdateRoleInput;
};


export type GQLMutationUpdate_Search_Result_SuppressionArgs = {
  search_term: Scalars['String'];
  suppressed_item_ids?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLMutationUpdate_SynonymArgs = {
  synonym: GQLSynonymInput;
  synonym_id: Scalars['String'];
};


export type GQLMutationUpload_AssetArgs = {
  file: Scalars['Upload'];
  input: GQLUploadAssetInput;
};


export type GQLMutationUpload_Assets_For_UsageArgs = {
  files: Array<InputMaybe<Scalars['Upload']>>;
  input: GQLUploadAssetsForUsageInput;
};


export type GQLMutationUpsert_Freight_Charge_RuleArgs = {
  input: GQLUpsertFreightChargeRuleInput;
};


export type GQLMutationUpsert_Localized_Delivery_OptionArgs = {
  input: GQLUpsertLocalizedDeliveryOptionInput;
};


export type GQLMutationUpsert_Price_Promotion_Campaign_ItemsArgs = {
  input: GQLUpsertPricePromotionCampaignItemsInput;
};


export type GQLMutationUpsert_Priority_Fallback_Delivery_OptionsArgs = {
  input: Array<GQLUpsertPriorityFallbackDeliveryOptionsInput>;
};


export type GQLMutationValidate_And_Get_QuotationArgs = {
  input: GQLValidateQuotationInput;
};


export type GQLMutationValidate_QuotationArgs = {
  quotation_id: Scalars['ID'];
};

export type GQLNegotiatedDetails = {
  __typename?: 'NegotiatedDetails';
  /** The discount amount based on the discount percentage */
  discount_amount: Scalars['Float'];
  /** The discounted percentage simulated for the quotation line */
  discount_percentage: Scalars['Float'];
  /** The discount reason associated with the quotation line. Tbd by the service */
  discount_reason: Scalars['String'];
  /** The effective price for the quotation line */
  effective_price: Scalars['Float'];
  /** The gross price for the line */
  line_gross_price: Scalars['Float'];
  /** The margin percentage for the business */
  margin_percentage: Scalars['Float'];
};

export type GQLNegotiatedQuotationLine = {
  /** Identifier that links this line to a configurable item in the tenant's pricing and/or ordering system */
  configuration_id?: InputMaybe<Scalars['String']>;
  /** Item id being negotiated */
  item_id?: InputMaybe<Scalars['String']>;
  /** Quantity of the item being negotiated */
  quantity: Scalars['Float'];
  /** Id of the quotation line that this simulation will replace */
  replaces_quotation_line_id?: InputMaybe<Scalars['String']>;
  /** Unit of measure for the item being negotiated */
  unit_of_measure: Scalars['String'];
};

/** Normalize translation data */
export type GQLNormalizeTranslationData = {
  __typename?: 'NormalizeTranslationData';
  deliveryOptionId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  translations?: Maybe<Array<GQLDescriptionLocale>>;
  translationsFillRate?: Maybe<Scalars['String']>;
};

export type GQLOffer = {
  __typename?: 'Offer';
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  is_buyable: Scalars['Boolean'];
  is_returnable: Scalars['Boolean'];
  item?: Maybe<GQLItem>;
  minimum_quantity: Scalars['Float'];
  quantity_unit_id: Scalars['ID'];
  rounding_quantity: Scalars['Float'];
  seller_organization: Scalars['ID'];
};

export type GQLOffersForItem = {
  __typename?: 'OffersForItem';
  Offer: Array<GQLOffer>;
  item_id: Scalars['ID'];
};

export type GQLOrder = {
  __typename?: 'Order';
  /** Free text comment */
  comment?: Maybe<Scalars['String']>;
  /** Guid; globally unique */
  id: Scalars['ID'];
  /** Last modification (e.g. status change) time */
  last_updated_datetime: Scalars['Time'];
  /** Name of the order */
  name: Scalars['String'];
  /** Is Open (not yet shipped) */
  open: Scalars['Boolean'];
  /** Time indicating when this order was created */
  order_created_datetime: Scalars['Time'];
  order_lines: Array<GQLOrderLine>;
  /** Type of order, for example CALL_OFF */
  order_type: GQLOrderType;
  /** Organization this order belongs to */
  organization: GQLOrganization;
  /** In case the order was created based on Dibbler quotation, this is the ID from Dibbler */
  quotation_id?: Maybe<Scalars['ID']>;
  /** Order id (as shown to the customer on printed documents; unique only within an organization!) */
  readable_order_id?: Maybe<Scalars['String']>;
  /** Order reference */
  reference: Scalars['String'];
  /** Human readable, localized status of the order. */
  status: Scalars['String'];
  /** Status Id of the order. */
  status_id: Scalars['String'];
  /** Total net amount of the order */
  total_net_price: GQLMonetaryValue;
  /** Total number of order lines */
  total_number_order_lines: Scalars['Int'];
};

export enum GQLOrderConsolidationMode {
  /** Quotation will be consolidated with all orders in the daily buffer. The supplied quotation effective price will be added to the buffered order matching the same delivery address and delivery option supplied in the request. */
  AllBufferedOrders = 'ALL_BUFFERED_ORDERS',
  /** Quotation will be consolidated with orders in daily buffer with the same delivery address and delivery option as in the request */
  BufferedOrdersMatchingReqAddressAndDo = 'BUFFERED_ORDERS_MATCHING_REQ_ADDRESS_AND_DO',
  /** Quotation will not be consolidated with any orders in the daily buffer */
  None = 'NONE'
}

export enum GQLOrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type GQLOrderLine = {
  __typename?: 'OrderLine';
  /** acknowledged delivery date */
  acknowledged_delivery_date?: Maybe<Scalars['Date']>;
  /** Order line comment */
  comment?: Maybe<Scalars['String']>;
  /** Order line id */
  id: Scalars['ID'];
  /** Can the order line be cancelled */
  is_cancellable: Scalars['Boolean'];
  /** Item */
  item?: Maybe<GQLItem>;
  /** Item id */
  item_id: Scalars['String'];
  /** Item name */
  item_name: Scalars['String'];
  /** Item sales unit */
  item_sales_unit: Scalars['String'];
  /** Last modification (e.g. status change) time */
  last_updated_datetime: Scalars['Time'];
  /** Is Open */
  open: Scalars['Boolean'];
  /** Order */
  order: GQLOrder;
  /** Type of order, for example CALL_OFF */
  order_type: GQLOrderType;
  /** quantity */
  quantity: Scalars['Float'];
  /** OrderLine sequence number, unique within the same order */
  sequence: Scalars['Int'];
  /**
   * shipped quantity; will move to Herald in the future
   * @deprecated In the backend, will be moved to herald
   */
  shipped_quantity: Scalars['Float'];
  /** Status of the order line */
  status: Scalars['String'];
  /** Status Id of the order line */
  status_id: Scalars['String'];
  /** total net price */
  total_net_price: GQLMonetaryValue;
  /** total gross price per unit */
  unit_gross_price: GQLMonetaryValue;
  /** Unit of measurement */
  unit_of_measurement?: Maybe<GQLUnitOfMeasurement>;
};

export type GQLOrderLineTax = {
  __typename?: 'OrderLineTax';
  /**
   * Collection of Feature Based Taxes for the given order line. Taxes that are applicable because of a certain feature of the
   * offer (volume, weight, capacity, material etc.) in the order line. For example, Oil Tax.
   */
  feature_based_tax_lines?: Maybe<Array<GQLFeatureBasedTax>>;
  /** Offer ID which is equivalent to item ID */
  offer_id: Scalars['String'];
  /** Order line ID */
  order_line_id: Scalars['String'];
  /** Quantity */
  quantity: Scalars['Float'];
  /** Total tax amount that is a sum of totals of value based and feature based tax calculations on the given order line */
  total_tax_amount: GQLMonetaryValue;
  /** Collection of Value Based Taxes for the given order line. Taxes that are applied on the monetary value of the order line, VAT for example. */
  value_based_tax_lines?: Maybe<Array<GQLValueBasedTax>>;
};

export type GQLOrderLinesQuery = {
  /** From date to filter */
  acknowledged_delivery_date_from?: InputMaybe<Scalars['Time']>;
  /** To date to filter */
  acknowledged_delivery_date_to?: InputMaybe<Scalars['Time']>;
  /** Is Open, not Open or both */
  is_open?: InputMaybe<GQLOrderLinesQueryIsOpen>;
  /** Item id */
  item_id?: InputMaybe<Scalars['String']>;
  /** Item name */
  item_name?: InputMaybe<Scalars['String']>;
  /** From order date to filter */
  order_created_datetime_from?: InputMaybe<Scalars['Time']>;
  /** To order date to filter */
  order_created_datetime_to?: InputMaybe<Scalars['Time']>;
  /** Comment on order line level */
  order_line_comment?: InputMaybe<Scalars['String']>;
  /** Name of the order */
  order_name?: InputMaybe<Scalars['String']>;
  /** Order reference */
  order_reference?: InputMaybe<Scalars['String']>;
  /** Type of order, for example CALL_OFF */
  order_type?: InputMaybe<GQLOrderType>;
  /** Organization to match */
  organization_id?: InputMaybe<Scalars['String']>;
  /** Organization relation to search (own/children/all) related to organization_id */
  organization_rel_type?: InputMaybe<GQLOrderLinesQueryOrganizationRelationType>;
  /** Order ids */
  readable_order_ids?: InputMaybe<Array<Scalars['String']>>;
  /** OrderLine status, for example In_progress */
  status?: InputMaybe<Array<Scalars['String']>>;
};

export enum GQLOrderLinesQueryIsOpen {
  IsOpenAny = 'IsOpen_ANY',
  IsOpenNotOpen = 'IsOpen_NOT_OPEN',
  IsOpenOpen = 'IsOpen_OPEN'
}

export enum GQLOrderLinesQueryOrganizationRelationType {
  All = 'ALL',
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  Own = 'OWN'
}

export enum GQLOrderLinesSearchSortByField {
  AcknowledgedDeliveryDate = 'ACKNOWLEDGED_DELIVERY_DATE',
  IsOpen = 'IS_OPEN',
  ItemId = 'ITEM_ID',
  ItemName = 'ITEM_NAME',
  OrderCreateDatetime = 'ORDER_CREATE_DATETIME',
  OrderId = 'ORDER_ID',
  OrderLineSequence = 'ORDER_LINE_SEQUENCE',
  OrderLineStatus = 'ORDER_LINE_STATUS',
  OrganizationId = 'ORGANIZATION_ID',
  Quantity = 'QUANTITY'
}

export type GQLOrderLinesSortBy = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field: GQLOrderLinesSearchSortByField;
};

export type GQLOrderLinesTaxInput = {
  /** Jurisdiction Id */
  jurisdiction_id?: InputMaybe<Scalars['String']>;
  /** Total net price for the given offer and quantity. If this is not provided in the request, Value Based taxes will not be calculated for the offers. */
  net_price_total: Scalars['Float'];
  /** external identifier of the offer */
  offer_id: Scalars['String'];
  /** Line identifier to handle correctly not consolidated requests. Optional to make it usable in cases where we dont have line id */
  order_line_id?: InputMaybe<Scalars['String']>;
  /** Quantity */
  quantity?: InputMaybe<Scalars['Float']>;
};

export type GQLOrderSortBy = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field: GQLOrdersSearchSortByField;
};

export enum GQLOrderType {
  CallOff = 'CALL_OFF',
  Standard = 'STANDARD'
}

export type GQLOrderedItemsQuery = {
  /** Item ids */
  item_ids: Array<Scalars['String']>;
  /** From order date to filter */
  order_created_datetime_from?: InputMaybe<Scalars['Time']>;
  /** To order date to filter */
  order_created_datetime_to?: InputMaybe<Scalars['Time']>;
  /** Organization to match */
  organization_id?: InputMaybe<Scalars['String']>;
  /** Organization relation to search (own/children/all) related to organization_id */
  organization_rel_type?: InputMaybe<GQLOrderLinesQueryOrganizationRelationType>;
};

export type GQLOrdersQuery = {
  /** From date to filter */
  created_datetime_from?: InputMaybe<Scalars['Time']>;
  /** To date to filter */
  created_datetime_to?: InputMaybe<Scalars['Time']>;
  /** Is Open, not Open or both */
  is_open?: InputMaybe<GQLOrdersQueryIsOpen>;
  /** Item id */
  item_id?: InputMaybe<Scalars['String']>;
  /** Item name */
  item_name?: InputMaybe<Scalars['String']>;
  /** Name of the order */
  name?: InputMaybe<Scalars['String']>;
  /** Type of order, for example CALL_OFF */
  order_type?: InputMaybe<GQLOrderType>;
  /** Organization to match */
  organization_id?: InputMaybe<Scalars['String']>;
  /** Organization relation to search (own/children/all) related to organization_id */
  organization_rel_type?: InputMaybe<GQLOrdersQueryOrganizationRelationType>;
  /** Order ids */
  readable_order_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Order reference */
  reference?: InputMaybe<Scalars['String']>;
  /** Order status, for example Delivered */
  status?: InputMaybe<Array<Scalars['String']>>;
};

export enum GQLOrdersQueryIsOpen {
  All = 'ALL',
  NotOpen = 'NOT_OPEN',
  Open = 'OPEN'
}

export enum GQLOrdersQueryOrganizationRelationType {
  All = 'ALL',
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  Own = 'OWN'
}

export enum GQLOrdersSearchSortByField {
  OrderDate = 'ORDER_DATE',
  OrderId = 'ORDER_ID',
  OrderReference = 'ORDER_REFERENCE',
  OrderStatus = 'ORDER_STATUS',
  OrderTotalNetAmount = 'ORDER_TOTAL_NET_AMOUNT',
  OrganizationId = 'ORGANIZATION_ID',
  ReadableOrderId = 'READABLE_ORDER_ID'
}

export type GQLOrganization = {
  __typename?: 'Organization';
  accounts: Array<GQLAccount>;
  /** All addresses associated with the organization */
  addresses: Array<GQLAddress>;
  child_organizations: Array<GQLOrganization>;
  /** country code (ISO format, 2 positions) */
  country_code: Scalars['String'];
  /** The currency associated to the organization. Optional since some organizations may not have a pricelist relation. */
  currency?: Maybe<Scalars['String']>;
  /** fields added by the DC3 Experience Layer */
  extended: GQLExtendedOrganization;
  id: Scalars['ID'];
  name: Scalars['String'];
  parent_organizations: Array<GQLOrganization>;
  /** The pricelist id associated to the organization. Optional since some organizations may not have a pricelist relation. */
  pricelist?: Maybe<Scalars['ID']>;
  properties: Array<GQLMapValue>;
  settings: Array<GQLMapValue>;
};


export type GQLOrganizationParent_OrganizationsArgs = {
  depth: Scalars['Int'];
};

export type GQLOrganizationAccessInfo = {
  __typename?: 'OrganizationAccessInfo';
  corporate_identity: Array<GQLKeyValueEntry>;
  organization_id: Scalars['String'];
};

export type GQLOrganizationsInStaticTargetGroupInput = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  static_target_group_id: Scalars['ID'];
};

export type GQLOrganizationsInStaticTargetGroupResponse = {
  __typename?: 'OrganizationsInStaticTargetGroupResponse';
  organizations: Array<GQLOrganization>;
  static_target_group: GQLStaticTargetGroup;
  statistics: GQLStatistics;
};

export type GQLOverwriteOrganizationAccessInput = {
  corporate_identity: Array<GQLKeyValueInput>;
  organization_id: Scalars['String'];
};

export type GQLParameter = {
  __typename?: 'Parameter';
  name: Scalars['String'];
  spec_id: Scalars['String'];
  value: GQLParameterValue;
};

export type GQLParameterInput = {
  data_type: GQLConfigurationType;
  name: Scalars['String'];
  value: GQLParameterValueInput;
};

export type GQLParameterSpec = {
  __typename?: 'ParameterSpec';
  data_type: GQLParameterType;
  description: Scalars['String'];
  name: Scalars['String'];
  spec_id: Scalars['String'];
  value_spec: GQLParameterValueSpec;
};

export type GQLParameterSpecPayload = {
  data_type: GQLParameterType;
  description: Scalars['String'];
  name: Scalars['String'];
  value_spec: GQLParameterValueSpecPayload;
};

export enum GQLParameterType {
  Boolean = 'BOOLEAN',
  Configuration = 'CONFIGURATION',
  Discrete = 'DISCRETE',
  Double = 'DOUBLE',
  Enum = 'ENUM',
  Integer = 'INTEGER',
  String = 'STRING',
  Unknown = 'UNKNOWN'
}

export type GQLParameterValue = GQLBooleanValue | GQLFloatValue | GQLIntValue | GQLPhoenixConfiguration | GQLStringValue;

export type GQLParameterValueInput = {
  bool_value?: InputMaybe<GQLBooleanValueInput>;
  configuration_value?: InputMaybe<GQLPhoenixConfigurationInput>;
  double_value?: InputMaybe<GQLFloatValueInput>;
  int_value?: InputMaybe<GQLIntValueInput>;
  string_value?: InputMaybe<GQLStringValueInput>;
};

export type GQLParameterValueSpec = GQLBooleanValueSpec | GQLDiscreteValueSpec | GQLEnumValueSpec | GQLFloatValueSpec | GQLIntValueSpec | GQLPhoenixConfigurationValueSpec | GQLStringValueSpec;

/** TODO:We should add oneof directive here later */
export type GQLParameterValueSpecPayload = {
  boolean?: InputMaybe<GQLBooleanValueSpecInput>;
  configuration?: InputMaybe<GQLConfigurationValueSpecInput>;
  discrete?: InputMaybe<GQLDiscreteValueSpecInput>;
  double?: InputMaybe<GQLFloatValueSpecInput>;
  enum?: InputMaybe<GQLEnumValueSpecInput>;
  int?: InputMaybe<GQLIntValueSpecInput>;
  string?: InputMaybe<GQLStringValueSpecInput>;
};

export type GQLPatchOrganizationPropertiesInput = {
  id: Scalars['ID'];
  properties: Array<GQLMapValueInput>;
};

export type GQLPatchOrganizationSettingsInput = {
  id: Scalars['ID'];
  settings: Array<GQLMapValueInput>;
};

export type GQLPatchQuotationLineInput = {
  /** (Optional) Clear the existing comment. If TRUE, the `comment` will be ignored */
  clear_comment?: InputMaybe<Scalars['Boolean']>;
  /** (Optional) Clear the existing shipping date. If TRUE, the `requested_shipping_date` will be ignored */
  clear_requested_shipping_date?: InputMaybe<Scalars['Boolean']>;
  /** (Optional) Set the quotation line comment. If not provided, it will NOT unset the existing comment */
  comment?: InputMaybe<Scalars['String']>;
  /** (Optional) Set the manual discount. If not provided, it will NOT unset the existing manual discount */
  manual_discount?: InputMaybe<GQLSetQuotationLineManualDiscountInput>;
  /** (Optional) Set the quotation line quantity. Original value will be overwritten. */
  quantity?: InputMaybe<Scalars['Float']>;
  quotation_line_id: Scalars['ID'];
  /** (Optional) Set the shipping data. If not provided, it will NOT unset the existing shipping date */
  requested_shipping_date?: InputMaybe<Scalars['Time']>;
};

export type GQLPatchQuotationLinePropertiesInput = {
  /** Map of free text properties */
  properties: Array<GQLMapValueInput>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLPatchQuotationLineReferencesInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /**
   * Map of free text references from another system. Might be used in automated channels to relate
   *     the quotation line to something in the system it originated from.
   */
  references: Array<GQLMapValueInput>;
};

export type GQLPatchQuotationLinesInput = {
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  quotation_lines: Array<GQLPatchQuotationLineInput>;
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLPatchQuotationPropertiesInput = {
  /** Map of free text properties */
  properties: Array<GQLMapValueInput>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLPatchRoleTranslationsInput = {
  role_id: Scalars['Int'];
  translations: Array<GQLRoleTranslationInput>;
};

export type GQLPatchStyleInput = {
  customization_owner?: InputMaybe<Scalars['String']>;
  properties: Array<GQLMapValueInput>;
};

export type GQLPermissionDetails = {
  __typename?: 'PermissionDetails';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type GQLPermissionsInOrganization = {
  __typename?: 'PermissionsInOrganization';
  organization: GQLOrganization;
  permissions: Array<Scalars['String']>;
};

export type GQLPhoenixConfiguration = {
  __typename?: 'PhoenixConfiguration';
  configuration_id?: Maybe<Scalars['String']>;
  parameters: Array<GQLParameter>;
  spec_id: Scalars['String'];
  specification_key: GQLSpecificationKey;
};

export type GQLPhoenixConfigurationAndSpecification = {
  __typename?: 'PhoenixConfigurationAndSpecification';
  configuration: GQLPhoenixConfiguration;
  specification_id: Scalars['String'];
  specification_name: Scalars['String'];
  specification_version?: Maybe<Scalars['Int']>;
};

export type GQLPhoenixConfigurationInput = {
  parameters: Array<GQLParameterInput>;
  spec_id: Scalars['String'];
};

export type GQLPhoenixConfigurationSpec = {
  __typename?: 'PhoenixConfigurationSpec';
  default_configuration_id: Scalars['String'];
  description: Scalars['String'];
  last_update_timestamp: Scalars['Time'];
  name: Scalars['String'];
  owner: Scalars['String'];
  parameters: Array<GQLParameterSpec>;
  spec_id: Scalars['String'];
  version: Scalars['Int'];
};

export type GQLPhoenixConfigurationSpecNameVersionInput = {
  name: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};

export type GQLPhoenixConfigurationValueSpec = {
  __typename?: 'PhoenixConfigurationValueSpec';
  configuration_spec: GQLPhoenixConfigurationSpec;
};

export type GQLPriceInformation = {
  __typename?: 'PriceInformation';
  /** Discount code based on percentage ranges of discount */
  discount_code: Scalars['String'];
  /** Discounts (all discount types combined) */
  discounts: Array<GQLDiscount>;
  /** Total gross price */
  gross_price: GQLMonetaryValue;
  /** Gross promotion information applicable to the item */
  gross_price_promotions?: Maybe<GQLPromotions>;
  /** Item identifier */
  item_id: Scalars['ID'];
  /** Total net price */
  net_price?: Maybe<GQLMonetaryValue>;
  /** Net Promotion information applicable for the item */
  net_price_promotions?: Maybe<GQLPromotions>;
  /** Quantity to which the price information applies */
  quantity: Scalars['Float'];
  /** Gross price per 1 unit */
  unit_gross_price: GQLMonetaryValue;
  /** Net price per 1 unit */
  unit_net_price?: Maybe<GQLMonetaryValue>;
  /** Indicator for presence of volume discount */
  volume_discount_present: Scalars['Boolean'];
  /** Volume discount ranges */
  volume_discount_ranges: Array<GQLVolumeDiscountRange>;
};

export type GQLPriceInformationInput = {
  item_id: Scalars['ID'];
  quantity: Scalars['Float'];
};

export type GQLPriceOverviewItem = {
  /** Configuration id of the item if item is a configured item */
  configuration_id?: InputMaybe<Scalars['String']>;
  /** item id */
  item_id: Scalars['String'];
  /** Requested quantity of the item, defaults to 1 */
  requested_quantity?: InputMaybe<Scalars['Float']>;
};

export type GQLPricePromotionCampaignItem = {
  __typename?: 'PricePromotionCampaignItem';
  /** Discount type */
  discount_type: GQLDiscountType;
  /** Discount value */
  discount_value: Scalars['Float'];
  /** Gross price */
  gross_price: Scalars['Float'];
  /** Item details */
  item?: Maybe<GQLItem>;
  /** Item ID */
  item_id: Scalars['String'];
  /** Modified datetime */
  modified_datetime: Scalars['Time'];
};

export type GQLPricePromotionCampaignItemResponse = {
  __typename?: 'PricePromotionCampaignItemResponse';
  /** Items */
  items: Array<GQLPricePromotionCampaignItem>;
  /** Pagination */
  statistics: GQLStatistics;
};

export type GQLPricePromotionCampaignItemsInput = {
  /** Campaign ID */
  campaign_id: Scalars['ID'];
  /** Organization ID in order to get gross price for items */
  organization_id: Scalars['String'];
  /** Page number */
  page: Scalars['Int'];
  /** Page size */
  page_size: Scalars['Int'];
  /** Sort by fields */
  sort_by?: InputMaybe<Array<GQLPricePromotionItemSortBy>>;
};

export type GQLPricePromotionItemSortBy = {
  /** Sort order */
  descending?: InputMaybe<Scalars['Boolean']>;
  /** Field on which sorting should be done on price promotion items */
  field: GQLPricePromotionItemSortByField;
};

export enum GQLPricePromotionItemSortByField {
  /** Discount type */
  DiscountType = 'DISCOUNT_TYPE',
  /** Discount value */
  DiscountValue = 'DISCOUNT_VALUE',
  /** Item ID */
  ItemId = 'ITEM_ID',
  /** Modified datetime */
  ModifiedDatetime = 'MODIFIED_DATETIME'
}

export type GQLPriceSimulationRequest = {
  /** The discount percentage to request, in case that a discount is requested */
  discount_percentage?: InputMaybe<Scalars['Float']>;
  /** the effective price, in case the price calculation is requested */
  effective_price?: InputMaybe<Scalars['Float']>;
  /** The margin percentage, in case a margin calculation is requested */
  margin_percentage?: InputMaybe<Scalars['Float']>;
};

export type GQLPriorityFallbackDeliveryOptions = {
  __typename?: 'PriorityFallbackDeliveryOptions';
  country: Scalars['String'];
  delivery_options: Array<GQLFallbackDeliveryOption>;
};

export type GQLProcessLogDataModificationDetails = {
  __typename?: 'ProcessLogDataModificationDetails';
  /** The type of modification (e.g. VALIDATION_RESOLUTION, SIMPLE_FIELD_UPDATE) */
  modification_type: Scalars['String'];
  /** The name of the field that was modified (e.g. quotation reference, quotation discount, line discount, quantity) */
  modified_field_name: Scalars['String'];
  /** optional - The value of the field after the modification */
  new_value?: Maybe<Scalars['String']>;
  /** optional - The value of the field before the modification */
  old_value?: Maybe<Scalars['String']>;
  /** Any other information that you might want to add to a modified data entity */
  properties: Array<GQLMapValue>;
};

export type GQLProcessLogEntry = {
  __typename?: 'ProcessLogEntry';
  /** optional - the context of an actor, only present when the event was triggered externally */
  actor_context?: Maybe<GQLActorContext>;
  /** The ID of the entity linked to a Log Entry, in case of the MVP this could be a Quotation ID */
  entity_id: Scalars['String'];
  /** optional - The owner of the entity linked to a Log Entry, in case of the MVP this could be an org 'C1040_1234' for the type 'Quotation' */
  entity_owner?: Maybe<Scalars['String']>;
  /** The type of entity linked to a Log Entry, in case of the MVP this could be a 'Quotation' */
  entity_type: Scalars['String'];
  /** optional - The details of an event, this is linked to the event_type */
  event_details?: Maybe<GQLProcessLogEventDetails>;
  /** The name of the event that triggered this Log Entry */
  event_name: Scalars['String'];
  /** The type of event that triggered this Log Entry */
  event_type: GQLProcessLogEventType;
  /** Any other information that you might want to add to a log entity */
  properties: Array<GQLMapValue>;
  /** Timestamp for the creation of this Log Entry */
  timestamp: Scalars['Time'];
};

export type GQLProcessLogEventDetails = GQLProcessLogDataModificationDetails | GQLProcessLogTransitionDetails;

export enum GQLProcessLogEventType {
  DataModification = 'DATA_MODIFICATION',
  Transition = 'TRANSITION'
}

export type GQLProcessLogTransitionDetails = {
  __typename?: 'ProcessLogTransitionDetails';
  /** optional - The name of the state that the entity transitioned from, can be null in case of creation */
  from_state?: Maybe<Scalars['String']>;
  /** The name of the state that the entity transitioned to */
  to_state: Scalars['String'];
};

export type GQLPromotion = {
  __typename?: 'Promotion';
  /** The original price (net/gross) before applying the promotion */
  original_price: GQLMonetaryValue;
  /** The amount saved due to the promotion */
  saving: GQLMonetaryValue;
  /** The percentage saved due to the promotion */
  saving_percentage: Scalars['Float'];
};

export type GQLPromotions = {
  __typename?: 'Promotions';
  /** Gross price promotions */
  price_promotions?: Maybe<Array<GQLPromotion>>;
  /** Net price promotions */
  unit_price_promotions?: Maybe<Array<GQLPromotion>>;
};

export type GQLProperty = {
  __typename?: 'Property';
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum GQLProposalStatus {
  Accepted = 'ACCEPTED',
  Expired = 'EXPIRED',
  Open = 'OPEN',
  Rejected = 'REJECTED'
}

export type GQLPutAllCampaignPropertiesInput = {
  campaign_id: Scalars['ID'];
  properties: Array<GQLMapValueInput>;
  source: Scalars['String'];
};

export type GQLQuery = {
  __typename?: 'Query';
  GetAllChangelogsByApplication: Array<GQLChangelogItem>;
  GetAllFreightChargeRulesXPL: Array<GQLFreightChargeRuleXpl>;
  GetChangelogsByApplication: GQLChangelogItem;
  GetDeliveryXPL: GQLDeliveryResponse;
  GetPersonMock: Array<GQLMockPerson>;
  /** Account. If no "id" is provided, acccount is returned based on JWT or impersonation headers. */
  account?: Maybe<GQLAccount>;
  /** Fetches addresses based on a list of address identifiers */
  addresses: Array<GQLAddress>;
  campaign?: Maybe<GQLCampaign>;
  campaigns: Array<GQLCampaign>;
  /** query of campaigns by conditions and pagination */
  campaigns_v2: GQLCampaignV2;
  catalog: GQLCatalog;
  category: GQLCategory;
  /** Checks acceptance state of the latest (active) document(s) based on type(s) for the organization (based on input or ID from JWT) */
  check_is_latest_document_accepted?: Maybe<Array<GQLDocument>>;
  /** Get communication preferences by entity_id, event_type, language & channel */
  communication_preferences?: Maybe<Array<GQLCommunicationPreference>>;
  component?: Maybe<GQLComponent>;
  /** Returns conditions according to ids */
  conditions_by_ids?: Maybe<Array<GQLCondition>>;
  /** Returns phoenix configuration parameters by spec id */
  config_by_spec_id?: Maybe<GQLPhoenixConfiguration>;
  /** Returns the details of config spec by spec id */
  config_spec?: Maybe<GQLPhoenixConfigurationSpec>;
  /** Returns the list of config specs related to given name and owner */
  config_specs?: Maybe<Array<GQLPhoenixConfigurationSpec>>;
  configurations_by_config_spec?: Maybe<Array<GQLPhoenixConfigurationAndSpecification>>;
  configurations_by_config_spec_ids?: Maybe<Array<GQLPhoenixConfiguration>>;
  /** Returns phoenix Configurations given a list of ids */
  configurations_by_ids?: Maybe<Array<GQLPhoenixConfiguration>>;
  count_feature_toggle_values?: Maybe<GQLCountFeatureToggleValuesOutput>;
  count_feature_toggles?: Maybe<GQLCountFeatureTogglesOutput>;
  /**
   * Will try to get default quotation, if it doesn't exists tries to
   * determine and set the default quotation associated with the requester's identity.
   * If there is no quotation eligible, it will return empty response.
   */
  default_quotation?: Maybe<GQLQuotation>;
  delivery_addresses?: Maybe<GQLDeliveryAddresses>;
  delivery_options?: Maybe<Array<GQLItemDeliveryOptions>>;
  /** Management Commercial filtering: all conditions for delivery options, specific to a rule */
  delivery_options_conditions?: Maybe<Array<GQLRestrictionConditionOptions>>;
  /** Management: retrieve all delivery options and translations */
  delivery_options_descriptions?: Maybe<Array<GQLDeliveryOptionWithDescriptions>>;
  /** Management Commercial filtering: all restriction rules for delivery options, which restrict usage of delivery options based on organization properties */
  delivery_options_restriction_rules?: Maybe<Array<GQLRestrictionRule>>;
  /** Possible delivery opportunities */
  delivery_plans: Array<GQLDeliveryPlan>;
  delivery_summary: GQLDeliverySummaryResult;
  /** All draft sales catalogs in a version */
  draft_sales_catalogs?: Maybe<Array<GQLDraftSalesCatalog>>;
  /** Returns all the requested draft sales categories based on the IDs provided */
  draft_sales_categories?: Maybe<Array<GQLDraftSalesCategory>>;
  /** Returns all the draft sales categories versions */
  draft_sales_categories_versions?: Maybe<Array<GQLDraftSalesCategoriesVersion>>;
  /** Returns a sorted list */
  draft_sales_category_hierarchy?: Maybe<Array<GQLDraftSalesCategoryHierarchyElement>>;
  /** Return the allowed/blocked assortements based on JWT or impersonation headers. */
  entitled_assortments: GQLEntitledAssortments;
  exportResults: Array<Array<GQLKv>>;
  /** Get the external submission url for the callers or given organization */
  external_submission_URL?: Maybe<Scalars['String']>;
  feature_toggle?: Maybe<GQLFeatureToggle>;
  feature_toggles?: Maybe<Array<GQLFeature>>;
  filter_shipment_units?: Maybe<Array<GQLShipmentUnit>>;
  filter_shipment_units_paginated: GQLShipmentUnitsResult;
  /** finding items for a specific catalog */
  find_items: GQLFindItemsResult;
  find_jurisdictions?: Maybe<Array<GQLJurisdiction>>;
  /** Find all search result suppressions that matches search terms OR item ids */
  find_search_result_suppressions: GQLFindSearchResultSuppressionsResponse;
  /** Generates a turnover report with the data for the previous 3 years of base year, and the cumulative YTD to the base month of the base year and the year before */
  generate_turnover_report?: Maybe<GQLGenerateTurnoverReportResponse>;
  getCampaignOverview: Array<GQLCampaignOverviewItem>;
  getExportedDataCampaign: GQLCampaignExportedDataItem;
  /** Queries for all models the item with given id fits into. Introduced wrapping of request params to align with Maykers requirements. Optional parameter search_query is provided from user input and if provided will be used to filter the results. Results are now aggregated by type */
  getReverseMakeModel: GQLGetReverseMakeModelResult;
  getWorkItemGroups: Array<GQLWorkItemGroup>;
  getWorkItemMetadata: Array<GQLWorkItemMetadata>;
  /** Return the account federations for the given user */
  get_account_federation: Array<GQLAccountFederation>;
  /** Gets the currenty active document based on type for the organization (based on input or ID from JWT) */
  get_active_document: GQLDocument;
  get_active_offers_for_items: Array<GQLOffersForItem>;
  /** Fetch all the freight charge rules */
  get_all_freight_charge_rules: Array<GQLFreightChargeRule>;
  /** Get the content for the current org in all the locales it's available in, used for administrator apps */
  get_all_own_localized_content?: Maybe<Array<GQLLocalizedContent>>;
  /** Returns identity tokens for anonymous guest users */
  get_anonymous_guest_identity: GQLGuestIdentity;
  get_assets?: Maybe<Array<GQLAsset>>;
  /** Returns per organization the roles you are allowed to assign. */
  get_assignable_roles: Array<GQLAssignableRole>;
  get_buyer_markets: Array<Scalars['String']>;
  /** Returns a list of colliding experiments */
  get_colliding_experiments: Array<GQLExperiment>;
  /** Get communication content by event_id and event_type */
  get_communication_content: GQLCommunicationContent;
  /** Get marketing content via Gutenberg */
  get_content: Scalars['String'];
  /** Fetch quotation freight charge and consolidate with previous orders */
  get_detailed_freight_charge: GQLDetailedFreightCharges;
  get_entity_feature_values?: Maybe<Array<GQLGrannyEntity>>;
  /** Returns experiment by spec id */
  get_experiment_by_id?: Maybe<GQLExperiment>;
  /** Fetch item surcharges for a specific delivery option and address combination. */
  get_item_surcharges: Array<GQLItemSurcharge>;
  get_jobs?: Maybe<Array<GQLJob>>;
  /** Get the localized content in the user's locale, used by consumers (webshop) */
  get_localized_content?: Maybe<Array<GQLLocalizedContent>>;
  /** Get master category details for one or more master category identifiers */
  get_master_categories: Array<GQLMasterCategory>;
  get_order_lines_tax?: Maybe<Array<GQLOrderLineTax>>;
  /** Returns current account role permissions mapping grouped by organizations */
  get_organization_role_name_permissions: Array<GQLRoleNamePermissionsMappingGroupedByOrganization>;
  /** Used for administrating the style */
  get_own_style?: Maybe<Array<GQLMapValue>>;
  /** Fetch quotation freight charge */
  get_quotation_freight_charge: GQLQuotationFreightCharge;
  /** Get quotations for the given organization. Includes information about default quotation. See: Quotation.IsDefault */
  get_quotations: Array<GQLQuotation>;
  get_shipment_unit?: Maybe<GQLShipmentUnit>;
  /** Used by the consumer of the style (webshop) */
  get_style?: Maybe<Array<GQLMapValue>>;
  /** Gets the terms and conditions for an organization with a locale */
  get_terms_and_conditions: Array<GQLTermsAndConditions>;
  /** Returns identity tokens for a unique guest user */
  get_unique_guest_identity: GQLGuestIdentity;
  get_value_added_tax_rates?: Maybe<Array<GQLJurisdictionValueAddedTaxRates>>;
  invoice?: Maybe<GQLInvoice>;
  invoice_file_url: Scalars['String'];
  invoices: Array<GQLInvoice>;
  item?: Maybe<GQLItem>;
  /** Retruns the mappings that exist between the item and draft sales categories */
  item_draft_sales_category_mappings: Array<GQLDraftSalesCategory>;
  /** Returns the price overview for the quotation */
  item_price_overview: GQLItemPriceOverview;
  items: Array<GQLItem>;
  items_by_ean: Array<GQLItem>;
  /** this works like the items(ids: _) query, but also returns item status - SUCCESS/NOT_FOUND/DELETED/ERROR */
  items_with_status?: Maybe<Array<GQLItemResult>>;
  /** Returns the list of configuration specifications according to the Filter */
  list_configuration_specifications_by_filter?: Maybe<GQLListConfigurationSpecificationByFilterResult>;
  /** List comments of an experiment */
  list_experiment_comments: GQLListExperimentCommentResponse;
  /** Returns the list of experiments */
  list_experiments: Array<Maybe<GQLExperiment>>;
  /** Returns the list of experiments by filter and pagination */
  list_experiments_by_filter: GQLListExperimentResult;
  list_feature_toggle_values?: Maybe<GQLListFeatureToggleValuesOutput>;
  list_feature_toggles?: Maybe<GQLListFeatureTogglesOutput>;
  /** Returns list of treatment assignments of an experiment by experiment id */
  list_treatment_assignments: GQLTreatmentAssignmentsWithTotal;
  makeModel?: Maybe<Array<GQLMakeModel>>;
  /** Queries for all makes or makes the type with given id fits into */
  makeModelMakes: GQLMakeModelMakesResult;
  /** Queries for all models the make or the type with given id fits into */
  makeModelModels: GQLMakeModelModelsResult;
  /** Queries for all types or type the make with given id fits into */
  makeModelTypes: GQLMakeModelTypesResult;
  /** Returns the mappings that exist between the master category and draft sales categories */
  master_category_draft_sales_category_mappings: Array<GQLDraftSalesCategory>;
  /** Returns an unsorted list */
  master_category_hierarchy?: Maybe<Array<GQLMasterCategoryHierarchyElement>>;
  /** Returns all the items in this category */
  master_category_items: GQLMasterCategoryItems;
  /** Return a metric based on it's id */
  metric: GQLMetricResult;
  /** Returns the list of metrics from catalog */
  metrics: GQLMetricListResult;
  nextQuestion: GQLQuestion;
  offers: Array<GQLOffer>;
  /** order_created_datetime is an optional parameter to improve performance */
  order?: Maybe<GQLOrder>;
  /** order_created_datetime is an optional parameter to improve performance */
  orders: Array<GQLOrder>;
  /** Organization. If no "id" is provided, organization is returned based on JWT or impersonation headers. */
  organization?: Maybe<GQLOrganization>;
  /** Returns organization access info for given organization ID */
  organization_access_info: GQLOrganizationAccessInfo;
  organizations_in_static_target_group: GQLOrganizationsInStaticTargetGroupResponse;
  /** Get paged communication preferences by entity_id, event_type, language & channel */
  paged_communication_preferences?: Maybe<Array<GQLCommunicationPreference>>;
  /** Returns a list of permission details */
  permission_details: Array<GQLPermissionDetails>;
  /** Returns per organization your permissions */
  permissions: Array<GQLPermissionsInOrganization>;
  /** Price information. Is without context of a quotation. In the context of a quotation additional discounts could be applicable. */
  price_information: Array<Maybe<GQLPriceInformation>>;
  /** List of price promotion campaign items */
  price_promotion_campaign_items: GQLPricePromotionCampaignItemResponse;
  /** Get All Priority Fallback Delivery Options */
  priority_fallback_delivery_options: Array<GQLPriorityFallbackDeliveryOptions>;
  /** Fetch all process logs for the given input  */
  process_logs: Array<GQLProcessLogEntry>;
  /** Returns the AccountProfile based on JWT or impersonation heaaders. */
  profile: GQLAccountProfile;
  /** Query quotation proposals of an organization */
  query_quotation_proposals?: Maybe<GQLQueryQuotationProposalsResponse>;
  /** Query quotations for the given organization */
  query_quotations: Array<GQLQuotation>;
  /** Quotation */
  quotation?: Maybe<GQLQuotation>;
  quotation_delivery_options: GQLQuotationDeliveryOptions;
  /** Returns the price details for the quotation line being negotiated */
  quotation_line_price_details: GQLQuotationLinePriceDetails;
  /**
   * Price information in the context of a quotation
   * @deprecated use quotation_price_information_v2 query instead
   */
  quotation_price_information: GQLQuotationPriceInformation;
  /** Price information in the context of a quotation. Supports partial responses */
  quotation_price_information_v2: GQLQuotationPriceInformationV2;
  /** Returns the price overview for the quotation */
  quotation_price_overview: GQLQuotationPriceOverview;
  /**
   * Get the history of proposals on a quotation ID
   * @deprecated use query quotations_proposals instead
   */
  quotation_proposal: Array<GQLQuotationProposal>;
  /**
   * List of quotations associated with the requester's identity.
   * @deprecated use query_quotations query instead
   */
  quotations: Array<GQLQuotation>;
  /** Get the history of proposals on a list of quotation IDs */
  quotations_proposals: Array<GQLQuotationsProposals>;
  recommended_items?: Maybe<Array<Scalars['String']>>;
  /** order_created_datetime is an optional parameter to improve performance */
  return_order?: Maybe<GQLReturnOrder>;
  /** order_created_datetime is an optional parameter to improve performance */
  return_orders: Array<GQLReturnOrder>;
  /** Queries for all models the item with given id fits into. Optional parameter search_query is provided from user input and if provided will be used to filter the results */
  reverseMakeModel: GQLReverseMakeModelResult;
  /** Returns a list of roles */
  roles: Array<GQLAdminRole>;
  searchResultSuppressionsV2: GQLFindSearchResultSuppressionsResponseV2;
  search_accounts?: Maybe<Array<GQLAccount>>;
  search_draft_sales_categories: GQLSearchDraftSalesCategoriesResponse;
  /** Searches for invoices based on query and sort by values */
  search_invoices: GQLSearchInvoicesResponse;
  /** Get search catalog item results */
  search_items: GQLSearchItemsResult;
  search_order_lines: GQLSearchOrderLinesResult;
  /** Search if a customer has ordered a specific set of items according to the query constraints */
  search_ordered_items: Array<Scalars['String']>;
  search_orders: GQLSearchOrdersResult;
  /** Performs "starts with" search on public identifier and organization name */
  search_organization: Array<GQLOrganization>;
  /** List all search terms with suppressed items */
  search_result_suppressions: GQLSearchResultSuppressionsResponse;
  search_return_order_lines: GQLSearchReturnOrderLinesResult;
  search_return_orders: GQLSearchReturnOrdersResult;
  search_suggestions: GQLSearchSuggestionsResult;
  /** Get search catalog variant results */
  search_variants: GQLSearchVariantsResult;
  /**
   * Set the external submission url for the callers or given organization
   * @deprecated use the mutation
   */
  set_external_submission_URL: Scalars['String'];
  /** Returns shipment details by tracking ids */
  shipment_details_by_tracking_id?: Maybe<Array<GQLShipmentDetail>>;
  /** Simulates the quotation line discount with the information given. */
  simulate_quotation_line_discount?: Maybe<GQLNegotiatedDetails>;
  status_definitions: Array<GQLStatusDefinition>;
  /** List all synonyms for a given language */
  synonyms: GQLSynoynmsResponse;
  /** List all synonyms for a given language that include the given term */
  synonyms_by_term?: Maybe<Array<GQLSynonym>>;
  /** List all synonyms for a given language that includes several terms */
  synonyms_by_terms?: Maybe<Array<GQLSynonym>>;
  /** Fetch the tenant configuration as a JSON string. It's up to the consumer on how to handle the JSON in the experience layer or front-end. */
  tenant_config: Scalars['String'];
  /**
   * Unset/Remove the external submission url for the callers or given organization
   * @deprecated use the mutation
   */
  unset_external_submission_URL?: Maybe<Scalars['String']>;
  validate_address: GQLValidatedAddress;
  variant?: Maybe<GQLVariant>;
  wishlist?: Maybe<GQLWishlist>;
  wishlists: Array<GQLWishlist>;
};


export type GQLQueryGetAllChangelogsByApplicationArgs = {
  application: GQLApplication;
};


export type GQLQueryGetChangelogsByApplicationArgs = {
  application: GQLApplication;
};


export type GQLQueryGetDeliveryXplArgs = {
  query?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLQueryAccountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryAddressesArgs = {
  ids: Array<Scalars['ID']>;
};


export type GQLQueryCampaignArgs = {
  id: Scalars['ID'];
};


export type GQLQueryCampaignsArgs = {
  query: GQLCampaignsQueryInput;
};


export type GQLQueryCampaigns_V2Args = {
  query: GQLCampaignsQueryV2Input;
};


export type GQLQueryCatalogArgs = {
  catalog_id: Scalars['ID'];
};


export type GQLQueryCategoryArgs = {
  catalog_id: Scalars['ID'];
  id: Scalars['ID'];
};


export type GQLQueryCheck_Is_Latest_Document_AcceptedArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
  types?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLQueryCommunication_PreferencesArgs = {
  input?: InputMaybe<GQLGetCommunicationPreferencesInput>;
};


export type GQLQueryComponentArgs = {
  id: Scalars['ID'];
};


export type GQLQueryConditions_By_IdsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  serviceName: Scalars['String'];
};


export type GQLQueryConfig_By_Spec_IdArgs = {
  specId: Scalars['String'];
};


export type GQLQueryConfig_SpecArgs = {
  specId: Scalars['String'];
};


export type GQLQueryConfig_SpecsArgs = {
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
};


export type GQLQueryConfigurations_By_Config_SpecArgs = {
  names: Array<GQLPhoenixConfigurationSpecNameVersionInput>;
  owner: Scalars['String'];
};


export type GQLQueryConfigurations_By_Config_Spec_IdsArgs = {
  specIds: Array<Scalars['String']>;
};


export type GQLQueryConfigurations_By_IdsArgs = {
  ids: Array<Scalars['String']>;
};


export type GQLQueryCount_Feature_Toggle_ValuesArgs = {
  count_feature_toggle_values_input: GQLCountFeatureToggleValuesInput;
};


export type GQLQueryCount_Feature_TogglesArgs = {
  count_feature_toggles_input: GQLCountFeatureTogglesInput;
};


export type GQLQueryDelivery_OptionsArgs = {
  input: GQLDeliveryOptionsInput;
};


export type GQLQueryDelivery_Options_Restriction_RulesArgs = {
  delivery_option_filter: Array<Scalars['String']>;
};


export type GQLQueryDelivery_PlansArgs = {
  address?: InputMaybe<GQLDeliveryAddressInput>;
  input: Array<GQLDeliveryPlanInput>;
  preferredDeliveryOptionId?: InputMaybe<Scalars['String']>;
};


export type GQLQueryDelivery_SummaryArgs = {
  input: GQLDeliverySummaryInput;
};


export type GQLQueryDraft_Sales_CatalogsArgs = {
  version_id: Scalars['ID'];
};


export type GQLQueryDraft_Sales_CategoriesArgs = {
  draft_sales_category_ids: Array<Scalars['ID']>;
  version_id: Scalars['ID'];
};


export type GQLQueryDraft_Sales_Category_HierarchyArgs = {
  depth: Scalars['Int'];
  draft_catalog_id: Scalars['ID'];
  draft_sales_category_id?: InputMaybe<Scalars['ID']>;
  version_id: Scalars['ID'];
};


export type GQLQueryExportResultsArgs = {
  labelFilters: Array<GQLLabelFilter>;
  timeRange: GQLExportRange;
  workItemGroupId: Scalars['String'];
};


export type GQLQueryExternal_Submission_UrlArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryFeature_ToggleArgs = {
  feature_id: Scalars['String'];
};


export type GQLQueryFeature_TogglesArgs = {
  context_name?: InputMaybe<Scalars['String']>;
  feature_ids: Array<Scalars['ID']>;
};


export type GQLQueryFilter_Shipment_UnitsArgs = {
  date_from?: InputMaybe<Scalars['Date']>;
  date_to?: InputMaybe<Scalars['Date']>;
  item_id?: InputMaybe<Scalars['ID']>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  postal_codes?: InputMaybe<Array<Scalars['ID']>>;
  shipment_ids?: InputMaybe<Array<Scalars['ID']>>;
  sort: Array<GQLShipmentUnitSort>;
  tracking_ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type GQLQueryFilter_Shipment_Units_PaginatedArgs = {
  input: GQLShipmentUnitsInput;
};


export type GQLQueryFind_ItemsArgs = {
  catalog_id?: InputMaybe<Scalars['String']>;
  enable_spellcheck?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<GQLFilterInput>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  search_term: Scalars['String'];
  sortBy?: InputMaybe<Array<GQLValueSortByInput>>;
};


export type GQLQueryFind_JurisdictionsArgs = {
  input: Array<GQLJurisdictionInput>;
};


export type GQLQueryFind_Search_Result_SuppressionsArgs = {
  item_ids: Array<Scalars['String']>;
  search_terms: Array<Scalars['String']>;
};


export type GQLQueryGenerate_Turnover_ReportArgs = {
  input: GQLGenerateTurnoverReportInput;
};


export type GQLQueryGetCampaignOverviewArgs = {
  campaignType: Scalars['String'];
};


export type GQLQueryGetExportedDataCampaignArgs = {
  campaignId: Scalars['ID'];
};


export type GQLQueryGetReverseMakeModelArgs = {
  input: GQLGetReverseMakeModelInput;
};


export type GQLQueryGetWorkItemMetadataArgs = {
  workItemGroupId: Scalars['String'];
};


export type GQLQueryGet_Account_FederationArgs = {
  account_id: Scalars['ID'];
};


export type GQLQueryGet_Active_DocumentArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
  type: Scalars['String'];
};


export type GQLQueryGet_Active_Offers_For_ItemsArgs = {
  item_ids?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLQueryGet_All_Own_Localized_ContentArgs = {
  content_ids: Array<Scalars['ID']>;
  customization_owner?: InputMaybe<Scalars['String']>;
};


export type GQLQueryGet_AssetsArgs = {
  input: GQLGetAssetsInput;
};


export type GQLQueryGet_Buyer_MarketsArgs = {
  buyer_id: Scalars['String'];
};


export type GQLQueryGet_Colliding_ExperimentsArgs = {
  experiment: GQLGetCollidingExperimentsInput;
};


export type GQLQueryGet_Communication_ContentArgs = {
  input: GQLGetCommunicationContentInput;
};


export type GQLQueryGet_ContentArgs = {
  input: GQLGetContentInput;
};


export type GQLQueryGet_Detailed_Freight_ChargeArgs = {
  input: GQLGetDetailedFreightChargeInput;
};


export type GQLQueryGet_Entity_Feature_ValuesArgs = {
  input: GQLGetEntityFeatureValuesInput;
};


export type GQLQueryGet_Experiment_By_IdArgs = {
  experimentId: Scalars['String'];
};


export type GQLQueryGet_Item_SurchargesArgs = {
  input: GQLGetItemSurchargesInput;
};


export type GQLQueryGet_JobsArgs = {
  types?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLQueryGet_Localized_ContentArgs = {
  content_ids: Array<Scalars['ID']>;
  customization_owner?: InputMaybe<Scalars['String']>;
};


export type GQLQueryGet_Master_CategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type GQLQueryGet_Order_Lines_TaxArgs = {
  input: Array<GQLOrderLinesTaxInput>;
};


export type GQLQueryGet_Own_StyleArgs = {
  customization_owner?: InputMaybe<Scalars['String']>;
};


export type GQLQueryGet_Quotation_Freight_ChargeArgs = {
  input: Scalars['String'];
};


export type GQLQueryGet_QuotationsArgs = {
  quotation_statuses?: InputMaybe<Array<GQLQuotationStatus>>;
};


export type GQLQueryGet_Shipment_UnitArgs = {
  shipment_unit_id: Scalars['ID'];
};


export type GQLQueryGet_StyleArgs = {
  customization_owner?: InputMaybe<Scalars['String']>;
};


export type GQLQueryGet_Terms_And_ConditionsArgs = {
  locale?: InputMaybe<Scalars['String']>;
  organization_id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryGet_Value_Added_Tax_RatesArgs = {
  input: Array<GQLJurisdictionInput>;
};


export type GQLQueryInvoiceArgs = {
  invoice_id: Scalars['ID'];
};


export type GQLQueryInvoice_File_UrlArgs = {
  invoice_id: Scalars['ID'];
};


export type GQLQueryInvoicesArgs = {
  invoice_ids: Array<Scalars['ID']>;
};


export type GQLQueryItemArgs = {
  id: Scalars['ID'];
};


export type GQLQueryItem_Draft_Sales_Category_MappingsArgs = {
  item_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLQueryItem_Price_OverviewArgs = {
  input?: InputMaybe<GQLItemPriceOverviewInput>;
};


export type GQLQueryItemsArgs = {
  ids: Array<Scalars['ID']>;
};


export type GQLQueryItems_By_EanArgs = {
  eans: Array<Scalars['ID']>;
};


export type GQLQueryItems_With_StatusArgs = {
  ids: Array<Scalars['ID']>;
};


export type GQLQueryList_Configuration_Specifications_By_FilterArgs = {
  filter?: InputMaybe<GQLListConfigurationSpecFilterInput>;
};


export type GQLQueryList_Experiment_CommentsArgs = {
  experimentId: Scalars['String'];
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};


export type GQLQueryList_Experiments_By_FilterArgs = {
  filter?: InputMaybe<GQLListExperimentFilterInput>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  sort_by?: InputMaybe<GQLListExperimentSortByInput>;
};


export type GQLQueryList_Feature_Toggle_ValuesArgs = {
  list_feature_toggle_values_input: GQLListFeatureToggleValuesInput;
};


export type GQLQueryList_Feature_TogglesArgs = {
  list_feature_toggles_input: GQLListFeatureTogglesInput;
};


export type GQLQueryList_Treatment_AssignmentsArgs = {
  experimentId: Scalars['String'];
};


export type GQLQueryMakeModelArgs = {
  makeModelType: GQLMakeModelType;
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
};


export type GQLQueryMakeModelMakesArgs = {
  type_id: Scalars['String'];
};


export type GQLQueryMakeModelModelsArgs = {
  make_id: Scalars['String'];
  type_id: Scalars['String'];
};


export type GQLQueryMakeModelTypesArgs = {
  make_id: Scalars['String'];
};


export type GQLQueryMaster_Category_Draft_Sales_Category_MappingsArgs = {
  master_category_id: Scalars['ID'];
  version_id: Scalars['ID'];
};


export type GQLQueryMaster_Category_HierarchyArgs = {
  depth: Scalars['Int'];
  master_category_id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryMaster_Category_ItemsArgs = {
  include_mapped_items?: InputMaybe<Scalars['Boolean']>;
  master_category_id: Scalars['String'];
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};


export type GQLQueryMetricArgs = {
  id: Scalars['String'];
};


export type GQLQueryMetricsArgs = {
  filter?: InputMaybe<GQLMetricRequestFilter>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};


export type GQLQueryNextQuestionArgs = {
  labelFilters: Array<GQLLabelFilter>;
  workItemGroupId: Scalars['String'];
};


export type GQLQueryOffersArgs = {
  offer_ids?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLQueryOrderArgs = {
  id: Scalars['ID'];
  order_created_datetime?: InputMaybe<Scalars['Time']>;
};


export type GQLQueryOrdersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  order_created_datetime?: InputMaybe<Array<Scalars['Time']>>;
};


export type GQLQueryOrganizationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryOrganization_Access_InfoArgs = {
  organization_id: Scalars['ID'];
};


export type GQLQueryOrganizations_In_Static_Target_GroupArgs = {
  input: GQLOrganizationsInStaticTargetGroupInput;
};


export type GQLQueryPaged_Communication_PreferencesArgs = {
  input?: InputMaybe<GQLGetPagedCommunicationPreferenceInput>;
};


export type GQLQueryPrice_InformationArgs = {
  input: Array<GQLPriceInformationInput>;
};


export type GQLQueryPrice_Promotion_Campaign_ItemsArgs = {
  input: GQLPricePromotionCampaignItemsInput;
};


export type GQLQueryProcess_LogsArgs = {
  entity_id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryQuery_Quotation_ProposalsArgs = {
  only_latest?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLQuotationProposalsQuery;
  sort_by?: InputMaybe<Array<GQLQuotationProposalSortBy>>;
};


export type GQLQueryQuery_QuotationsArgs = {
  order_by: GQLQuotationsOrderBy;
  order_direction: GQLOrderDirection;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLQuotationsQueryInput;
};


export type GQLQueryQuotationArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryQuotation_Delivery_OptionsArgs = {
  input: GQLQuotationDeliveryOptionsInput;
};


export type GQLQueryQuotation_Line_Price_DetailsArgs = {
  input?: InputMaybe<GQLQuotationLinePriceDetailsInput>;
};


export type GQLQueryQuotation_Price_InformationArgs = {
  input: GQLQuotationPriceInformationInput;
};


export type GQLQueryQuotation_Price_Information_V2Args = {
  input: GQLQuotationPriceInformationInput;
};


export type GQLQueryQuotation_Price_OverviewArgs = {
  currency: Scalars['String'];
  organization_id: Scalars['String'];
  quotation_discount_percentage?: InputMaybe<Scalars['Float']>;
  quotation_id: Scalars['String'];
};


export type GQLQueryQuotation_ProposalArgs = {
  id: Scalars['ID'];
};


export type GQLQueryQuotationsArgs = {
  order_by: GQLQuotationsOrderBy;
  order_direction: GQLOrderDirection;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLQuotationsQueryInput;
};


export type GQLQueryQuotations_ProposalsArgs = {
  ids: Array<Scalars['ID']>;
};


export type GQLQueryReturn_OrderArgs = {
  id: Scalars['ID'];
  order_created_datetime?: InputMaybe<Scalars['Time']>;
};


export type GQLQueryReturn_OrdersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  order_created_datetime?: InputMaybe<Array<Scalars['Time']>>;
};


export type GQLQueryReverseMakeModelArgs = {
  item_id: Scalars['String'];
  search_query?: InputMaybe<Scalars['String']>;
};


export type GQLQuerySearchResultSuppressionsV2Args = {
  itemIds: Array<Scalars['String']>;
  searchTerms: Array<Scalars['String']>;
};


export type GQLQuerySearch_AccountsArgs = {
  input: GQLSearchAccountsInput;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type GQLQuerySearch_Draft_Sales_CategoriesArgs = {
  keyword: Scalars['String'];
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  version_id: Scalars['ID'];
};


export type GQLQuerySearch_InvoicesArgs = {
  invoices_query: GQLInvoicesSearchQuery;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  sort_by: Array<GQLInvoicesSearchQuerySortBy>;
};


export type GQLQuerySearch_ItemsArgs = {
  catalog_id?: InputMaybe<Scalars['String']>;
  enable_spellcheck?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<GQLFilterInput>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  search_term: Scalars['String'];
};


export type GQLQuerySearch_Order_LinesArgs = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLOrderLinesQuery;
  sort_by?: InputMaybe<Array<GQLOrderLinesSortBy>>;
};


export type GQLQuerySearch_Ordered_ItemsArgs = {
  query: GQLOrderedItemsQuery;
};


export type GQLQuerySearch_OrdersArgs = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLOrdersQuery;
  sort_by?: InputMaybe<Array<GQLOrderSortBy>>;
};


export type GQLQuerySearch_OrganizationArgs = {
  ancestor_organization_id?: InputMaybe<Scalars['ID']>;
  search_term: Scalars['String'];
};


export type GQLQuerySearch_Result_SuppressionsArgs = {
  count: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  sort_by?: InputMaybe<GQLSearchResultSuppressionSortBy>;
};


export type GQLQuerySearch_Return_Order_LinesArgs = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLReturnOrderLinesQuery;
  sort_by?: InputMaybe<Array<GQLReturnOrderLinesSortBy>>;
};


export type GQLQuerySearch_Return_OrdersArgs = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  query: GQLReturnOrdersQuery;
  sort_by?: InputMaybe<Array<GQLReturnOrderSortBy>>;
};


export type GQLQuerySearch_SuggestionsArgs = {
  category?: InputMaybe<GQLCategorySuggestionProperties>;
  item?: InputMaybe<GQLItemSuggestionProperties>;
  make_model?: InputMaybe<GQLMakeModelSuggestionProperties>;
  query?: InputMaybe<GQLQuerySuggestionProperties>;
  search_term: Scalars['String'];
};


export type GQLQuerySearch_VariantsArgs = {
  catalog_id?: InputMaybe<Scalars['String']>;
  enable_spellcheck?: InputMaybe<Scalars['Boolean']>;
  filters?: InputMaybe<GQLFilterInput>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  search_term: Scalars['String'];
};


export type GQLQuerySet_External_Submission_UrlArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
  url: Scalars['String'];
};


export type GQLQueryShipment_Details_By_Tracking_IdArgs = {
  tracking_ids: Array<Scalars['ID']>;
};


export type GQLQuerySimulate_Quotation_Line_DiscountArgs = {
  input: GQLSimulateQuotationLineDiscount;
};


export type GQLQuerySynonymsArgs = {
  count: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  sort_by?: InputMaybe<GQLSynonymsSortBy>;
};


export type GQLQuerySynonyms_By_TermArgs = {
  language: Scalars['String'];
  term: Scalars['String'];
};


export type GQLQuerySynonyms_By_TermsArgs = {
  language: Scalars['String'];
  terms?: InputMaybe<Array<Scalars['String']>>;
};


export type GQLQueryUnset_External_Submission_UrlArgs = {
  organization_id?: InputMaybe<Scalars['ID']>;
};


export type GQLQueryValidate_AddressArgs = {
  input: GQLValidateAddressInput;
};


export type GQLQueryVariantArgs = {
  id: Scalars['ID'];
};


export type GQLQueryWishlistArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export enum GQLQueryCampaignsField {
  EndDatetime = 'END_DATETIME',
  Name = 'NAME',
  StartDatetime = 'START_DATETIME',
  Status = 'STATUS',
  Type = 'TYPE'
}

export type GQLQueryCampaignsSortBy = {
  /** Sort order */
  descending?: InputMaybe<Scalars['Boolean']>;
  /** Field on which sorting should be done on campaigns */
  field: GQLQueryCampaignsField;
};

export type GQLQueryQuotationProposalsResponse = {
  __typename?: 'QueryQuotationProposalsResponse';
  /** Quotation proposals that are result of the query */
  quotation_proposals: Array<GQLQuotationProposal>;
  /** Statistics for pagination */
  statistics: GQLStatistics;
};

export type GQLQueryRequestInterpretation = {
  __typename?: 'QueryRequestInterpretation';
  /** The original query of this request that didn't yield any results. */
  original_query: Scalars['String'];
  /** The query that was used for this request */
  used_query: Scalars['String'];
};

export type GQLQuerySuggestionProperties = {
  maximum_suggestions: Scalars['Int'];
};

export type GQLQuestion = {
  __typename?: 'Question';
  answers: Array<GQLAnswer>;
  content: GQLQuestionContent;
  id: GQLWorkItemIdentifier;
  message: Scalars['String'];
};

export type GQLQuestionContent = {
  __typename?: 'QuestionContent';
  images: Array<GQLImage>;
  textBlocks: Array<GQLKv>;
};

export type GQLQuotation = {
  __typename?: 'Quotation';
  /**
   * Profile (belonging to e.g. a salesrep) to which the quotation is assigned. Assigned to is only an indicator,
   *     there are no enforced rules based on this field.
   */
  assigned_to_account?: Maybe<GQLAccount>;
  /** Billing address of quotation. */
  billing_address?: Maybe<GQLAddressValue>;
  /**
   * Profile (belonging to e.g. a salesrep) by which the quotation is claimed. A claimed quotation can only be
   *     modified by the claiming party. When this field is set, the quotation status should be Claimed.
   */
  claimed_by_account?: Maybe<GQLAccount>;
  /** Field with free text providing insights in the quotation from the perspective of the customer. */
  comment?: Maybe<Scalars['String']>;
  /** indicates if a all items should be present before shipping for this quotation. */
  consolidate_fulfillment: Scalars['Boolean'];
  /**
   * Account associated with the quotation, this is the owner if the quotation is private and the account responsible
   *     for the quotation if the quotation is shared.
   */
  contact_account?: Maybe<GQLAccount>;
  /** Timestamp indicating when this quotation was created */
  created_datetime: Scalars['Time'];
  /** Account of the creator of the quotation */
  creator_account?: Maybe<GQLAccount>;
  /** Account id of the creator of the quotation */
  creator_account_id?: Maybe<Scalars['String']>;
  /** Delivery address of quotation. */
  delivery_address?: Maybe<GQLAddressValue>;
  /** Timestamp indicating earliest expiration time of manual discount and/or fixed prices. */
  discount_expiration?: Maybe<Scalars['Time']>;
  /** fields added by the DC3 Experience Layer */
  extended: GQLExtendedQuotation;
  /** Fixed price information for the entire quotation */
  fixed_prices?: Maybe<GQLQuotationFixedPrices>;
  /** Optional field with free text describing instructions for fulfillment. */
  fulfillment_instructions?: Maybe<Scalars['String']>;
  /** Identifier for the quotation, UUID that is generated upon creation of the quotation. */
  id: Scalars['ID'];
  /**
   * When a quotation is created using impersonation it is the account of the impersonator who created it.
   *     otherwise, it will be empty
   */
  impersonation_account?: Maybe<GQLAccount>;
  /**
   * When a quotation is created using impersonation it is the account id of the impersonator who created it.
   * 	otherwise, it will be empty
   */
  impersonation_account_id?: Maybe<Scalars['String']>;
  /** indicates if a separate invoice is to be sent, specifically for this quotation. */
  invoiced_separately: Scalars['Boolean'];
  /** Indicates if this is the default quotation associated with this profile or not. Null is provided when unknown */
  isDefault?: Maybe<Scalars['Boolean']>;
  /** Timestamp indicating when this quotation or one of the associated quotation lines was last modified. */
  last_modified_datetime: Scalars['Time'];
  /** Returns all the legal documents that are attached to the Items that are included in this Quotation */
  legal_documents?: Maybe<Array<GQLDocument>>;
  /** Manual discount over the full quotation price. Can optionally be set by for example a sales representative using the SetManualDiscount operation. */
  manual_discount?: Maybe<GQLDiscount>;
  /** Name of the quotation, optional custom name that can be supplied by the channel when creating the quotation. */
  name: Scalars['String'];
  /** Organization the quotation belongs to */
  organization?: Maybe<GQLOrganization>;
  /** Map of free text properties. */
  properties?: Maybe<Array<GQLMapValue>>;
  /** Proposals that are associated with this quotation */
  proposals?: Maybe<Array<GQLQuotationProposal>>;
  quotation_lines: Array<GQLQuotationLine>;
  /**
   * A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of
   *     the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated.
   */
  quotation_state: Scalars['String'];
  /** The type of quotation, eg. Standard or Call-off */
  quotation_type?: Maybe<GQLQuotationType>;
  /**
   * Field with free text with a reference from another system, might be used in automated channels to relate
   *     the quotation to something in the system it originated from
   */
  reference?: Maybe<Scalars['String']>;
  /** Selected delivery option on quotation level */
  selected_delivery_option?: Maybe<Scalars['String']>;
  /** Set of the seller organizations to which the items in this quotation belong to */
  seller_organizations?: Maybe<Array<GQLOrganization>>;
  /**
   * Indicator if the quotation is private or shared,
   *     true: a shared quotation owned by an organization, contact_account_id will point to the responsible account,
   *     false: a private quotation owned by an account
   */
  shared: Scalars['Boolean'];
  /** Status of the quotation. */
  status: GQLQuotationStatus;
  /** The channel that quotation has been submitted through */
  submission_channel?: Maybe<Scalars['String']>;
  /** @deprecated use tax_breakdown instead */
  tax: GQLTax;
  /** This is just the same as tax field, but it can also be optional */
  tax_breakdown?: Maybe<GQLTax>;
  /** Total number of quotations lines associated with this quotation */
  total_number_quotation_lines: Scalars['Int'];
  /**
   * Validation errors applicable for the whole quotation (does not contain the quotation line validation errors)
   *     Query'ing the validation errors will not trigger the validation itself, use validate_quotation query instead.
   */
  validation_errors: Array<GQLValidationError>;
};

export type GQLQuotationDeliveryOptions = {
  __typename?: 'QuotationDeliveryOptions';
  delivery_options: Array<GQLDeliveryOption>;
  promoted_delivery_option?: Maybe<GQLDeliveryOption>;
};

export type GQLQuotationDeliveryOptionsInput = {
  /** Optional identifier of delivery address. If none, the delivery address of the quotation is used. */
  delivery_address_id?: InputMaybe<Scalars['String']>;
  /** Identifier of quotation. */
  quotation_id: Scalars['String'];
};

export type GQLQuotationEffectivePrice = {
  currency: Scalars['String'];
  value: Scalars['Float'];
};

export type GQLQuotationFixedPrices = {
  __typename?: 'QuotationFixedPrices';
  /** Average margin percentage of the quotation */
  average_margin_percentage?: Maybe<Scalars['Float']>;
  /** The total gross price for the entire quotation */
  total_gross_price: GQLMonetaryValue;
  /** The total net price for the entire quotation */
  total_net_price: GQLMonetaryValue;
  /** The total net price for the entire quotation with the quotation discount applied, if applicable */
  total_net_price_with_discount: GQLMonetaryValue;
};

export type GQLQuotationFreightCharge = {
  __typename?: 'QuotationFreightCharge';
  amount: GQLMonetaryValue;
};

export type GQLQuotationLine = {
  __typename?: 'QuotationLine';
  /**
   * If the item in this quotation line represent a configurable or pre configured set of items, this bill of
   *     materials is filled with the contents of the set.
   */
  bill_of_materials?: Maybe<GQLBillOfMaterials>;
  /** Comment, only relevant for the customer, could be concatenated by the backend in case of lines consolidation */
  comment?: Maybe<Scalars['String']>;
  /** Timestamp indicating when this quotation line was created */
  created_datetime: Scalars['Time'];
  /** Get fixed price information for the quotation line if set. */
  fixed_prices?: Maybe<GQLQuotationLineFixedPrices>;
  /** List of freight surcharges that apply to this item */
  freight_surcharges?: Maybe<Array<GQLQuotationLineFreightSurcharge>>;
  /** Free text describing instructions for fulfillment. */
  fulfillment_instructions?: Maybe<Scalars['String']>;
  /** identifier for the quotation line */
  id: Scalars['ID'];
  /** item associated to this quotation line */
  item?: Maybe<GQLItem>;
  /** Timestamp indicating when this quotation line was last modified. */
  last_modified_datetime: Scalars['Time'];
  /** Gross price for sales unit on quotation line level */
  line_unit_gross_price?: Maybe<GQLMonetaryValue>;
  /**
   *  Manual discount over the quotation line. Can optionally be set by for example a sales representative
   *    using the SetQuotationLineManualDiscount operation.
   */
  manual_discount?: Maybe<GQLDiscount>;
  /** name(alias) for this quotation line */
  name?: Maybe<Scalars['String']>;
  /** Map of free text properties. */
  properties?: Maybe<Array<GQLMapValue>>;
  /** Quantity, could be rounded by the backend */
  quantity: Scalars['Float'];
  /**
   * Map of free text references from another system. Might be used in automated channels to relate
   *     the quotation line to something in the system it originated from.
   */
  references?: Maybe<Array<GQLMapValue>>;
  /** Timestamp indicating then the item should be shipped. */
  requested_shipping_date?: Maybe<Scalars['Time']>;
  /** Unit gross promotion */
  unit_gross_promotion?: Maybe<GQLPromotion>;
  /**
   * Validation errors applicable to this quotation line
   *     Querying the validation errors will not trigger the validation itself, use validate_quotation query instead.
   */
  validation_errors: Array<GQLValidationError>;
};

export type GQLQuotationLineFixedPriceInformation = {
  /** Margin percentage of the quotation line */
  margin_percentage?: InputMaybe<Scalars['Float']>;
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** Gross price of the quotation line */
  total_gross_price: Scalars['Float'];
  /** Effective price (excl discounts) of the quotation line */
  total_net_price: Scalars['Float'];
  /** Unit gross price of the quotation line */
  unit_gross_price: Scalars['Float'];
};

export type GQLQuotationLineFixedPrices = {
  __typename?: 'QuotationLineFixedPrices';
  /** Margin percentage of the quotation line */
  margin_percentage?: Maybe<Scalars['Float']>;
  total_gross_price: GQLMonetaryValue;
  total_net_price: GQLMonetaryValue;
  unit_gross_price: GQLMonetaryValue;
  unit_net_price: GQLMonetaryValue;
};

export type GQLQuotationLineFreightSurcharge = {
  __typename?: 'QuotationLineFreightSurcharge';
  /** Description of the freight surcharge e.g. 'Surcharge applied due to the length of the product' */
  localized_description: Scalars['String'];
  /** Short name of the freight surcharge e.g. LENGTH */
  localized_name: Scalars['String'];
};

export type GQLQuotationLineManualDiscountInput = {
  /** manual discount to be applied on the full quotation net price as a percentage (e.g.: 1 means 1% additional discount) */
  percentage: Scalars['Float'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /** reason for the pricing adjustment */
  reason?: InputMaybe<Scalars['String']>;
  /** timestamp until when the manual discount pricing adjustment is valid */
  valid_until?: InputMaybe<Scalars['Time']>;
};

export type GQLQuotationLinePriceDetails = {
  __typename?: 'QuotationLinePriceDetails';
  /** The negotiated details of the quotation line */
  negotiated_details: GQLNegotiatedDetails;
  /** Properties in the quotation line */
  properties: Array<GQLProperty>;
  /** Indicator if the quantity is rounded or not */
  quantity_is_rounded: Scalars['Boolean'];
  /** The reference price information related to the quotation line */
  reference_price_info: GQLReferencePriceInfo;
  /** The rounded quantity of the item in the quotation line */
  rounded_quantity: Scalars['Float'];
};

export type GQLQuotationLinePriceDetailsInput = {
  /** The currency of the quotation */
  currency: Scalars['String'];
  /** The quotation line that is being negotiated */
  new_quotation_line: GQLNegotiatedQuotationLine;
  /** The organization for which the prices are being requested */
  organization_id: Scalars['String'];
  /** The quotation id where the quotation line is from */
  quotation_id: Scalars['String'];
};

export type GQLQuotationLinePriceInformation = {
  __typename?: 'QuotationLinePriceInformation';
  /** Discounts (all discount types combined) */
  discounts: Array<GQLDiscount>;
  /** Total effective price */
  effective_price: GQLMonetaryValue;
  /** Total gross price */
  gross_price: GQLMonetaryValue;
  /** Gross Promotions information applicable to this Item */
  gross_price_promotions?: Maybe<GQLPromotions>;
  /** Unique identifier of the quotation line */
  id: Scalars['ID'];
  /** Total net price */
  net_price: GQLMonetaryValue;
  /** Net Promotions information applicable to this Item */
  net_price_promotions?: Maybe<GQLPromotions>;
  /** Effective price per 1 unit */
  unit_effective_price: GQLMonetaryValue;
  /** Gross price per 1 unit */
  unit_gross_price: GQLMonetaryValue;
  /** Net price per 1 unit */
  unit_net_price: GQLMonetaryValue;
  /** Volume discount ranges */
  volume_discount_ranges: Array<GQLVolumeDiscountRange>;
};

export type GQLQuotationLinePriceInformationV2 = {
  __typename?: 'QuotationLinePriceInformationV2';
  /** Discounts (all discount types combined) */
  discounts?: Maybe<Array<GQLDiscount>>;
  /** Total effective price */
  effective_price?: Maybe<GQLMonetaryValue>;
  /** Total gross price */
  gross_price?: Maybe<GQLMonetaryValue>;
  /** Gross Promotions information applicable to this Item */
  gross_price_promotions?: Maybe<GQLPromotions>;
  /** Unique identifier of the quotation line */
  id: Scalars['ID'];
  /** Total net price */
  net_price?: Maybe<GQLMonetaryValue>;
  /** Net Promotions information applicable to this Item */
  net_price_promotions?: Maybe<GQLPromotions>;
  /** Effective price per 1 unit */
  unit_effective_price?: Maybe<GQLMonetaryValue>;
  /** Gross price per 1 unit */
  unit_gross_price?: Maybe<GQLMonetaryValue>;
  /** Net price per 1 unit */
  unit_net_price?: Maybe<GQLMonetaryValue>;
  /** Volume discount ranges */
  volume_discount_ranges?: Maybe<Array<GQLVolumeDiscountRange>>;
};

export type GQLQuotationLinePriceOverview = {
  __typename?: 'QuotationLinePriceOverview';
  /** Discount amount for the quotation line */
  discount_amount: Scalars['Float'];
  /** Percentage discount of the quotation line */
  discount_percentage: Scalars['Float'];
  /** Effective price of the quotation line */
  effective_price: Scalars['Float'];
  /** Gross price of the quotation line */
  gross_price: Scalars['Float'];
  /** Quotation line id */
  id: Scalars['ID'];
  /** Margin percentage of the quotation line */
  margin_percentage: Scalars['Float'];
  /** Indicator if the quantity is rounded */
  quantity_is_rounded: Scalars['Boolean'];
  /** Rounded quantity of the item in the quotation line */
  rounded_quantity: Scalars['Float'];
  /** Unit gross price of the quotation line, calculated based on gross_price and rounded_quantity fields */
  unit_gross_price: Scalars['Float'];
  /** Unit net price of the quotation line, calculated based on net_price and rounded_quantity fields */
  unit_net_price: Scalars['Float'];
};

export type GQLQuotationManualDiscountInput = {
  /** manual discount to be applied on the full quotation net price as a percentage (e.g.: 1 means 1% additional discount) */
  percentage: Scalars['Float'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /** reason for the pricing adjustment */
  reason?: InputMaybe<Scalars['String']>;
  /** timestamp until when the manual discount pricing adjustment is valid */
  valid_until?: InputMaybe<Scalars['Time']>;
};

export type GQLQuotationPriceInformation = {
  __typename?: 'QuotationPriceInformation';
  /** Quotation level discounts (provided by upstream) */
  discounts: Array<GQLDiscount>;
  /** Total effective price for the quotation. */
  effective_price: GQLMonetaryValue;
  /** Total gross price for the quotation */
  gross_price: GQLMonetaryValue;
  /** Unique identifier of the quotation */
  id: Scalars['ID'];
  /** Price information on quotation lines */
  quotation_lines_price_information: Array<GQLQuotationLinePriceInformation>;
};

export type GQLQuotationPriceInformationInput = {
  quotation_id: Scalars['ID'];
};

export type GQLQuotationPriceInformationV2 = {
  __typename?: 'QuotationPriceInformationV2';
  /** Quotation level discounts (provided by upstream) */
  discounts?: Maybe<Array<GQLDiscount>>;
  /** Total effective price for the quotation. */
  effective_price?: Maybe<GQLMonetaryValue>;
  /** Freight charge for a single delivery option and address combination. */
  freight_charge: GQLFreightChargesMap;
  /** All freight charge ranges for a single delivery option and address combination */
  freight_charge_ranges: Array<GQLAddressDeliveryOptionsFreightChargeRanges>;
  /** All freight charge ranges for a single delivery option and address combination, including the freight charge total. */
  freight_charge_ranges_with_total: GQLFreightChargeRangesWithTotal;
  /** Total gross price for the quotation */
  gross_price?: Maybe<GQLMonetaryValue>;
  /** Unique identifier of the quotation */
  id: Scalars['ID'];
  /** Price information on quotation lines */
  quotation_lines_price_information?: Maybe<Array<GQLQuotationLinePriceInformationV2>>;
};


export type GQLQuotationPriceInformationV2Freight_ChargeArgs = {
  input: GQLFreightChargeInput;
};


export type GQLQuotationPriceInformationV2Freight_Charge_RangesArgs = {
  input: GQLFreightChargeInputForRanges;
};


export type GQLQuotationPriceInformationV2Freight_Charge_Ranges_With_TotalArgs = {
  input: GQLFreightChargeInputForRanges;
};

export type GQLQuotationPriceOverview = {
  __typename?: 'QuotationPriceOverview';
  /** Average margin percentage for the quotation */
  average_margin_percentage: Scalars['Float'];
  /** Effective price for the lines */
  effective_lines_price: Scalars['Float'];
  /** Effective quotation price for the quotation */
  effective_quotation_price: Scalars['Float'];
  /** Gross price of the quotation */
  gross_price: Scalars['Float'];
  id: Scalars['String'];
  /** Discount amount in the quotation lines */
  lines_discount_amount: Scalars['Float'];
  /** Minimum net margin percentage for the quotation */
  minimum_net_margin_percentage: Scalars['Float'];
  /** Amount of the discount for the quotation */
  quotation_discount_amount: Scalars['Float'];
  /** Price overview for the quotation lines */
  quotation_lines: Array<GQLQuotationLinePriceOverview>;
};

export type GQLQuotationProposal = {
  __typename?: 'QuotationProposal';
  /**
   * identifier of the account that accepted or rejected a quotation proposal
   * @deprecated use decision_maker_account_id instead
   */
  account_id?: Maybe<Scalars['String']>;
  /** Average margin percentage for the quotation */
  average_margin_percentage?: Maybe<Scalars['Float']>;
  /** Timestamp indicating when this quotation proposal was created */
  created_datetime: Scalars['Time'];
  /** Account that has created a quotation proposal */
  creator_account?: Maybe<GQLAccount>;
  /** identifier of the account that has created a quotation proposal */
  creator_account_id?: Maybe<Scalars['String']>;
  /** Decision maker account that has accepted or rejected a quotation proposal */
  decision_maker_account?: Maybe<GQLAccount>;
  /** identifier of the account that accepted or rejected a quotation proposal */
  decision_maker_account_id?: Maybe<Scalars['String']>;
  /** URL to the proposal document PDF */
  document_url?: Maybe<Scalars['String']>;
  /** Effective price for the quotation proposal */
  effective_price?: Maybe<GQLMonetaryValue>;
  /** Effective price for the quotation proposal with discount applied, if applicable */
  effective_price_with_discount?: Maybe<GQLMonetaryValue>;
  /** Time indicating when the quotation proposal will be expired */
  expiry_datetime?: Maybe<Scalars['Time']>;
  /** Gross price of the quotation proposal */
  gross_price?: Maybe<GQLMonetaryValue>;
  /** Identifier of the Proposal */
  id: Scalars['ID'];
  /** Timestamp indicating when this quotation proposal was last modified. */
  last_modified_datetime: Scalars['Time'];
  /** Organization that the quotation proposal belongs to, originates from the quotation */
  organization?: Maybe<GQLOrganization>;
  /** ID of the Organization that the quotation proposal belongs to, originates from the quotation */
  organization_id?: Maybe<Scalars['String']>;
  /** Quotation that the quotation proposal belongs to */
  quotation: GQLQuotation;
  /** Contact account of the quotation that this quotation proposal belongs to, originates from the quotation */
  quotation_contact_account?: Maybe<GQLAccount>;
  /** The ID of the contact account of the quotation that this quotation proposal belongs to, originates from the quotation */
  quotation_contact_account_id?: Maybe<Scalars['String']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Total number of quotation lines in the quotation proposal */
  quotation_line_count?: Maybe<Scalars['Int']>;
  /** Name of the quotation that this quotation proposal belongs to, originates from the quotation */
  quotation_name?: Maybe<Scalars['String']>;
  /** Reference of the quotation that this quotation proposal belongs to, originates from the quotation */
  quotation_reference?: Maybe<Scalars['String']>;
  /** Reason given for the rejection of the quotation proposal */
  reason?: Maybe<Scalars['String']>;
  /** The status of the Proposal, possible values: OPEN, REJECTED, ACCEPTED */
  status: GQLProposalStatus;
  /** Version of the proposal, monotonically increasing number, 1.2.3,.. */
  version?: Maybe<Scalars['String']>;
};

export type GQLQuotationProposalSortBy = {
  /** Sort order */
  descending: Scalars['Boolean'];
  /** Field on which the sorting is done on quotation proposals */
  field: GQLQuotationProposalSortField;
};

export enum GQLQuotationProposalSortField {
  CreatedDatetime = 'CREATED_DATETIME',
  ExpiryDatetime = 'EXPIRY_DATETIME',
  LastModifiedDatetime = 'LAST_MODIFIED_DATETIME',
  QuotationId = 'QUOTATION_ID',
  TotalValue = 'TOTAL_VALUE',
  Version = 'VERSION'
}

export type GQLQuotationProposalsQuery = {
  /** User IDs (quotation assigned_to) to filter proposals */
  assigned_to_accounts?: InputMaybe<Array<Scalars['String']>>;
  /** Time to filter proposals that have been created after that */
  created_after?: InputMaybe<Scalars['Time']>;
  /** Time to filter proposals that have been created before that */
  created_before?: InputMaybe<Scalars['Time']>;
  /** User IDs (proposal assigned_to) to filter proposals */
  creator_accounts?: InputMaybe<Array<Scalars['String']>>;
  /** Organization countries to filter proposals. ISO 3166-1 alpha-2 country codes */
  organization_countries?: InputMaybe<Array<Scalars['String']>>;
  /** Organization IDs to filter proposals */
  organization_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Seller organization to filter proposals */
  seller_organizations?: InputMaybe<Array<Scalars['String']>>;
  /** Statuses to filter proposals */
  statuses?: InputMaybe<Array<GQLProposalStatus>>;
};

export enum GQLQuotationStatus {
  Cancelled = 'CANCELLED',
  Claimed = 'CLAIMED',
  Draft = 'DRAFT',
  Hold = 'HOLD',
  Open = 'OPEN',
  Pending = 'PENDING',
  Proposal = 'PROPOSAL',
  Submitted = 'SUBMITTED',
  SubmittedExternally = 'SUBMITTED_EXTERNALLY'
}

export type GQLQuotationToConsolidate = {
  /** Delivery address identifier */
  delivery_address_id?: InputMaybe<Scalars['String']>;
  /** Delivery option as known to Pythia */
  delivery_option_id?: InputMaybe<Scalars['String']>;
  /** List of item ids to determine surcharges for. Ids as known to Ciske. */
  item_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Id of the quotation */
  quotation_id: Scalars['String'];
};

export enum GQLQuotationType {
  CallOff = 'CALL_OFF',
  Standard = 'STANDARD'
}

export enum GQLQuotationValidationErrorCode {
  /**
   * Stock allocation error
   * @deprecated Use VALIDATION_ERROR_NO_ENOUGH_STOCK_AVAILABLE instead
   */
  ValidationErrorAllocationError = 'VALIDATION_ERROR_ALLOCATION_ERROR',
  /** Suspected duplicate quotation */
  ValidationErrorDuplicateQuotation = 'VALIDATION_ERROR_DUPLICATE_QUOTATION',
  /** Fulfilment instructions has been set */
  ValidationErrorFulfillmentInstructionsSet = 'VALIDATION_ERROR_FULFILLMENT_INSTRUCTIONS_SET',
  /** General error */
  ValidationErrorGeneralError = 'VALIDATION_ERROR_GENERAL_ERROR',
  /** Invalid address */
  ValidationErrorInvalidAddress = 'VALIDATION_ERROR_INVALID_ADDRESS',
  /** Invalid freight charge */
  ValidationErrorInvalidFreightCharge = 'VALIDATION_ERROR_INVALID_FREIGHT_CHARGE',
  /** Invalid customer */
  ValidationErrorInvalidOrganization = 'VALIDATION_ERROR_INVALID_ORGANIZATION',
  /** Invalid quantity */
  ValidationErrorInvalidQuantity = 'VALIDATION_ERROR_INVALID_QUANTITY',
  /** Item with advisory */
  ValidationErrorItemAdvisory = 'VALIDATION_ERROR_ITEM_ADVISORY',
  /** Non-buyable item */
  ValidationErrorItemBuyability = 'VALIDATION_ERROR_ITEM_BUYABILITY',
  /**
   * Unentitled item
   * @deprecated It is split to VALIDATION_ERROR_ITEM_VISIBILITY and VALIDATION_ERROR_ITEM_BUYABILITY
   */
  ValidationErrorItemEntitlement = 'VALIDATION_ERROR_ITEM_ENTITLEMENT',
  /** Invalid item */
  ValidationErrorItemProvided = 'VALIDATION_ERROR_ITEM_PROVIDED',
  /** Item replaced */
  ValidationErrorItemReplaced = 'VALIDATION_ERROR_ITEM_REPLACED',
  /** Invisible item */
  ValidationErrorItemVisibility = 'VALIDATION_ERROR_ITEM_VISIBILITY',
  /** Maximum quantity exceeded */
  ValidationErrorMaximumQtyExceeded = 'VALIDATION_ERROR_MAXIMUM_QTY_EXCEEDED',
  /** Maximum unverified quantity exceeded */
  ValidationErrorMaximumUnverifiedQtyExceeded = 'VALIDATION_ERROR_MAXIMUM_UNVERIFIED_QTY_EXCEEDED',
  /** Missing terms agreement */
  ValidationErrorMissingDocument = 'VALIDATION_ERROR_MISSING_DOCUMENT',
  /** Missing price data */
  ValidationErrorMissingPrice = 'VALIDATION_ERROR_MISSING_PRICE',
  /** Stock allocation error */
  ValidationErrorNotEnoughStockAvailable = 'VALIDATION_ERROR_NOT_ENOUGH_STOCK_AVAILABLE',
  /** Organization on sales stop */
  ValidationErrorOrganizationOnSalesStop = 'VALIDATION_ERROR_ORGANIZATION_ON_SALES_STOP',
  /** Missing Quotation name */
  ValidationErrorQuotationName = 'VALIDATION_ERROR_QUOTATION_NAME',
  /** UOM mismatch */
  ValidationErrorUomMismatch = 'VALIDATION_ERROR_UOM_MISMATCH'
}

export enum GQLQuotationsOrderBy {
  AssignedToAccount = 'ASSIGNED_TO_ACCOUNT',
  ClaimedByAccount = 'CLAIMED_BY_ACCOUNT',
  ContactAccount = 'CONTACT_ACCOUNT',
  FulfillmentInstructions = 'FULFILLMENT_INSTRUCTIONS',
  LastModified = 'LAST_MODIFIED',
  Name = 'NAME',
  None = 'NONE',
  Organization = 'ORGANIZATION',
  Reference = 'REFERENCE',
  Status = 'STATUS'
}

export type GQLQuotationsProposals = {
  __typename?: 'QuotationsProposals';
  /** Identifier of the Proposal History */
  id: Scalars['ID'];
  /** List of proposal history based on the quotation id */
  quotation_proposals: Array<GQLQuotationProposal>;
};

/**
 * Filters for query quotations request
 * Filters work according to the following rules to decide which quotations are returned in the response:
 * - All filtering is done based on an exact matching
 * - If no values are provided for a particular filter, no quotations are filtered based on the corresponding field
 * - If one or more values are provided for a particular filter, quotations where the corresponding field does not
 * match (one of the) value(s) are filtered out
 * - Filters can be stacked, i.e. all the filters that are non empty lists are applied to the list of quotations
 */
export type GQLQuotationsQueryInput = {
  assigned_to_accounts?: InputMaybe<Array<Scalars['String']>>;
  claimed_by_acocunts?: InputMaybe<Array<Scalars['String']>>;
  comments?: InputMaybe<Array<Scalars['String']>>;
  countries?: InputMaybe<Array<Scalars['String']>>;
  impersonation_accounts?: InputMaybe<Array<Scalars['String']>>;
  organizations?: InputMaybe<Array<Scalars['String']>>;
  quotation_id?: InputMaybe<Scalars['String']>;
  seller_organizations?: InputMaybe<Array<Scalars['String']>>;
  statuses?: InputMaybe<Array<GQLQuotationStatus>>;
  validation_error_codes?: InputMaybe<Array<GQLQuotationValidationErrorCode>>;
};

export type GQLRangeFacetValues = {
  __typename?: 'RangeFacetValues';
  max: Scalars['Float'];
  min: Scalars['Float'];
  unfiltered_max: Scalars['Float'];
  unfiltered_min: Scalars['Float'];
};

export type GQLRangeFilter = {
  __typename?: 'RangeFilter';
  /**
   * Filter the values from and including this value.
   * If this field is not set then the filter is applied without a minimum value.
   */
  from?: Maybe<Scalars['Float']>;
  /**
   * Filter the values up to and including this value.
   * If this field is not set then the filter is applied without a maximum value.
   */
  to?: Maybe<Scalars['Float']>;
};

export type GQLRangeFilterInput = {
  field: Scalars['String'];
  /**
   * Filter the values from and including this value.
   * If this field is not set then the filter is applied without a minimum value.
   */
  from?: InputMaybe<Scalars['Float']>;
  /**
   * Filter the values up to and including this value.
   * If this field is not set then the filter is applied without a maximum value.
   */
  to?: InputMaybe<Scalars['Float']>;
};

export type GQLReferencePriceInfo = {
  __typename?: 'ReferencePriceInfo';
  /** The discount percentage for the quotation line */
  discount_percentage: Scalars['Float'];
  /** The global cost for the business related to the item in the quotation line */
  global_cost_price: Scalars['Float'];
  /** The local cost for the business related to the item in the quotation line */
  local_cost_price: Scalars['Float'];
  /** The minimum net margin for the item in the quotation line */
  minimum_net_margin: Scalars['Float'];
  /** The minimum net price for the item in the quotation line */
  minimum_net_price: Scalars['Float'];
  /** The net price for the item in the quotation line */
  net_price: Scalars['Float'];
  /** The unit gross price for the item in the quotation line */
  unit_gross_price: Scalars['Float'];
};

export type GQLRefreshIdToken = {
  __typename?: 'RefreshIdToken';
  id_token: Scalars['String'];
  refresh_token: Scalars['String'];
};

export type GQLRefreshIdTokenInput = {
  refresh_token: Scalars['String'];
};

export type GQLRejectQuotationProposalInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /** Reason given for the rejection of the quotation proposal */
  reason?: InputMaybe<Scalars['String']>;
};

export type GQLReleaseQuotationInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLRemoveAddressTypesInput = {
  address_id: Scalars['ID'];
  address_types: Array<GQLAddressType>;
};

export type GQLRemoveOrganizationPropertiesInput = {
  id: Scalars['ID'];
  keys: Array<Scalars['String']>;
};

export type GQLRemoveOrganizationSettingsInput = {
  id: Scalars['ID'];
  keys: Array<Scalars['String']>;
};

export type GQLRemoveOrganizationsFromStaticTargetGroupInput = {
  organization_ids: Array<Scalars['ID']>;
  static_target_group_id: Scalars['ID'];
};

export type GQLRemoveRoleTranslationInput = {
  locale: Scalars['String'];
  role_id: Scalars['Int'];
};

export type GQLRequestInterpretations = {
  __typename?: 'RequestInterpretations';
  /**
   * An interpretation that has been applied to the request indicating that Sherlock has used a different search query
   * If this field is not present then the requested search query has been used.
   */
  query_request_interpretation?: Maybe<GQLQueryRequestInterpretation>;
};

export type GQLResetQuotationLineManualDiscountInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLResetQuotationManualDiscountInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLRestrictOrganizationAccessInput = {
  corporate_identity: Scalars['String'];
  country_list: Array<Scalars['String']>;
  organization_id: Scalars['String'];
};

export type GQLRestrictionConditionOptions = {
  __typename?: 'RestrictionConditionOptions';
  operators: Array<GQLConditionOperator>;
  property_id: Scalars['String'];
  property_name: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type GQLRestrictionRule = {
  __typename?: 'RestrictionRule';
  /** list of conditions */
  conditions: Array<GQLDeliveryOptionRestrictionConditionType>;
  /** list of delivery options which are affected */
  delivery_options: Array<GQLDeliveryOption>;
  /** description of the created rule */
  description: Scalars['String'];
  /** restriction rule id */
  id: Scalars['String'];
};

/** Delivery Restriction Rules Response */
export type GQLRestrictionRulesResponse = {
  __typename?: 'RestrictionRulesResponse';
  conditions?: Maybe<Array<GQLConditions>>;
  deliveryOptions?: Maybe<Array<GQLDeliveryOptionResponse>>;
  description: Scalars['String'];
  id: Scalars['String'];
};

export type GQLReturnOrder = {
  __typename?: 'ReturnOrder';
  /** Free text comment */
  comment?: Maybe<Scalars['String']>;
  /** Guid; globally unique */
  id: Scalars['ID'];
  /** Last modification (e.g. status change) time */
  last_updated_datetime: Scalars['Time'];
  /** Name of the order */
  name: Scalars['String'];
  /** Is Open (not yet shipped) */
  open: Scalars['Boolean'];
  /** Organization this order belongs to */
  organization: GQLOrganization;
  /** Order id (as shown to the customer on printed documents; unique only within an organization!) */
  readable_return_id?: Maybe<Scalars['String']>;
  /** Order reference */
  reference: Scalars['String'];
  /** Time indicating when this order was created */
  return_order_created_datetime: Scalars['Time'];
  return_order_lines: Array<GQLReturnOrderLine>;
  /** Human readable, localized status of the order. */
  status: Scalars['String'];
  /** Status Id of the order. */
  status_id: Scalars['String'];
  /** Total net amount of the order */
  total_net_price: GQLMonetaryValue;
};

export type GQLReturnOrderLine = {
  __typename?: 'ReturnOrderLine';
  /** acknowledged delivery date */
  acknowledged_delivery_date?: Maybe<Scalars['Date']>;
  /** Order line comment */
  comment?: Maybe<Scalars['String']>;
  /** Order line id */
  id: Scalars['ID'];
  /** Can the order line be cancelled */
  is_cancellable: Scalars['Boolean'];
  /** Item id */
  item_id: Scalars['String'];
  /** Item name */
  item_name: Scalars['String'];
  /** Item sales unit */
  item_sales_unit: Scalars['String'];
  /** Last modification (e.g. status change) time */
  last_updated_datetime: Scalars['Time'];
  /** Is Open */
  open: Scalars['Boolean'];
  /** Order */
  order: GQLReturnOrder;
  /** Original Order id */
  original_order_id?: Maybe<Scalars['String']>;
  /** quantity */
  quantity: Scalars['Float'];
  /** OrderLine sequence number, unique within the same order */
  sequence: Scalars['Int'];
  /**
   * shipped quantity; will move to Herald in the future
   * @deprecated In the backend, will be moved to herald
   */
  shipped_quantity: Scalars['Float'];
  /** Status of the order line */
  status: Scalars['String'];
  /** Status Id of the order line */
  status_id: Scalars['String'];
  /** total net price */
  total_net_price: GQLMonetaryValue;
  /** total gross price per unit */
  unit_gross_price: GQLMonetaryValue;
};

export type GQLReturnOrderLinesQuery = {
  /** From order date to filter */
  order_created_datetime_from?: InputMaybe<Scalars['Time']>;
  /** To order date to filter */
  order_created_datetime_to?: InputMaybe<Scalars['Time']>;
  /** Organization to match */
  organization_id?: InputMaybe<Scalars['String']>;
  /** Organization relation to search (own/children/all) related to organization_id */
  organization_rel_type?: InputMaybe<GQLReturnOrderLinesQueryOrganizationRelationType>;
  /** Original Order ids */
  original_order_ids?: InputMaybe<Array<Scalars['String']>>;
  /** Status */
  status?: InputMaybe<Array<Scalars['String']>>;
};

export enum GQLReturnOrderLinesQueryOrganizationRelationType {
  All = 'ALL',
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  Own = 'OWN'
}

export enum GQLReturnOrderLinesSearchSortByField {
  OrderCreateDatetime = 'ORDER_CREATE_DATETIME',
  OrderId = 'ORDER_ID'
}

export type GQLReturnOrderLinesSortBy = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field: GQLReturnOrderLinesSearchSortByField;
};

export type GQLReturnOrderSortBy = {
  descending?: InputMaybe<Scalars['Boolean']>;
  field: GQLReturnOrdersSearchSortByField;
};

export type GQLReturnOrdersQuery = {
  /** From date to filter */
  created_datetime_from?: InputMaybe<Scalars['Time']>;
  /** To date to filter */
  created_datetime_to?: InputMaybe<Scalars['Time']>;
  /** Organization to match */
  organization_id?: InputMaybe<Scalars['String']>;
  /** Organization relation to search (own/children/all) related to organization_id */
  organization_rel_type?: InputMaybe<GQLReturnOrdersQueryOrganizationRelationType>;
  /** Order reference */
  reference?: InputMaybe<Scalars['String']>;
};

export enum GQLReturnOrdersQueryOrganizationRelationType {
  All = 'ALL',
  Children = 'CHILDREN',
  Descendants = 'DESCENDANTS',
  Own = 'OWN'
}

export enum GQLReturnOrdersSearchSortByField {
  OrderDate = 'ORDER_DATE',
  OrderId = 'ORDER_ID'
}

export type GQLReverseMakeModelMake = {
  __typename?: 'ReverseMakeModelMake';
  id: Scalars['String'];
  models: Array<GQLMakeModelInfo>;
  name: Scalars['String'];
  series: Array<GQLReverseMakeModelSeries>;
};

export type GQLReverseMakeModelResult = {
  __typename?: 'ReverseMakeModelResult';
  makes: Array<GQLReverseMakeModelMake>;
};

export type GQLReverseMakeModelSeries = {
  __typename?: 'ReverseMakeModelSeries';
  id: Scalars['String'];
  models: Array<GQLMakeModelInfo>;
  name: Scalars['String'];
};

export type GQLReverseMakeModelType = {
  __typename?: 'ReverseMakeModelType';
  id: Scalars['String'];
  makes: Array<GQLReverseMakeModelMake>;
  name: Scalars['String'];
};

export type GQLRole = {
  __typename?: 'Role';
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  permissions?: Maybe<Array<Scalars['String']>>;
};

export type GQLRoleNamePermissionsMapping = {
  __typename?: 'RoleNamePermissionsMapping';
  permissions: Array<Scalars['String']>;
  role_name: Scalars['String'];
};

export type GQLRoleNamePermissionsMappingGroupedByOrganization = {
  __typename?: 'RoleNamePermissionsMappingGroupedByOrganization';
  organization_id: Scalars['String'];
  role_name_permissions_mapping: Array<GQLRoleNamePermissionsMapping>;
};

export type GQLRoleTranslation = {
  __typename?: 'RoleTranslation';
  description: Scalars['String'];
  locale: Scalars['String'];
  name: Scalars['String'];
};

export type GQLRoleTranslationInput = {
  description: Scalars['String'];
  locale: Scalars['String'];
  name: Scalars['String'];
};

export type GQLSearchAccountsInput = {
  account_id?: InputMaybe<Scalars['ID']>;
  ancestor_organization_id?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  logon_id?: InputMaybe<Scalars['ID']>;
  parent_organization_id?: InputMaybe<Scalars['ID']>;
};

export type GQLSearchDraftSalesCategoriesResponse = {
  __typename?: 'SearchDraftSalesCategoriesResponse';
  max_score: Scalars['Float'];
  results: Array<GQLDraftSalesCategorySearchResult>;
  total_results: Scalars['Int'];
};

export type GQLSearchForAllSynonym = {
  __typename?: 'SearchForAllSynonym';
  /** What account_id has initially created this synonym */
  created_by_account_id?: Maybe<GQLAccount>;
  /** When was this synonym exactly created */
  creation_timestamp: Scalars['Time'];
  /** Identifier for this synonym */
  id: Scalars['String'];
  /** Language code that this synonym is applicable to */
  language: Scalars['String'];
  /** When was this update of suppressed items for this search term */
  last_update_timestamp: Scalars['Time'];
  /** When account_id has last modified this synonym */
  last_updated_by_account_id?: Maybe<GQLAccount>;
  /** What problem is this synonym supposed to resolve */
  resolve_type?: Maybe<GQLSynonymResolveType>;
  /** Terms in this synonym, when the user enters any of these terms the search engine will search for all terms in this list */
  terms?: Maybe<Array<Scalars['String']>>;
};

export type GQLSearchInvoicesResponse = {
  __typename?: 'SearchInvoicesResponse';
  invoices: Array<GQLInvoice>;
  statistics: GQLStatistics;
};

export type GQLSearchItemsResult = {
  __typename?: 'SearchItemsResult';
  /** The filters that were used to produce these search results */
  active_filters: Array<GQLFilter>;
  facets: Array<GQLFacet>;
  items: Array<GQLItem>;
  /** Request interpretations are changes that Sherlock has applied to the request to (hopefully) improve relevance. */
  request_interpretations: GQLRequestInterpretations;
  /** Spellcheck suggestions for the query. */
  spellcheck_suggestions: GQLSpellcheckSuggestions;
  statistics: GQLStatistics;
};

export type GQLSearchOrderLinesResult = {
  __typename?: 'SearchOrderLinesResult';
  order_lines: Array<GQLOrderLine>;
  statistics: GQLStatistics;
};

export type GQLSearchOrdersResult = {
  __typename?: 'SearchOrdersResult';
  orders: Array<GQLOrder>;
  statistics: GQLStatistics;
};

export type GQLSearchResultSuppression = {
  __typename?: 'SearchResultSuppression';
  /** What account_id has initially suppressed an item for this search term */
  created_by_account_id?: Maybe<GQLAccount>;
  /** When was the first item suppressed for this search term */
  creation_timestamp: Scalars['Time'];
  /** When was this update of suppressed items for this search term */
  last_update_timestamp: Scalars['Time'];
  /** When account_id has last suppressed or restored and item for this search term */
  last_updated_by_account_id?: Maybe<GQLAccount>;
  /** Search term for which items are suppressed */
  search_term: Scalars['String'];
  /** Item ids that are suppressed for this search term */
  suppressed_item_ids?: Maybe<Array<Scalars['String']>>;
};

export type GQLSearchResultSuppressionSortBy = {
  descending: Scalars['Boolean'];
  field: GQLSearchResultSuppressionSortBySortByField;
};

export enum GQLSearchResultSuppressionSortBySortByField {
  CreationTimestamp = 'CREATION_TIMESTAMP',
  LastUpdateTimestamp = 'LAST_UPDATE_TIMESTAMP',
  SearchTerm = 'SEARCH_TERM',
  SuppressedItemIds = 'SUPPRESSED_ITEM_IDS'
}

export type GQLSearchResultSuppressionV2 = {
  __typename?: 'SearchResultSuppressionV2';
  createdByAccountId?: Maybe<GQLAccountV2>;
  creationTimestamp: Scalars['String'];
  lastUpdateTimestamp: Scalars['String'];
  lastUpdatedByAccountId?: Maybe<GQLAccountV2>;
  searchTerm: Scalars['String'];
  suppressedItemIds: Array<Scalars['String']>;
};

export type GQLSearchResultSuppressionsResponse = {
  __typename?: 'SearchResultSuppressionsResponse';
  /** Cursor that can be passed in a subsequent search_result_suppressions query to fetch the next search result suppressions */
  cursor?: Maybe<Scalars['String']>;
  search_result_suppressions?: Maybe<Array<GQLSearchResultSuppression>>;
};

export type GQLSearchReturnOrderLinesResult = {
  __typename?: 'SearchReturnOrderLinesResult';
  order_lines: Array<GQLReturnOrderLine>;
  statistics: GQLStatistics;
};

export type GQLSearchReturnOrdersResult = {
  __typename?: 'SearchReturnOrdersResult';
  orders: Array<GQLReturnOrder>;
  statistics: GQLStatistics;
};

export type GQLSearchSuggestionsResult = {
  __typename?: 'SearchSuggestionsResult';
  category_suggestions: Array<GQLCategorySuggestion>;
  item_suggestions: Array<GQLItemSuggestion>;
  make_model_suggestions: Array<GQLMakeModelSuggestion>;
  query_suggestions: Array<Scalars['String']>;
};

export enum GQLSearchType {
  Contains = 'CONTAINS',
  Equals = 'EQUALS',
  IsAnyOf = 'IS_ANY_OF',
  IsEmpty = 'IS_EMPTY',
  IsNotEmpty = 'IS_NOT_EMPTY',
  NoSearchTypeSpecified = 'NO_SEARCH_TYPE_SPECIFIED'
}

export type GQLSearchVariantsResult = {
  __typename?: 'SearchVariantsResult';
  /** The filters that were used to produce these search results */
  active_filters: Array<GQLFilter>;
  facets: Array<GQLFacet>;
  /** Request interpretations are changes that Sherlock has applied to the request to (hopefully) improve relevance. */
  request_interpretations: GQLRequestInterpretations;
  /** Spellcheck suggestions for the query. */
  spellcheck_suggestions: GQLSpellcheckSuggestions;
  statistics: GQLStatistics;
  variant_search_results: Array<GQLVariantSearchResult>;
};

export type GQLSendQuotationProposalInput = {
  /** Time indicating when the quotation proposal will be expired */
  expiry_datetime?: InputMaybe<Scalars['Time']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetAddressGeoLocationInput = {
  address_id: Scalars['ID'];
  geo_location: GQLGeoLocationInput;
};

export type GQLSetCampaigStartDatetimeInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
  start_datetime?: InputMaybe<Scalars['Time']>;
};

export type GQLSetCampaignActiveInput = {
  campaign_id: Scalars['ID'];
  source: Scalars['String'];
  status: GQLCampaignStatus;
};

export type GQLSetCampaignEndDatetimeInput = {
  campaign_id: Scalars['ID'];
  end_datetime?: InputMaybe<Scalars['Time']>;
  source: Scalars['String'];
};

export type GQLSetCampaignNameInput = {
  campaign_id: Scalars['ID'];
  name: Scalars['String'];
  source: Scalars['String'];
};

export type GQLSetDefaultQuotationInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
};

export type GQLSetDefaultWishlistInput = {
  wishlist_id: Scalars['ID'];
};

export type GQLSetLocalizedContentInput = {
  content: Scalars['String'];
  customization_owner?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationBillingAddressInput = {
  /** Catlyn address identifier, optional; won't be there in case of an address unknown to Catlyn */
  address_id?: InputMaybe<Scalars['ID']>;
  /** textual field with the address information */
  address_text: Scalars['String'];
  /** city this address is in */
  city: Scalars['String'];
  /** country code (ISO format, 2 positions) */
  country_code: Scalars['String'];
  /** postal code for this address */
  postal_code: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationCommentInput = {
  /** Free text providing insights in the quotation from the perspective of the customer. */
  comment: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationConsolidateFulfillmentInput = {
  /** consolidate fulfillment */
  consolidate_fulfillment: Scalars['Boolean'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationContactAccountInput = {
  /** Identifier of the account to be associated with the quotation */
  account_id: Scalars['ID'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationDeliveryAddressInput = {
  /** Catlyn address identifier, optional; won't be there in case of an address unknown to Catlyn */
  address_id?: InputMaybe<Scalars['ID']>;
  /** textual field with the address information */
  address_text: Scalars['String'];
  /** city this address is in */
  city: Scalars['String'];
  /** country code (ISO format, 2 positions) */
  country_code: Scalars['String'];
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** postal code for this address */
  postal_code: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  recipient_name?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationDeliveryOptionInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  selected_delivery_option: Scalars['String'];
};

export type GQLSetQuotationFixedPriceInformationInput = {
  /** Average margin percentage for the quotation */
  average_margin_percentage: Scalars['Float'];
  /** Currency to be used for all prices */
  currency: Scalars['String'];
  /** Effective price for the lines */
  effective_lines_price: Scalars['Float'];
  /** Gross price of the quotation */
  gross_price: Scalars['Float'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** List with the prices for all quotation lines */
  quotation_line_prices?: InputMaybe<Array<GQLQuotationLineFixedPriceInformation>>;
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationFreightChargeInput = {
  currency: Scalars['String'];
  quotation_id: Scalars['ID'];
  value: Scalars['Float'];
};

export type GQLSetQuotationFulfillmentInstructions = {
  /** Free text describing instructions for fulfillment. */
  fulfillment_instructions: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationLineCommentInput = {
  /** Comment, only relevant for the customer. */
  comment: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationLineFixedPricesInput = {
  /** Currency to be used for all prices */
  currency: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** List with the prices for all quotation lines */
  quotation_line_prices: Array<GQLQuotationLineFixedPriceInformation>;
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationLineFulfillmentInstructionsInput = {
  /** Free text describing instructions for fulfillment. */
  fulfillment_instructions: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationLineManualDiscountInput = {
  /** manual discount to be applied on the full quotation net price as a percentage (e.g.: 1 means 1% additional discount) */
  percentage: Scalars['Float'];
  /** reason for the pricing adjustment */
  reason?: InputMaybe<Scalars['String']>;
  /** timestamp until when the manual discount pricing adjustment is valid */
  valid_until?: InputMaybe<Scalars['Time']>;
};

export type GQLSetQuotationLineQuantityInput = {
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** The new quantity. Original value will be overwritten. */
  quantity: Scalars['Float'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationLineRequestedShippingDateInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /** The requested shipping date, may be null to unset it. */
  requested_shipping_date?: InputMaybe<Scalars['Time']>;
};

export type GQLSetQuotationLinesManualDiscountInput = {
  /** manual discount lines to apply */
  manual_discount_lines: Array<GQLSetQuotationLinesManualDiscountLineInput>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationLinesManualDiscountLineInput = {
  /** manual discount to be applied on the full quotation net price as a percentage (e.g.: 1 means 1% additional discount) */
  percentage: Scalars['Float'];
  /** Identifier of the quotation line */
  quotation_line_id: Scalars['ID'];
  /** reason for the pricing adjustment */
  reason?: InputMaybe<Scalars['String']>;
  /** timestamp until when the manual discount pricing adjustment is valid */
  valid_until?: InputMaybe<Scalars['Time']>;
};

export type GQLSetQuotationNameInput = {
  /** The (new) name of the quotation. */
  name: Scalars['String'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetQuotationReferenceInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
  /**
   * Field with free text with a reference from another system, might be used in automated channels to relate
   *     the quotation to something in the system it originated from
   */
  reference: Scalars['String'];
};

export type GQLSetQuotationSeperateInvoiceInput = {
  /** indicates if a separate invoice is to be sent, specifically for this quotation. */
  invoiced_separately: Scalars['Boolean'];
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLSetValueAddedTaxRatesInput = {
  jurisdiction: GQLJurisdictionInput;
  tax_rates: Array<GQLValueAddedTaxRateInput>;
};

export type GQLSetWinnerVariantInput = {
  /** ID of the experiment to set the winner variant */
  experiment_id: Scalars['String'];
  /** ID of the variant to be set as winner */
  variant_id: Scalars['String'];
};

export type GQLSetWishlistCommentInput = {
  comment: Scalars['String'];
  wishlist_id: Scalars['ID'];
};

export type GQLSetWishlistLineCommentInput = {
  comment: Scalars['String'];
  wishlist_id: Scalars['ID'];
  wishlist_line_id: Scalars['ID'];
};

export type GQLSetWishlistLineQuantityInput = {
  quantity: Scalars['Float'];
  wishlist_id: Scalars['ID'];
  wishlist_line_id: Scalars['ID'];
};

export type GQLSetWishlistNameInput = {
  name: Scalars['String'];
  wishlist_id: Scalars['ID'];
};

export type GQLShareQuotationInput = {
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state?: InputMaybe<Scalars['String']>;
};

export type GQLShareWishlistInput = {
  wishlist_id: Scalars['ID'];
};

export type GQLShipmentDeliveryAddress = {
  __typename?: 'ShipmentDeliveryAddress';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  fax_number?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  name_2?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region_state?: Maybe<Scalars['String']>;
};

export type GQLShipmentDetail = {
  __typename?: 'ShipmentDetail';
  dispatch_note_id: Scalars['ID'];
  id: Scalars['ID'];
  organization?: Maybe<GQLOrganization>;
  shipment_orders: Array<GQLShipmentOrder>;
  tracking_id: Scalars['ID'];
};

export type GQLShipmentItem = {
  __typename?: 'ShipmentItem';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type GQLShipmentOrder = {
  __typename?: 'ShipmentOrder';
  order_id: Scalars['ID'];
  order_name: Scalars['String'];
  order_reference: Scalars['String'];
  shipment_order_lines: Array<GQLShipmentOrderLine>;
};

export type GQLShipmentOrderLine = {
  __typename?: 'ShipmentOrderLine';
  item?: Maybe<GQLItem>;
  item_description: Scalars['String'];
  /** Identifier of the order line that the item is a part of */
  order_line_id: Scalars['ID'];
  quantity: GQLDecimalValue;
};

export enum GQLShipmentStatus {
  Delivered = 'DELIVERED',
  InTransit = 'IN_TRANSIT',
  LeftBehind = 'LEFT_BEHIND',
  PackageCreated = 'PACKAGE_CREATED',
  Takeaway = 'TAKEAWAY',
  Unknown = 'UNKNOWN'
}

export type GQLShipmentUnit = {
  __typename?: 'ShipmentUnit';
  box_type: GQLShipmentUnitBoxType;
  /** Name of the carrier */
  carrier_name?: Maybe<Scalars['String']>;
  /** Optional delivery address */
  delivery_address?: Maybe<GQLShipmentDeliveryAddress>;
  /** The expected delivery date */
  delivery_date: Scalars['Date'];
  dispatch_note_id: Scalars['ID'];
  id: Scalars['ID'];
  /** Whether the shipment is from a backup or a default warehouse */
  is_from_backup_warehouse?: Maybe<Scalars['Boolean']>;
  /** Whether the shipment is using takeaway carrier */
  is_takeaway_carrier?: Maybe<Scalars['Boolean']>;
  /** Indication of the timestamp the last modification of the status happened */
  last_modified_status?: Maybe<Scalars['Date']>;
  organization?: Maybe<GQLOrganization>;
  shipment_unit_lines: Array<GQLShipmentUnitLine>;
  /** Count of shipment unit lines */
  shipment_unit_lines_count: Scalars['Int'];
  /** The status of a Delivery */
  status: GQLShipmentStatus;
  /** Known as box_id on frontend */
  tracking_id: Scalars['ID'];
  /** Optional tracking url by a shipment */
  tracking_url?: Maybe<Scalars['String']>;
  type: GQLShipmentUnitType;
};

export type GQLShipmentUnitBoxType = {
  __typename?: 'ShipmentUnitBoxType';
  label: Scalars['ID'];
  order: Scalars['Int'];
};

export type GQLShipmentUnitLine = {
  __typename?: 'ShipmentUnitLine';
  delivered_quantity: GQLDecimalValue;
  id: Scalars['ID'];
  item: GQLShipmentItem;
  order?: Maybe<GQLOrder>;
  order_line?: Maybe<GQLOrderLine>;
};

export type GQLShipmentUnitSort = {
  descending: Scalars['Boolean'];
  field: GQLShipmentUnitSortField;
};

export enum GQLShipmentUnitSortField {
  BoxType = 'BOX_TYPE',
  CarrierName = 'CARRIER_NAME',
  DeliveryDate = 'DELIVERY_DATE',
  PostalCode = 'POSTAL_CODE'
}

export type GQLShipmentUnitType = {
  __typename?: 'ShipmentUnitType';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type GQLShipmentUnitsInput = {
  box_types?: InputMaybe<Array<Scalars['String']>>;
  carrierNames?: InputMaybe<Array<Scalars['String']>>;
  /** Start of period to be included in the results. */
  date_from?: InputMaybe<Scalars['Date']>;
  /** End of period to be included in the results. NOTE: The date passed will always be at the beginning of the day, if you wish to include the results for that day be sure to add one more day to this field */
  date_to?: InputMaybe<Scalars['Date']>;
  dispatch_note_ids?: InputMaybe<Array<Scalars['ID']>>;
  is_from_backup_warehouse?: InputMaybe<Scalars['Boolean']>;
  item_id?: InputMaybe<Scalars['ID']>;
  order_ids?: InputMaybe<Array<Scalars['ID']>>;
  organization_ids?: InputMaybe<Array<Scalars['ID']>>;
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  postal_codes?: InputMaybe<Array<Scalars['ID']>>;
  shipment_ids?: InputMaybe<Array<Scalars['ID']>>;
  sort: Array<GQLShipmentUnitSort>;
  tracking_ids?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<Scalars['String']>>;
};

export type GQLShipmentUnitsResult = {
  __typename?: 'ShipmentUnitsResult';
  shipment_units?: Maybe<Array<GQLShipmentUnit>>;
  statistics: GQLStatistics;
};

export type GQLSimulateQuotationLineDiscount = {
  /** The currency to simulate the price information */
  currency: Scalars['String'];
  /** The quotation line being simulated */
  new_quotation_line: GQLNegotiatedQuotationLine;
  /** The organization for which the prices are being requested */
  organization_id: Scalars['String'];
  /** The quotation for which the prices are being requested */
  quotation_id: Scalars['String'];
  /**
   * The simulation needs to have the information related to the simulation request
   * to be done. All the fields are optional because only one of the fields should
   * be filled to perform the request.
   */
  simulation: GQLPriceSimulationRequest;
};

export enum GQLSortField {
  /** maps to sherlock.SortField_SortField_CAMPAIGN_ITEM_ORDER */
  CampaignItemOrder = 'CAMPAIGN_ITEM_ORDER',
  /** maps to sherlock.SortField_SortField_DEFAULT */
  Default = 'DEFAULT',
  /** maps to sherlock.SortField_SortField_GROSS_PRICE */
  GrossPrice = 'GROSS_PRICE'
}

export enum GQLSortOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum GQLSoteriaEntityType {
  EntityTypeAddress = 'EntityType_ADDRESS',
  EntityTypeBrand = 'EntityType_BRAND',
  EntityTypeComponent = 'EntityType_COMPONENT',
  EntityTypeFacet = 'EntityType_FACET',
  EntityTypeFacetvalue = 'EntityType_FACETVALUE',
  EntityTypeItem = 'EntityType_ITEM',
  EntityTypeOrganization = 'EntityType_ORGANIZATION',
  EntityTypeSalescategory = 'EntityType_SALESCATEGORY',
  EntityTypeUnknown = 'EntityType_UNKNOWN',
  EntityTypeVariant = 'EntityType_VARIANT'
}

export type GQLSpecificationKey = {
  __typename?: 'SpecificationKey';
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
};

/** A Suggestion entry contains a term (or multiple terms) and the suggestions. */
export type GQLSpellcheckSuggestionEntry = {
  __typename?: 'SpellcheckSuggestionEntry';
  /** The suggested spelling corrections for the given text. */
  options: Array<Scalars['String']>;
  /** The input text that suggestions apply to. */
  text: Scalars['String'];
};

export type GQLSpellcheckSuggestions = {
  __typename?: 'SpellcheckSuggestions';
  /** Phrase suggestions suggestions for the entire search query */
  phrase_suggestions?: Maybe<GQLSpellcheckSuggestionEntry>;
  /** Term suggestions are suggestions per search term */
  term_suggestions?: Maybe<Array<GQLSpellcheckSuggestionEntry>>;
};

export type GQLStaticTargetGroup = {
  __typename?: 'StaticTargetGroup';
  id: Scalars['ID'];
};

export type GQLStatistics = {
  __typename?: 'Statistics';
  page: Scalars['Int'];
  page_size: Scalars['Int'];
  total_pages: Scalars['Int'];
  total_results: Scalars['Int'];
};

export enum GQLStatusApplication {
  Order = 'ORDER',
  OrderLine = 'ORDER_LINE'
}

export type GQLStatusDefinition = {
  __typename?: 'StatusDefinition';
  id: Scalars['ID'];
  localized_status_definition: Array<GQLLocalizedValue>;
  status_application: Array<GQLStatusApplication>;
};

export type GQLStoreCommunicationContentInput = {
  content_id_to_content: Array<GQLMapValueInput>;
  event_id: Scalars['String'];
  event_type: Scalars['String'];
  organization_id: Scalars['String'];
};

export type GQLStoreCommunicationPreferenceInput = {
  account_id?: InputMaybe<Scalars['String']>;
  channel: GQLChannelType;
  communication_details: Array<Scalars['String']>;
  event_type: Scalars['String'];
  language: Scalars['String'];
  organization_id?: InputMaybe<Scalars['String']>;
};

export type GQLStringValue = {
  __typename?: 'StringValue';
  value: Scalars['String'];
};

export type GQLStringValueInput = {
  value: Scalars['String'];
};

export type GQLStringValueSpec = {
  __typename?: 'StringValueSpec';
  default_value: Scalars['String'];
};

export type GQLStringValueSpecInput = {
  default_value: Scalars['String'];
};

export type GQLSubmitQuotationInput = {
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
  /** A unique string that represents the state of the quotation. It is a hash calculated based on the parameters of the quotation. On every operation that changes the parameters in a quotation, the state will be recalculated. */
  quotation_state: Scalars['String'];
  /** The the selected delivery option */
  selected_delivery_option?: InputMaybe<Scalars['String']>;
};

export type GQLSubscriptionInput = {
  event_name: Scalars['ID'];
  is_created_by: Scalars['ID'];
  job_type: Scalars['ID'];
  organization_id: Scalars['ID'];
  quota: GQLJobSubscriptionQuotaInput;
  settings: Array<GQLMapValueInput>;
};

export type GQLSynonym = GQLAlsoSearchForSynonym | GQLInsteadSearchForSynonym | GQLSearchForAllSynonym;

export type GQLSynonymAlsoSearchForInput = {
  /** Input terms for this synonym, when a user searches for any of these terms they will also search for the terms in the search_terms field */
  query_terms?: InputMaybe<Array<Scalars['String']>>;
  /** Search terms for this synonym, when a user searches for any of the terms in the query_terms field they will also search for the terms in this field */
  search_terms?: InputMaybe<Array<Scalars['String']>>;
};

/** Various kinds of synonym implementations, only one of these fields needs to be set */
export type GQLSynonymImplementation = {
  /** Synonym for searching on one of the query terms we search also on search terms */
  also_search_for?: InputMaybe<GQLSynonymAlsoSearchForInput>;
  /** Synonym for searching on one of the query terms we search instead on search terms */
  instead_search_for?: InputMaybe<GQLSynonymInsteadSearchForInput>;
  /** Synonym for searching on one of the query terms, we search for all terms */
  search_for_all?: InputMaybe<GQLSynonymSearchForAllInput>;
};

export type GQLSynonymInput = {
  /**
   * The specific synonym implementations. All the fields are optional because only one of the fields should
   * be filled to perform the request.
   */
  implementation: GQLSynonymImplementation;
  /** Resolve type for this synonym */
  resolve_type?: InputMaybe<GQLSynonymResolveType>;
};

export type GQLSynonymInsteadSearchForInput = {
  /** Input terms for this synonym, when a user searches for any of these terms they will instead search for the terms in the search_terms field */
  query_terms?: InputMaybe<Array<Scalars['String']>>;
  /** Search terms for this synonym, when a user searches for any of the terms in the query_terms field they will only search for the terms in this field */
  search_terms?: InputMaybe<Array<Scalars['String']>>;
};

export enum GQLSynonymResolveType {
  AlternativeBrand = 'ALTERNATIVE_BRAND',
  AlternativeProduct = 'ALTERNATIVE_PRODUCT',
  SpellingError = 'SPELLING_ERROR',
  TrueSynonym = 'TRUE_SYNONYM'
}

export type GQLSynonymSearchForAllInput = {
  /** Terms in this synonym, when the user enters any of these terms the search engine will search for all terms in this list */
  terms?: InputMaybe<Array<Scalars['String']>>;
};

export type GQLSynonymsSortBy = {
  descending: Scalars['Boolean'];
  field: GQLSynonymsSortBySortByField;
};

export enum GQLSynonymsSortBySortByField {
  AlsoSearchForTerms = 'ALSO_SEARCH_FOR_TERMS',
  CreationTimestamp = 'CREATION_TIMESTAMP',
  InsteadSearchForTerms = 'INSTEAD_SEARCH_FOR_TERMS',
  LastUpdateTimestamp = 'LAST_UPDATE_TIMESTAMP',
  Terms = 'TERMS'
}

export type GQLSynoynmsResponse = {
  __typename?: 'SynoynmsResponse';
  /** Cursor that can be passed in a subsequent synonyms query to fetch the next set of synonyms */
  cursor?: Maybe<Scalars['String']>;
  synonyms?: Maybe<Array<GQLSynonym>>;
};

export enum GQLTargetAudience {
  Guest = 'GUEST',
  Organization = 'ORGANIZATION',
  User = 'USER'
}

export type GQLTax = {
  __typename?: 'Tax';
  feature_based_tax: Array<GQLFeatureBasedTax>;
  total_feature_based_tax_amount: GQLMonetaryValue;
  total_tax_amount: GQLMonetaryValue;
  total_value_based_tax_amount: GQLMonetaryValue;
  value_based_tax: Array<GQLValueBasedTax>;
};

export type GQLTaxBaseUnit = {
  __typename?: 'TaxBaseUnit';
  /** ID of the base unit */
  id: Scalars['String'];
};

export type GQLTaxFeature = {
  __typename?: 'TaxFeature';
  /** Id of the feature (attribute or other) that the tax is based on */
  feature_id: Scalars['ID'];
  /** Translated name of the feature */
  name: Scalars['String'];
};

export type GQLTermsAndConditions = {
  __typename?: 'TermsAndConditions';
  /** Content of the terms and conditions */
  content: Scalars['String'];
  /** Id of the terms and conditions entry */
  id: Scalars['ID'];
  /** Locale of the terms and conditions */
  locale: Scalars['String'];
  /** Date since which the terms and conditions is valid from */
  validFrom: Scalars['Time'];
};

export type GQLTranslation = {
  __typename?: 'Translation';
  /** Actual content */
  content: Scalars['String'];
  /** Locale; nl_NL, da_DK, etc.. */
  locale: Scalars['String'];
  /** (Optional) title for this document */
  title?: Maybe<Scalars['String']>;
};

export type GQLTreatmentAssignment = {
  __typename?: 'TreatmentAssignment';
  assigned_users: Scalars['Int'];
  percentage: Scalars['Float'];
  variant_identifier: Scalars['String'];
  variant_name: Scalars['String'];
};

export type GQLTreatmentAssignmentsWithTotal = {
  __typename?: 'TreatmentAssignmentsWithTotal';
  total_assigned_users: Scalars['Int'];
  treatment_assignments?: Maybe<Array<GQLTreatmentAssignment>>;
};

export type GQLTurnoverReportLine = {
  __typename?: 'TurnoverReportLine';
  line_description: Scalars['String'];
  values: Array<GQLTurnoverReportValue>;
};

export type GQLTurnoverReportValue = {
  __typename?: 'TurnoverReportValue';
  value: Scalars['Float'];
  value_type: GQLTurnoverReportValueType;
};

export enum GQLTurnoverReportValueType {
  YearCumulative = 'YEAR_CUMULATIVE',
  YearMinus_1Cumulative = 'YEAR_MINUS_1_CUMULATIVE',
  YearMinus_1Total = 'YEAR_MINUS_1_TOTAL',
  YearMinus_2Total = 'YEAR_MINUS_2_TOTAL',
  YearMinus_3Total = 'YEAR_MINUS_3_TOTAL'
}

export type GQLUiComponent = {
  /** [TODO: Have Maik define what he wants here] */
  name: Scalars['String'];
};

export type GQLUnassignAccountRoleInput = {
  account_id: Scalars['ID'];
  organization_id: Scalars['ID'];
  role_id: Scalars['Int'];
};

export type GQLUnitOfMeasurement = {
  __typename?: 'UnitOfMeasurement';
  /** Localized unit of measurement description */
  content: GQLLocalizedValue;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Localized symbol for this unit of measurement (e.g. kg) */
  symbol: GQLLocalizedValue;
};

export type GQLUpdateAccountInput = {
  account_id: Scalars['ID'];
  email: Scalars['String'];
  first_name?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  logon_id?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateAccountStatusInput = {
  account_id: Scalars['ID'];
  status: GQLAccountStatus;
};

export type GQLUpdateAddressInput = {
  address_id: Scalars['ID'];
  address_text: Scalars['String'];
  /** Deprecated: Use mutations add_address_types & remove_address_types */
  address_type?: InputMaybe<Array<GQLAddressType>>;
  city: Scalars['String'];
  country_code: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use mutations set_address_geo_location & delete_address_geo_location */
  geo_location?: InputMaybe<GQLGeoLocationInput>;
  label?: InputMaybe<Scalars['String']>;
  /** Deprecated: This field should be updated using the mutations to update the address settings using the key 'recipient_name' */
  name?: InputMaybe<Scalars['String']>;
  post_code: Scalars['String'];
};

export type GQLUpdateContextsForFeatureToggleInput = {
  /** The name of the feature. E.g. 'FreightCharges' */
  feature_id: Scalars['String'];
  /** The new contexts to use for the feature toggle (already existing ones that are not present in this collection will be removed) */
  new_contexts: Array<Scalars['String']>;
};

export type GQLUpdateContextsForFeatureToggleOutput = {
  __typename?: 'UpdateContextsForFeatureToggleOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLUpdateDefaultValueForFeatureToggleInput = {
  /** The name of the feature. E.g. 'FreightCharges' */
  feature_id: Scalars['String'];
  /** The new default value for the feature toggle */
  new_default_value: Scalars['String'];
};

export type GQLUpdateDefaultValueForFeatureToggleOutput = {
  __typename?: 'UpdateDefaultValueForFeatureToggleOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLUpdateDescriptionForFeatureToggleInput = {
  /** The name of the feature. E.g. 'FreightCharges' */
  feature_id: Scalars['String'];
  /** The new description for the feature toggle */
  new_description: Scalars['String'];
};

export type GQLUpdateDescriptionForFeatureToggleOutput = {
  __typename?: 'UpdateDescriptionForFeatureToggleOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLUpdateExperimentInput = {
  /** Description for the experiment. */
  description?: InputMaybe<Scalars['String']>;
  /** Unix timestamp in milliseconds indicating when the experiment stops, if unset then the experiment will not */
  end_date?: InputMaybe<Scalars['Time']>;
  /** Hypothesis for experiment describing what this experiment should verify */
  hypothesis?: InputMaybe<Scalars['String']>;
  /** List of metadata requirement for experiment, to be able to activate experiments according to context */
  metadata_requirements?: InputMaybe<Array<GQLMetaDataRequirementInput>>;
  /** Readable name for the experiment. */
  name?: InputMaybe<Scalars['String']>;
  /** Information about research done for the experiment */
  research_info?: InputMaybe<Scalars['String']>;
  /** Unix timestamp in milliseconds indicating when the experiment starts, if unset then the experiment will start */
  start_date?: InputMaybe<Scalars['Time']>;
  /** Target audience for the experiment */
  target_audience?: InputMaybe<GQLTargetAudience>;
  /** Name of a target metric. Different from metric_name as it isn't used in feedback workflow */
  target_metric_name?: InputMaybe<Scalars['String']>;
};

export type GQLUpdateFeatureToggleValueInput = {
  /** The unique identifier of the value (a.k.a. 'rule') */
  feature_toggle_value_id: Scalars['String'];
  /** The new importance that should be configured for the 'rule' (provide the previous value if the importance is not intended to change) */
  new_importance: Scalars['Int'];
  /** The new value that should be configured for the 'rule' (provide the previous value if the value is not intended to change) */
  new_value: Scalars['String'];
};

export type GQLUpdateFeatureToggleValueOutput = {
  __typename?: 'UpdateFeatureToggleValueOutput';
  result?: Maybe<Scalars['Boolean']>;
};

export type GQLUpdateOrganizationInput = {
  country_code: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  properties: Array<GQLMapValueInput>;
  settings: Array<GQLMapValueInput>;
};

export type GQLUpdateOrganizationLinkInput = {
  child_id: Scalars['String'];
  parent_id: Scalars['String'];
  weight: Scalars['Int'];
};

export type GQLUpdateRoleInput = {
  can_assign_roles?: InputMaybe<Array<Scalars['Int']>>;
  is_assignable_by_role_ids?: InputMaybe<Array<Scalars['Int']>>;
  role_description: Scalars['String'];
  role_id: Scalars['Int'];
  role_name: Scalars['String'];
};

export type GQLUploadAssetInput = {
  file_name: Scalars['String'];
  mime_type: Scalars['String'];
  path_pattern?: InputMaybe<Scalars['String']>;
  process_immediately: Scalars['Boolean'];
};

export type GQLUploadAssetsForUsageInput = {
  assets: Array<InputMaybe<GQLAssetToUploadForUsageDefinitionInput>>;
  delete_replaced_assets: Scalars['Boolean'];
  entity_id: Scalars['ID'];
  entity_type: GQLSoteriaEntityType;
  process_immediately: Scalars['Boolean'];
  replace_all_existing_usages: Scalars['Boolean'];
  usage_type: Scalars['String'];
};

export type GQLUpsertFreightChargeRuleInput = {
  /** The freight charge amount */
  amount: Scalars['Float'];
  /** Currency for the minimum effective price and the amount */
  currency: Scalars['String'];
  /** Delivery option as known to Pythia */
  delivery_option_id: Scalars['String'];
  /** Freight Plans */
  freight_plan: Scalars['String'];
  /** The minimum effective price */
  minimum_effective_price: Scalars['Float'];
};

export type GQLUpsertLocalizedDeliveryOptionInput = {
  content: Scalars['String'];
  delivery_option_id: Scalars['String'];
  locale: Scalars['String'];
};

export type GQLUpsertPricePromotionCampaignItem = {
  /** Discount type */
  discount_type: GQLDiscountType;
  /** Discount value */
  discount_value: Scalars['Float'];
  /** Item ID */
  item_id: Scalars['ID'];
};

export type GQLUpsertPricePromotionCampaignItemsInput = {
  /** Campaign ID */
  campaign_id: Scalars['ID'];
  /** Items to be inserted/updated in price promotion campaign */
  items: Array<GQLUpsertPricePromotionCampaignItem>;
};

export type GQLUpsertPriorityFallbackDeliveryOptionsInput = {
  country: Scalars['String'];
  delivery_options: Array<GQLFallbackDeliveryOptionInput>;
};

export type GQLUpsertRolePermissionsMappingInput = {
  permissions: Array<Scalars['String']>;
  role_id: Scalars['Int'];
};

export type GQLValidateAddressInput = {
  address_text: Scalars['String'];
  city: Scalars['String'];
  country_code: Scalars['String'];
  post_code: Scalars['String'];
};

export type GQLValidatedAddress = {
  __typename?: 'ValidatedAddress';
  suggested_address?: Maybe<GQLAddress>;
  valid: Scalars['Boolean'];
};

export type GQLValidationError = {
  __typename?: 'ValidationError';
  /** description of the error */
  description?: Maybe<Scalars['String']>;
  /** validation error code */
  error_code?: Maybe<Scalars['String']>;
  /** Additional properties related to the validation error */
  error_properties?: Maybe<Array<GQLMapValue>>;
  /** identifier of validation error */
  id: Scalars['ID'];
};

export type GQLValueAddedTaxRate = {
  __typename?: 'ValueAddedTaxRate';
  /** Name of the VAT range such as Standard rate */
  tax_range: Scalars['String'];
  /** Rate value associated with the range such as 20.0 (%) */
  tax_rate: Scalars['Float'];
};

export type GQLValueAddedTaxRateInput = {
  /** Name of the VAT range such as Standard rate */
  tax_range: Scalars['String'];
  /** Rate value associated with the range such as 20.0 (%) */
  tax_rate: Scalars['Float'];
};

export type GQLValueBasedTax = {
  __typename?: 'ValueBasedTax';
  tax_amount: GQLMonetaryValue;
  tax_description: Scalars['String'];
  tax_rate: Scalars['Float'];
};

export type GQLValueFacetValue = {
  __typename?: 'ValueFacetValue';
  key: Scalars['ID'];
  label: Scalars['String'];
  score: Scalars['Int'];
};

export type GQLValueFacetValues = {
  __typename?: 'ValueFacetValues';
  values: Array<GQLValueFacetValue>;
};

export type GQLValueFilter = {
  __typename?: 'ValueFilter';
  value: Scalars['String'];
};

export type GQLValueFilterInput = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export type GQLValueSortByInput = {
  direction: GQLSortOrder;
  field: GQLSortField;
};

export type GQLValuesFilter = {
  __typename?: 'ValuesFilter';
  values: Array<Scalars['String']>;
};

export type GQLValuesFilterInput = {
  field: Scalars['String'];
  values: Array<Scalars['String']>;
};

export type GQLVariant = {
  __typename?: 'Variant';
  /** Media assets coming from Soteria */
  assets?: Maybe<Array<GQLAsset>>;
  /** Brand */
  brand?: Maybe<GQLBrand>;
  /** Attributes defining this variant */
  defining_attributes: Array<GQLAttribute>;
  /** Localized description for the variant. locale and/or value fields can be empty */
  description: GQLLocalizedValue;
  /** AttributeValues describing this variant */
  descriptive_attribute_values: Array<GQLAttributeValue>;
  /** Public identifier provided by tenant */
  id: Scalars['ID'];
  /** Get all items of this variant that this user is entitled to see. */
  items: GQLItemsInVariant;
  /** Localized name of the variant. locale and/or value fields can be empty */
  name: GQLLocalizedValue;
  path: Array<GQLCategory>;
};


export type GQLVariantItemsArgs = {
  page: Scalars['Int'];
  page_size: Scalars['Int'];
};


export type GQLVariantPathArgs = {
  catalog_id: Scalars['String'];
};

export type GQLVariantSearchResult = {
  __typename?: 'VariantSearchResult';
  items: Array<GQLItem>;
  variant: GQLVariant;
};

export type GQLVolumeDiscountRange = {
  __typename?: 'VolumeDiscountRange';
  price: GQLMonetaryValue;
  range_start: Scalars['Float'];
};

export type GQLWishlist = {
  __typename?: 'Wishlist';
  /** Account associated with the wishlist, this is the owner if the wishlist is private and the account responsible for the wishlist if the wishlist is shared */
  account: GQLAccount;
  /** Field with free text providing insights in the wishlist provided by the user. */
  comment?: Maybe<Scalars['String']>;
  /** Time indicating when this wishlist was created */
  created_datetime: Scalars['Time'];
  /** Identifier of the wishlist. */
  id: Scalars['ID'];
  /** Time indicating when this wishlist or one of the associated wishlist lines was last modified. */
  last_modified_datetime: Scalars['Time'];
  /** Readable name of the wishlist to be set by the channel. */
  name: Scalars['String'];
  /** Organization the wishlist belongs to */
  organization: GQLOrganization;
  /**
   * Indicator if the wishlist is private or shared.
   *      - true: a shared wishlist owned by an organization, account_id will point to the responsible account
   *      - false: a private wishlist owned by an account
   */
  shared: Scalars['Boolean'];
  /** Status of the wishlist */
  status: GQLWishlistStatus;
  /** Wishlist lines */
  wishlist_lines: Array<GQLWishlistLine>;
  /** Count of lines that wishlist contains */
  wishlist_lines_count: Scalars['Int'];
};

export type GQLWishlistLine = {
  __typename?: 'WishlistLine';
  /** Comment, only relevant for the customer */
  comment?: Maybe<Scalars['String']>;
  /** Time indicating when this wishlist line was created */
  created_datetime: Scalars['Time'];
  /** Identifier of the wishlist line. */
  id: Scalars['ID'];
  /** The item */
  item?: Maybe<GQLItem>;
  /** Time indicating when this wishlist lines was last modified. */
  last_modified_datetime: Scalars['Time'];
  /** Quantity */
  quantity: Scalars['Float'];
};

export enum GQLWishlistStatus {
  Deleted = 'DELETED',
  Open = 'OPEN'
}

/** Return types */
export type GQLWorkItemGroup = {
  __typename?: 'WorkItemGroup';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

export type GQLWorkItemIdentifier = {
  __typename?: 'WorkItemIdentifier';
  workItemGroup: GQLWorkItemGroup;
  workItemId: Scalars['String'];
};

export type GQLWorkItemMetadata = {
  __typename?: 'WorkItemMetadata';
  identifier: GQLWorkItemIdentifier;
  possibleFilters: Array<GQLKv>;
};

export type GQLValidateQuotationInput = {
  /** Flag indicating whether the request is from a non interactive channel, for eg., EDI. */
  non_interactive_channel?: InputMaybe<Scalars['Boolean']>;
  /** Identifier of the quotation */
  quotation_id: Scalars['ID'];
};
