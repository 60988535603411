/* eslint-disable no-lone-blocks */

import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

{
  /* How to use the transform translation

  import { useFormatTranslation } from '@dc3/utils/helpers';

  const formatTranslation = useFormatTranslation();

  formatTranslation(
    'proposal.introduction',
    translations['proposal.introduction'],
    { contactName: quotation?.contactAccount.fullName }
  );
  */
}

// Hook to transform translations from Google Sheets
export const useFormatTranslation = () => {
  const { formatMessage } = useIntl();

  return useCallback(
    (id: string, message: string, ...rest: Array<{}>) => {
      if (!id || !message) return;

      const messages = defineMessages({
        translation: {
          id,
          defaultMessage: message,
        },
      });

      return formatMessage(messages.translation, ...rest, { ignoreTag: true });
    },
    [formatMessage],
  );
};
