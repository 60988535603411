import { useContext } from 'react';
import { FeaturesContext } from './feature-toggles.context';

export enum FeatureId {
  qmSavedViews = 'qmSavedViews',
  proposalsFromQueryQuotationProposals = 'proposalsFromQueryQuotationProposals',
  userAddressManagement = 'userAddressManagement',
  userSettingsManagement = 'userSettingsManagement',
  organizationPunchout = 'organizationPunchout',
  socialIdentityCreation = 'socialIdentityCreation',
  orgDeleteAccount = 'orgDeleteAccount',
}

const parseFeatureValue = <T>(value: string | T): boolean | T => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return value as T;
};

export const useFeatureToggle = <T = boolean>(
  featureId: FeatureId,
): T | null => {
  const { features } = useContext(FeaturesContext);

  if (!features) {
    return null;
  }

  const featureValue = features[featureId];

  if (!featureValue) {
    return null;
  }

  return parseFeatureValue<T>(featureValue) as T;
};
